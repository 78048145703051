import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { APIProvider } from 'src/utils/API';

export const ACCOUNT_QUERY = gql`
  query account($id: Int!) {
    account(id: $id) {
      id
      name
      companyName
      companyName
      accountType
      city
      contactName
      contactEmail
      contactPhone
      state
      streetAddressLine1
      streetAddressLine2
      zip
      isAccountDisabled
      isDemo
      subdomain
      connection
      timezone
      primacyCode
      pwsid
      pwsType
      sdwisContact
      owner
      populationServedCount
      serviceLineConnectionsCount
      latitude
      longitude
    }
  }
`;

export const useGetAvailableCounties = (accountId: number): any => {
  const [availableCounties, setAvailableCounties] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `/platform/account-management/rest/admin/accounts/${accountId}/available-counties`;
      const response = await APIProvider.fetch(url);
      setAvailableCounties(response);
    };
    fetchData();
  }, []);

  return { availableCounties };
};

export const useGetCountiesServed = (accountId: number): any => {
  const [countiesServed, setCountiesServed] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `/platform/account-management/rest/admin/accounts/${accountId}/served-counties`;
      const response = await APIProvider.fetch(url);
      setCountiesServed(response);
    };
    fetchData();
  }, []);

  return { countiesServed };
};

export const useUpdateCountiesServed = (accountId: number): any => {
  const updateCountiesServed = async counties => {
    const url = `/platform/account-management/rest/admin/accounts/${accountId}/served-counties`;
    const response = await APIProvider.put(url, {
      countyIds: counties
    });
    return response;
  };

  return { updateCountiesServed };
};
