import { useEffect } from 'react'

/**
 * `useCleanup` - used to clean up state logic when a component un-mounts
 *
 * @param cleanupCallback clean up callback function
 */
export const useCleanup = (cleanupCallback: () => void) => {
  useEffect(() => {
    return () => {
      cleanupCallback()
    }
  }, [])
}
