import * as React from 'react'
import { MappingViewRow } from './MappingViewRow'
import {
  Table,
  TableHeader,
  TableBody,
  TableHeaderCell
} from 'semantic-ui-react'
import { CenteringWrapper, DefinedMapping } from './shared'

interface ConfirmStepProps {
  mappings: DefinedMapping[]
}

export const ConfirmMappingStep = ({ mappings }: ConfirmStepProps) => (
  <>
    <CenteringWrapper>
      <h3>New Edd Mapping</h3>
    </CenteringWrapper>
    <Table striped>
      <TableHeader>
        <Table.Row>
          <TableHeaderCell>System Field</TableHeaderCell>
          <TableHeaderCell>User Field</TableHeaderCell>
          <TableHeaderCell>Info</TableHeaderCell>
        </Table.Row>
      </TableHeader>
      <TableBody>
        {mappings.map(mapping => (
          <MappingViewRow key={mapping.systemField.name} mapping={mapping} />
        ))}
      </TableBody>
    </Table>
  </>
)
