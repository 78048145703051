import React, { FC, useState } from 'react'
import { Analyte } from '@120wateraudit/envirio-components/dist/models'
import { Loader, Grid } from '@120wateraudit/envirio-components'
import styled from 'styled-components'
import { useAccountAnalytes } from './dataAccess'
import { AccountAnalyteRow } from './components/AccountAnalyteRow'

interface Props {
  accountId: number
}

export interface AccountAnalyte {
  accountId: number
  analyteId: number
  exceedanceLimit?: number
  id: number
  includeInExport?: boolean
  isRange?: boolean
  lowerLimit?: number | null
}

export interface AccountAnalyteWithRelations extends AccountAnalyte {
  analyte: Analyte
}

export const AccountAnalytesContainer: FC<Props> = ({ accountId }) => {
  const [disableEditButtons, setDisableEditButtons] = useState(false)
  const { accountAnalytes, loading, refetch } = useAccountAnalytes(accountId)

  const columns = [
    {
      key: 'analyteName',
      name: 'Analyte Name'
    },
    {
      key: 'exceedanceLimit',
      name: 'Exceedance Limit'
    },
    {
      key: 'lowerLimit',
      name: 'Lower Limit'
    },
    {
      key: 'isRange',
      name: 'Is Range'
    },
    {
      key: 'includeInExport',
      name: 'Include In Export'
    }
  ]

  return (
    <AccountAnalytesWrapper>
      {loading && <Loader />}
      {!loading && (
        <>
          <h4>ACCOUNT ANALYTES</h4>
          <Grid
            columns={columns}
            data={accountAnalytes}
            displaySearch={false}
            hideShowingText
            renderRow={(accountAnalyte: AccountAnalyteWithRelations) => {
              return (
                <AccountAnalyteRow
                  accountAnalyte={accountAnalyte}
                  disableEditButtons={disableEditButtons}
                  key={accountAnalyte.id}
                  refetch={refetch}
                  setDisableEditButtons={setDisableEditButtons}
                />
              )
            }}
          />
        </>
      )}
    </AccountAnalytesWrapper>
  )
}

const AccountAnalytesWrapper = styled.div`
  padding-top: 5%;
`
