import React from 'react';
import { BadgeProps, Badge } from '@120wateraudit/envirio-components';
import { Popup } from 'semantic-ui-react';
import { ReportInstanceStatus } from '@120wateraudit/envirio-components/dist/models';

export const ReportInstanceStatusBadge: React.FC<{
  status: ReportInstanceStatus;
  error?: string;
}> = ({ status, error = '' }) => {
  let variant: BadgeProps['variant'] = 'primary';

  switch (status) {
    case ReportInstanceStatus.Initiated:
      break;
    case ReportInstanceStatus.Processed:
      variant = 'success';
      break;
    case ReportInstanceStatus.Error:
      variant = 'error';
      break;
    default:
      throw new Error('Unknown badge status');
  }

  const badge = (
    <Badge style={{ width: '7rem' }} variant={variant}>
      {ReportInstanceStatus[status]}
    </Badge>
  );

  if (status !== ReportInstanceStatus.Error) {
    return badge;
  }

  return <Popup on="hover" inverted content={error} trigger={badge} />;
};
