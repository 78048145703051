import actionCreatorFactory from 'typescript-fsa'
const action = actionCreatorFactory()

import { UserInvite } from '../types/UserInvite'

export const inviteUserRequest = action('INVITE_USER_REQUEST')
export const inviteUserSuccess = action<{ invite: UserInvite }>(
  'INVITE_USER_SUCCESS'
)
export const inviteUserFailure = action<{ error: string }>(
  'INVITE_USER_FAILURE'
)

export const resendInviteRequest = action('RESEND_INVITE_REQUEST')
export const resendInviteSuccess = action('RESEND_INVITE_SUCCESS')
export const resendInviteFailure = action<{ error: string }>(
  'RESEND_INVITE_FAILURE'
)

export const revokeInviteRequest = action('REVOKE_INVITE_REQUEST')
export const revokeInviteSuccess = action('REVOKE_INVITE_SUCCESS')
export const revokeInviteFailure = action<{ error: string }>(
  'REVOKE_INVITE_FAILURE'
)
