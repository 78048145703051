import React, { FC } from 'react'
import { TableCell, TableRow } from 'semantic-ui-react'
import { Lab, LabPartner } from '@120wateraudit/envirio-components/dist/models'
import { formatPhoneString } from 'src/utils/FormatPhoneNumber'
import { Link } from 'react-router-dom'
import { Button } from '@120wateraudit/envirio-components'

interface Props {
  lab: Lab
  labPartner: LabPartner
  toggleCreateEditLabModal: (labPartner?: LabPartner, lab?: Lab) => void
}

export enum MappingFields {
  Analyte,
  UnitId,
  UnitIdentifier
}

export const LabRow: FC<Props> = ({
  lab,
  labPartner,
  toggleCreateEditLabModal
}: Props) => {
  const addressLine2 = lab.addressLine2 || '--'
  const getFullPhoneNumber = (phone: string, ext?: string) => {
    return !ext ? phone : `${phone} ext: ${ext}`
  }
  const contactEmail = lab.contactEmail || '--'

  return (
    <>
      <TableRow
        className={'tableRow'}
        onClick={() => {
          toggleCreateEditLabModal(labPartner, lab)
        }}
        style={{ cursor: 'pointer' }}>
        <TableCell>{lab.id}</TableCell>
        <TableCell>{lab.name}</TableCell>
        <TableCell>{lab.addressLine1}</TableCell>
        <TableCell>{addressLine2}</TableCell>
        <TableCell>{lab.city}</TableCell>
        <TableCell>{lab.state}</TableCell>
        <TableCell>{lab.zip}</TableCell>
        <TableCell>{lab.contactName}</TableCell>
        <TableCell>
          {getFullPhoneNumber(formatPhoneString(lab.contactPhone))}
        </TableCell>
        <TableCell>{contactEmail}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={9}>
          <Link
            to={{
              pathname: `/eddconfigurations/${lab.id}`,
              state: {
                labName: lab.name
              }
            }}>
            <Button variant="primary">View/Edit EDD Configuration</Button>
          </Link>
        </TableCell>
      </TableRow>
    </>
  )
}
