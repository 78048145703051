import { TextField as EnvirioTextField } from '@120wateraudit/envirio-components';
import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

type Props = FieldRenderProps<string, HTMLElement>;

const TextField: FC<Props> = ({ input, meta: { error, touched }, ...rest }) => {
  return (
    <EnvirioTextField
      {...input}
      error={touched && !!error}
      value={(input.value as unknown) === 0 ? '0' : input.value}
      {...rest}
    />
  );
};

export default TextField;
