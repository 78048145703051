import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { DetailsReducerState } from './helpers/DetailsCrudReducer';
import { IndexReducerState } from './helpers/IndexCrudReducer';

// Utils
import { clearToken } from 'src/utils/Token';

// Reducers
import account from './account';
import accounts from './accounts';
import accountUser from './accountUser';
import applicationRoles, { Auth0RolesState } from './applicationAccessRoles';
import definitions from './definitions';
import labPartner from './labPartner';
import roles, { RolesState } from './roles';
import schoolDistricts from './schoolDistricts';
import schools from './schools';
import settingDefinitions from './settingDefinitions';
import settings from './settings';
import user from './user';
import userInvite, { UserInviteState } from './userInvite';

import applicationUser, { UserState } from './applicationUser';
import { SchoolDistrictsState } from './schoolDistricts';
import { SchoolsState } from './schools';

import { accountManagementService, eddService, pwsService } from 'src/services';

export interface ApplicationState {
  [accountManagementService.reducerPath]: ReturnType<
    typeof accountManagementService.reducer
  >;
  [eddService.reducerPath]: ReturnType<typeof eddService.reducer>;
  [pwsService.reducerPath]: ReturnType<typeof pwsService.reducer>;
  account: DetailsReducerState;
  accounts: IndexReducerState;
  accountUser: DetailsReducerState;
  definitions: IndexReducerState;
  roles: RolesState;
  schoolDistricts: SchoolDistrictsState;
  schools: SchoolsState;
  settingDefinitions: IndexReducerState;
  settings: IndexReducerState;
  applicationUser: UserState;
  user: DetailsReducerState;
  userInvite: UserInviteState;
  labPartner: DetailsReducerState;
  applicationRoles: Auth0RolesState;
}

const appReducer = combineReducers({
  [accountManagementService.reducerPath]: accountManagementService.reducer,
  [eddService.reducerPath]: eddService.reducer,
  [pwsService.reducerPath]: pwsService.reducer,
  form: formReducer,
  account,
  accounts,
  accountUser,
  definitions,
  roles,
  schools,
  schoolDistricts,
  settingDefinitions,
  settings,
  applicationUser,
  labPartner,
  user,
  userInvite,
  applicationRoles
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT') {
    clearToken();
    window.localStorage.removeItem('120_backoffice_user');
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
