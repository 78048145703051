import actionCreatorFactory from 'typescript-fsa'
const action = actionCreatorFactory()

// tslint:disable-next-line:interface-name
export interface ICrudActions {
  detailsActions: {
    fetchRequest: any
    fetchSuccess: any
    fetchFailure: any
    unload: any
  }

  updateActions: {
    updateRequest: any
    updateSuccess: any
    updateFailure: any
  }

  deleteActions: {
    deleteRequest: any
    deleteSuccess: any
    deleteFailure: any
  }

  collectionActions: {
    fetchRequest: any
    fetchSuccess: any
    fetchFailure: any
    unload: any
  }

  createActions: {
    createRequest: any
    createSuccess: any
    createFailure: any
  }
}

interface CrudActionsOptions {
  entityName?: string
  collectionName?: string
}

// tslint:disable-next-line:max-line-length
export default function GenerateCrudActions<T>({
  entityName,
  collectionName
}: CrudActionsOptions) {
  const actions: any = {}

  if (entityName) {
    const entity = entityName.toUpperCase()

    actions.detailsActions = {
      fetchRequest: action<any>(`FETCH_${entity}_REQUEST`),
      fetchSuccess: action<{ [entityName: string]: T }>(
        `FETCH_${entity}_SUCCESS`
      ),
      // tslint:disable-next-line:object-literal-sort-keys
      fetchFailure: action<{ error: string }>(`FETCH_${entity}_FAILURE`),
      unload: action(`UNLOAD_${entity}`)
    }

    actions.updateActions = {
      updateRequest: action(`UPDATE_${entity}_REQUEST`),
      updateSuccess: action<{ [entityName: string]: T }>(
        `UPDATE_${entity}_SUCCESS`
      ),
      updateFailure: action<{ error: string }>(`UPDATE_${entity}_FAILURE`)
    }

    actions.deleteActions = {
      deleteRequest: action(`DELETE_${entity}_REQUEST`),
      deleteSuccess: action(`DELETE_${entity}_SUCCESS`),
      deleteFailure: action<{ error: string }>(`DELETE_${entity}_FAILURE`)
    }

    actions.createActions = {
      createRequest: action(`CREATE_${entity}_REQUEST`),
      createSuccess: action<{ [entityName: string]: T }>(
        `CREATE_${entity}_SUCCESS`
      ),
      createFailure: action<{ error: string }>(`CREATE_${entity}_FAILURE`)
    }
  }

  if (collectionName) {
    const collection = collectionName.toUpperCase()

    actions.collectionActions = {
      fetchRequest: action(`FETCH_${collection}_REQUEST`),
      fetchSuccess: action<{ [entityName: string]: [T] }>(
        `FETCH_${collection}_SUCCESS`
      ),
      // tslint:disable-next-line:object-literal-sort-keys
      fetchFailure: action<{ error: string }>(`FETCH_${collection}_FAILURE`),
      unload: action(`UNLOAD_${collection}`)
    }
  }

  return actions
}
