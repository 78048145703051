import {
  colors,
  FilterType,
  fontSize,
  lineHeight,
  spacing
} from '@120wateraudit/envirio-components';
import {
  Table as WWTable,
  Card,
  Button,
  useMultiselection,
  ActiveFilters
} from '@120wateraudit/waterworks';
import {
  PWSAccount,
  PWSSamplingEvent
} from '@120wateraudit/envirio-components/dist/models';
import styled from '../../theme';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import * as React from 'react';
import { intersection } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTableState } from 'src/hooks/useTableState';
import { Container, Popup, Button as SemanticButton } from 'semantic-ui-react';
import { APIProvider, AWS_BASE } from 'src/utils/API';
import { useGetFulfillmentSamplesQuery } from 'src/services';
import { sampleStatusListOptions } from 'src/utils/SelectList';
import { PWSSampleRecord } from 'src/types/Fulfillment';
import { Sort } from 'src/services/types';
import { SampleStatus } from 'src/types/Sample';
import { buildParameters } from 'src/services/utils';

interface Props {
  accounts: PWSAccount[];
  onMarkSampleAsShipped: (sample: PWSSampleRecord) => void;
  onMarkSamplesAsShipped: (samples: PWSSampleRecord[]) => void;
  onUpdateSampleStatus: (sample: PWSSampleRecord) => void;
  onUpdateSamplesStatus: (samples: PWSSampleRecord[]) => void;
}

const getAccountListOptions = (accounts: PWSAccount[]) => {
  return accounts && accounts.length
    ? accounts.map(a => {
        return { id: `${a.id}`, label: `${a.id}: ${a.name}`, value: a.id };
      })
    : [];
};

const fetchEventsForAccount = async (accountId: number): Promise<any> => {
  return await APIProvider.fetchSamplingEventsByAccount(accountId);
};

const getSamplingEventListOptions = (events: any) => {
  return events.items && events.items.length
    ? events.items.map(a => {
        return { id: `${a.id}`, label: `${a.id}: ${a.name}`, value: a.id };
      })
    : [];
};

const Fulfillment = ({
  accounts,
  onMarkSampleAsShipped,
  onMarkSamplesAsShipped,
  onUpdateSampleStatus,
  onUpdateSamplesStatus
}: Props) => {
  const defaultFilters: ActiveFilters = {
    status: SampleStatus.New
  };
  const [accId, setAccountId] = useState(-1);
  const [events, setEvents] = useState<PWSSamplingEvent[]>([]);
  const {
    onClearSelection,
    onSelect,
    onSelectAll,
    selected,
    selectedIds
  } = useMultiselection<PWSSampleRecord>();
  const {
    params,
    setSearchTermWrapper,
    setPageSize,
    setPageNumber,
    setSort,
    setFiltersWrapper
  } = useTableState({ defaultFilters, searchQueryType: 'search' });
  const { data: response, isFetching } = useGetFulfillmentSamplesQuery({
    paramsData: params
  });
  useEffect(() => {
    fetchEventsForAccount(accId).then(setEvents);
  }, [accId]);

  const pwsSampleRecords = response?.items || [];
  const pwsSampleRecordsLength = response?.count || 0;

  const areAllSelected = useMemo(() => {
    const idsOnPage = pwsSampleRecords.map(s => s.id);
    return intersection(idsOnPage, selectedIds).length === idsOnPage.length;
  }, [pwsSampleRecords, selectedIds]);

  const selectAllData = useCallback(() => {
    onSelectAll(pwsSampleRecords, areAllSelected);
  }, [onSelectAll, pwsSampleRecords, areAllSelected]);

  const onExportClicked = async () => {
    try {
      const results: any = await APIProvider.fetchExcel(
        `${AWS_BASE}/pws/rest/fulfillment/samples/export?${buildParameters(
          params
        )}`
      );

      const blob = new Blob([results.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      FileSaver.saveAs(
        blob,
        `fulfillment_samples_${moment().format('DD-MM-YYYY')}.xlsx`,
        true
      );
    } catch (e) {
      /* */
    }
  };

  const TABLE_ACTIONS = [
    {
      label: 'Set Status',
      onClick: () => {
        onUpdateSamplesStatus(selected);
      }
    },
    {
      label: 'Mark as Shipped',
      onClick: () => {
        onMarkSamplesAsShipped(selected);
      }
    }
  ];

  const COLUMNS = [
    {
      key: 'PWSSample_program_account.id',
      Header: 'Account Id',
      sortable: false,
      accessor: ({ program }: PWSSampleRecord) => {
        setAccountId(program.account.id);
        return <div>{program.account.id}</div>;
      }
    },
    {
      key: 'PWSSample_program_account.name',
      Header: 'Account Name',
      sortable: false,
      accessor: ({ program }: PWSSampleRecord) => {
        return <div>{program.account.name}</div>;
      }
    },
    {
      key: 'id',
      Header: 'Sample Id',
      sortable: true,
      accessor: 'id'
    },
    {
      key: 'kitId',
      Header: 'Kit Id',
      sortable: true,
      accessor: 'kitId'
    },
    {
      key: 'PWSSample_samplingEvent.id',
      Header: 'Sampling Event Id',
      sortable: false,
      accessor: ({ samplingEvent }: PWSSampleRecord) => (
        <div>{samplingEvent.id}</div>
      )
    },
    {
      key: 'PWSSample_samplingEvent.name',
      Header: 'Sampling Event Name',
      sortable: false,
      accessor: ({ samplingEvent }: PWSSampleRecord) => (
        <div>{samplingEvent.name}</div>
      )
    },
    {
      key: 'status',
      Header: 'Status',
      sortable: true,
      accessor: ({ newStatus }: PWSSampleRecord) => (
        <div>{SampleStatus[newStatus]}</div>
      )
    },
    {
      key: 'drawOrder',
      Header: 'Draw Order',
      sortable: false,
      accessor: ({ drawOrder }: PWSSampleRecord) => (
        <div>{typeof drawOrder === 'number' ? drawOrder : '--'}</div>
      )
    },
    {
      key: '',
      Header: '',
      sortable: false,
      accessor: (sample: PWSSampleRecord) => {
        return (
          <Popup
            position="bottom left"
            trigger={
              <SemanticButton
                className="popupTrigger"
                floated="right"
                circular
                style={{ background: colors.white }}
                icon={<OptionsIcon src="/overflow.svg" />}
              />
            }
            on="click">
            <OptionsList>
              {sample.newStatus !== SampleStatus.New && (
                <OptionsListItem
                  style={{ paddingBottom: '8px' }}
                  onClick={() => {
                    onMarkSampleAsShipped(sample);
                  }}>
                  <img
                    src="/edit.svg"
                    style={{
                      opacity: 0.25,
                      width: '24px',
                      height: '24px',
                      marginLeft: '8px',
                      marginRight: '8px'
                    }}
                    alt="edit"
                  />
                  <span>Mark as Shipped</span>
                </OptionsListItem>
              )}
              <OptionsListItem
                style={{ paddingBottom: '8px' }}
                onClick={() => {
                  onUpdateSampleStatus(sample);
                }}>
                <img
                  src="/edit.svg"
                  style={{
                    opacity: 0.25,
                    width: '24px',
                    height: '24px',
                    marginLeft: '8px',
                    marginRight: '8px'
                  }}
                  alt="edit"
                />
                <span>Set Status</span>
              </OptionsListItem>
            </OptionsList>
          </Popup>
        );
      }
    }
  ];

  const FILTER = [
    {
      key: 'status',
      label: 'Status',
      type: FilterType.ListSelection,
      options: sampleStatusListOptions
    },
    {
      key: 'accountId',
      label: 'Account',
      type: FilterType.ListSelection,
      options: getAccountListOptions(accounts)
    },
    {
      key: 'samplingEventId',
      label: 'Sampling Event',
      type: FilterType.ListSelection,
      options: getSamplingEventListOptions(events)
    }
  ];

  return (
    <Container>
      <div style={{ marginTop: '20px', position: 'relative' }}>
        <Card>
          <ExportWrapper>
            <Button size="small" onClick={onExportClicked}>
              <span>
                <img src="/download.svg" alt="download" />
                <div>Export</div>
              </span>
            </Button>
          </ExportWrapper>
          <WWTable
            columns={COLUMNS}
            data={pwsSampleRecords}
            itemName="Fulfillment"
            totalRecords={pwsSampleRecordsLength}
            loading={isFetching}
            paginated
            page={params.pageNumber}
            pageSize={params.pageSize}
            onPageChanged={setPageNumber}
            onPageSizeChanged={setPageSize}
            onSortChanged={setSort}
            sort={params.sort as Sort}
            sortable
            filterable
            activeFilters={params.filters}
            onFilterChanged={setFiltersWrapper}
            filters={FILTER}
            areAllSelected={areAllSelected}
            actions={TABLE_ACTIONS}
            selected={selected}
            selectionMode="multi"
            onClearSelection={onClearSelection}
            onSelect={onSelect}
            onSelectAll={selectAllData}
            searchable
            onSearchChanged={setSearchTermWrapper}
          />
        </Card>
      </div>
    </Container>
  );
};

const ExportWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
  right: 0;
  & > button {
    align-self: flex-end;
    margin-bottom: 0.5rem;

    & span {
      padding: 0.1rem;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      & img {
        margin-right: 0.5rem;
      }
      & div {
        font-size: ${fontSize.subHeader};
      }
    }
  }
`;

const OptionsIcon = styled.img`
  width: 20px;
  height: 20px;
  opacity: 0.25;

  &:active {
    opacity: 1;
  }
`;

const OptionsList = styled.ul`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  border-radius: 4px;
  width: 184px;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

const OptionsListItem = styled.li`
  font-size: ${fontSize.body};
  font-weight: 300;
  padding: ${spacing.mini};
  display: flex;
  width: 100%;
  align-items: center;

  span {
    text-align: center;
    display: inline-block;
    line-height: ${lineHeight.body};
  }

  &:hover {
    background: ${colors.black05};
  }
`;

export default Fulfillment;
