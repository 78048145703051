import * as React from 'react'
import { TableRow, TableCell, Loader } from 'semantic-ui-react'
import { Button, Grid } from '@120wateraudit/envirio-components'
import { Feature } from '../../types/Feature'
import {
  useDeleteAccountFeatureMutation,
  useFeaturesForAccount
} from 'src/hooks/useFeatures'

interface Props {
  accountId: number
}

export const columns = [
  {
    key: 'code',
    sortable: false,
    name: 'Code'
  },
  {
    key: 'name',
    sortable: false,
    name: 'Name'
  },
  {
    key: 'remove',
    sortable: false,
    name: 'Remove'
  }
]

export const FeaturesGrid = ({ accountId }: Props) => {
  const { accountFeatures: features, loading } = useFeaturesForAccount(
    accountId
  )

  const [deleteAccountFeature] = useDeleteAccountFeatureMutation()

  const onDeleteClicked = async ({ featureId }: { featureId: number }) => {
    await deleteAccountFeature({
      variables: {
        accountId,
        featureId
      },
      refetchQueries: ['features', 'availableFeatures']
    })
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Grid
      style={{}}
      displaySearch={false}
      columns={columns}
      data={features}
      renderRow={(feature: Feature) => (
        <TableRow key={feature.id}>
          <TableCell>{feature.code}</TableCell>
          <TableCell>{feature.name}</TableCell>
          <TableCell>
            <Button
              variant="error"
              onClick={async () =>
                await onDeleteClicked({ featureId: feature.id })
              }>
              Remove
            </Button>
          </TableCell>
        </TableRow>
      )}
    />
  )
}
