import * as React from 'react'
import {
  DropdownItemProps,
  DropdownProps,
  Table,
  TableCell
} from 'semantic-ui-react'
import { Mapping } from './shared'
import { Dropdown } from '@120wateraudit/envirio-components'

interface MappingEditRowProps {
  options: DropdownItemProps[]
  mapping: Mapping
  onChange(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void
}

export const MappingEditRow = ({
  options,
  mapping,
  onChange
}: MappingEditRowProps) => (
  <Table.Row key={mapping.systemField.name}>
    <TableCell>{mapping.systemField.name}</TableCell>
    <TableCell>
      <Dropdown
        addUndefinedOption={false}
        options={options}
        value={mapping.userFieldName || ''}
        onChange={onChange}
      />
    </TableCell>
  </Table.Row>
)
