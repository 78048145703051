import {
  Button,
  Grid,
  Modal,
  smartGrid
} from '@120wateraudit/envirio-components'
import { Lab, LabPartner } from '@120wateraudit/envirio-components/dist/models'
import React, { FC, useState } from 'react'
import { Container, Icon } from 'semantic-ui-react'

import LabPartnerForm from 'src/components/Forms/LabPartner'
import { APIProvider, AWS_BASE } from '../../utils/API'
import { CreateEditLabModal } from '../Labs/CreateEditLabModal'
import { LabPartnerData } from '../Labs/data-access'
import { LabPartnerGridRow } from './LabPartnerGridRow'

interface Props {
  gridRef: any
  isCreateModalOpen: boolean
  onNewClicked: any
  onSaveClicked: () => void
  reloadGrid: () => void
  toggleModal: () => void
}

const LabPartnersGrid = smartGrid({
  WrappedComponent: Grid
})

const LabPartners: FC<Props> = ({
  gridRef,
  isCreateModalOpen,
  onNewClicked,
  onSaveClicked,
  reloadGrid,
  toggleModal
}: Props) => {
  const [createEditLabModalIsOpen, setCreateEditLabModalIsOpen] = useState(
    false
  )
  const [currentLab, setCurrentLab] = useState<undefined | Lab>(undefined)
  const [currentLabPartner, setCurrentLabPartner] = useState<
    undefined | LabPartner
  >(undefined)
  const [labPartnerData, setLabPartnerData] = useState<
    undefined | LabPartnerData
  >(undefined)

  const toggleCreateEditLabModal = (partner?: LabPartner, lab?: Lab) => {
    if (!createEditLabModalIsOpen) {
      setCreateEditLabModalIsOpen(true)
      if (partner) {
        setCurrentLabPartner(partner)
      }
      if (lab) {
        setCurrentLab(lab)
      }
    } else {
      setCreateEditLabModalIsOpen(false)
      setCurrentLab(undefined)
      setCurrentLabPartner(undefined)
    }
  }

  return (
    <Container>
      <div style={{ marginTop: '20px' }}>
        <Button
          onClick={onNewClicked}
          style={{ marginRight: '1rem' }}
          variant="primary">
          <Icon className="plus" style={{ marginRight: '1rem' }} />
          Create Lab Partner
        </Button>
        <LabPartnersGrid
          columns={[
            {
              key: 'name',
              name: 'Lab Partner Name'
            },
            {
              key: 'countOfLabs',
              name: 'Count of Labs'
            },
            {
              key: 'displayAssociatedLabs',
              name: 'Display Associated Labs'
            }
          ]}
          fetchOptions={APIProvider.getAPICredentials()}
          fetchURL={`${AWS_BASE}/platform/account-management/rest/labpartners`}
          gridOptions={{
            style: {
              margin: '20px 0'
            },
            entityName: 'lab partner',
            pluralEntityName: 'lab partners'
          }}
          ref={gridRef}
          renderRow={(labPartner: LabPartner) => {
            return (
              <LabPartnerGridRow
                key={`labpartner-${labPartner.id}`}
                labPartner={labPartner}
                toggleCreateEditLabModal={toggleCreateEditLabModal}
              />
            )
          }}
          setFetchResponse={setLabPartnerData}
        />
        <Modal
          content={
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '2rem'
                }}>
                <h3>Create Lab Partner</h3>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <LabPartnerForm isNew />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '2rem'
                }}>
                <Button onClick={toggleModal}>Cancel</Button>
                <Button variant="primary" onClick={onSaveClicked}>
                  Save
                </Button>
              </div>
            </>
          }
          isOpen={isCreateModalOpen}
          toggle={toggleModal}
        />
        {createEditLabModalIsOpen && labPartnerData && (
          <>
            <CreateEditLabModal
              createEditLabModalIsOpen={createEditLabModalIsOpen}
              currentLab={currentLab}
              labPartner={currentLabPartner}
              labPartnerData={labPartnerData}
              reloadGrid={reloadGrid}
              toggleCreateEditLabModal={toggleCreateEditLabModal}
            />
          </>
        )}
      </div>
    </Container>
  )
}

export default LabPartners
