import * as React from 'react'
import { Table, TableCell } from 'semantic-ui-react'
import { DefinedMapping } from './shared'
import { pascalToFriendly } from '@120wateraudit/envirio-components/dist/utils/pascalToFriendly'

interface MappingViewRowProps {
  mapping: DefinedMapping
}

export const MappingViewRow = ({ mapping }: MappingViewRowProps) => (
  <Table.Row key={mapping.systemField.name}>
    <TableCell>{mapping.systemField.name}</TableCell>
    <TableCell>{mapping.userField.name}</TableCell>
    <TableCell>{getInfoColumn(mapping)}</TableCell>
  </Table.Row>
)

export const getInfoColumn = (mapping: DefinedMapping) => {
  if (!mapping.systemField.meta) {
    return null
  }

  const infoRows: JSX.Element[] = []
  const m = mapping.systemField.meta

  for (const key in m) {
    if (key === '__typename') {
      continue
    }
    if (key === 'customFieldDefinitionId') {
      continue
    }
    if (key === 'customFieldDefinitionEntityName' && m[key] !== null) {
      infoRows.push(
        <div key={key}>Entity Name: {pascalToFriendly(m[key])}</div>
      )
      continue
    }
    if (key === 'isRequired' && m[key]) {
      infoRows.push(<div key={key}>Is Required</div>)
      continue
    }
    if (key === 'isRequired' && !m[key]) {
      infoRows.push(<div key={key}>Is Optional</div>)
      continue
    }
    infoRows.push(
      <div key={key}>
        {camelToFriendlyCase(key)}: {m[key]}
      </div>
    )
  }
  return infoRows
}

const camelToFriendlyCase = (str: string) =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, s => s.toUpperCase())
