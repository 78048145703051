import {
  Button,
  Datepicker,
  Dropdown,
  Modal,
  ModalProps,
  TextField
} from '@120wateraudit/envirio-components';
import { Account } from '@120wateraudit/envirio-components/dist/models';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import * as React from 'react';

import { pwsClient, reportsClient } from 'src/apolloClient';
import {
  CREATE_REPORT_INSTANCE,
  CreateReportInstanceData,
  CreateReportInstanceVariables,
  PROGRAMS,
  ProgramsData,
  ProgramsVariables,
  REPORTS,
  ReportsData,
  ReportsVariables
} from 'src/components/Reports/CreateReportModal/dataAccess';
import { GET_ALL_REPORT_INSTANCES } from 'src/containers/Reports/data-access';
import { useCleanup, useFetch } from 'src/hooks';
import styled from 'src/theme';
import { ReportOutputType } from 'src/types/ReportOutputType';
import { convertStringEnumToDropdownOptions } from 'src/utils/SelectList';

export const CreateReportModal: React.FC<CreateReportModalProps> = ({
  toggle
}) => {
  const [state, dispatch] = React.useReducer(reportsReducer, initialState);
  const [getProgramsByAccount] = useLazyQuery<ProgramsData, ProgramsVariables>(
    PROGRAMS,
    {
      client: pwsClient,
      onCompleted: ({ programs }) =>
        dispatch({ payload: programs, type: DispatchType.PROGRAMS_SUCCESS })
    }
  );
  const [createReportInstance] = useMutation<
    CreateReportInstanceData,
    CreateReportInstanceVariables
  >(CREATE_REPORT_INSTANCE, {
    client: reportsClient,
    refetchQueries: [
      { query: GET_ALL_REPORT_INSTANCES, variables: { page: 0, pageSize: 10 } }
    ],
    onCompleted: () => toggle()
  });

  useQuery<ReportsData, ReportsVariables>(REPORTS, {
    client: reportsClient,
    onCompleted: ({ reports }) =>
      dispatch({ payload: reports, type: DispatchType.REPORTS_SUCCESS })
  });

  useFetch<Account[]>({
    url: `/platform/account-management/rest/accounts-all`,
    onCompleted: payload =>
      dispatch({
        payload: payload.filter(acct => !acct.isAccountDisabled),
        type: DispatchType.ACCOUNTS_SUCCESS
      })
  });

  useCleanup(() => {
    dispatch({ type: DispatchType.CLEAN_UP });
  });

  const onParamValueChange = React.useCallback(
    ([key, payload]) => {
      if (key === 'accountId') {
        getProgramsByAccount({ variables: { accountId: payload } });
      }

      dispatch({ key, payload, type: DispatchType.VALUE_CHANGE });
    },
    [getProgramsByAccount, dispatch]
  );

  const onRunReportClicked = () => {
    const params = getReportParams(state);
    createReportInstance({
      variables: {
        accountId: getStateValue(state, 'accountId'),
        outputType: getStateValue(state, 'outputType'),
        reportId: getStateValue(state, 'reportId'),
        ...(params && { params })
      }
    });
  };

  return (
    <Modal
      closeIcon
      content={
        <ModalContent params={state} onParamValueChange={onParamValueChange} />
      }
      footer={
        <ModalFooter
          toggle={toggle}
          onRunReportClicked={onRunReportClicked}
          state={state}
        />
      }
      header="Create Report"
      isOpen
      toggle={toggle}
    />
  );
};

const ModalContent = React.memo(
  <T extends object>({
    onParamValueChange,
    params
  }: Pick<ReportModalProps<T>, 'onParamValueChange' | 'params'>) => (
    <ReportFormStyled>
      {Object.values(params).map((param: State<T>) => (
        <ModalContentStyled key={param.key.toString()}>
          <TypedParamInput<T>
            disabled={param.disabled}
            keyName={param.key}
            label={param.label}
            onParamValueChange={onParamValueChange}
            options={param.options}
            type={param.type}
            value={params[param.key].value}
          />
        </ModalContentStyled>
      ))}
    </ReportFormStyled>
  )
);

const ModalFooter: React.FC<{
  state: typeof initialState;
  toggle: ModalProps['toggle'];
  onRunReportClicked: () => void;
}> = React.memo(({ onRunReportClicked, state, toggle }) => (
  <>
    <Button onClick={toggle}>Cancel</Button>
    <Button
      disabled={!hasRequiredFields(state)}
      onClick={onRunReportClicked}
      variant="primary">
      Run Report
    </Button>
  </>
));

export const TypedParamInput = <T extends object>({
  disabled,
  keyName,
  label,
  onParamValueChange,
  options,
  type,
  value
}: Pick<ReportModalProps<T>, 'onParamValueChange'> & {
  disabled?: boolean;
  keyName: keyof T;
  label: string;
  options?: Options[];
  type: ReportParamType;
  value?: any;
}) => {
  switch (type) {
    case 'dropdown':
      return (
        <Dropdown
          disabled={disabled}
          fluid
          label={label}
          onChange={(_, data) => onParamValueChange([keyName, data.value])}
          options={options}
          type="dropdown"
          value={value}
        />
      );

    case 'text':
      return (
        <TextField
          label={label}
          onChange={e => onParamValueChange([keyName, e.target.value])}
          type="text"
          value={value}
        />
      );

    case 'number':
      return (
        <TextField
          label={label}
          onChange={e => onParamValueChange([keyName, e.target.value])}
          type="number"
          value={value}
        />
      );

    case 'date':
      return (
        <Datepicker
          label={label}
          onChange={(date: Date) => {
            onParamValueChange([keyName, date]);
          }}
          value={value}
        />
      );

    default:
      throw new Error(
        `Unknown input type of ${type} provided for report param.`
      );
  }
};

const reportsReducer = <T, _>(
  state: { [K in string]: State<T> },
  { key, payload, type }: { key?: string; payload?: any; type: DispatchType }
) => {
  if (type === DispatchType.REPORTS_SUCCESS) {
    return {
      ...state,
      reportId: {
        ...state.reportId,
        options: payload.map(({ displayName, id, parameters }) => ({
          key: id,
          parameters,
          text: `${displayName} - ${id}`,
          value: id
        }))
      }
    };
  }

  if (type === DispatchType.ACCOUNTS_SUCCESS) {
    return {
      ...state,
      accountId: {
        ...state.accountId,
        options: payload.map(({ id, name }) => ({
          key: id,
          text: `${name} - ${id}`,
          value: id
        }))
      }
    };
  }

  if (type === DispatchType.PROGRAMS_SUCCESS) {
    return {
      ...state,
      programId: {
        ...state.programId,
        disabled: false,
        options: payload.map(({ id, name }) => ({
          key: id,
          text: `${name} - ${id}`,
          value: id
        }))
      }
    };
  }

  if (type === DispatchType.VALUE_CHANGE && key) {
    return {
      ...state,
      [key]: {
        ...state[key],
        value: payload
      }
    };
  }

  if (type === DispatchType.CLEAN_UP) {
    return initialState;
  }

  return state;
};

const getStateValue = (state: typeof initialState, key: string) =>
  state[key].value;

const getReportParams = (state: typeof initialState) => {
  const params = Object.values(state)
    .filter(({ reportParam }) => reportParam)
    .filter(({ value }) => value);

  return (
    (params.length > 0 &&
      JSON.stringify(
        // @ts-ignore
        Object.fromEntries(params.map(({ key, value }) => [key, value]))
      )) ||
    undefined
  );
};
const hasRequiredFields = (state: typeof initialState): boolean => {
  const values = Object.values(state);

  if (
    !values.filter(v => v.required).every(v => v.required === true && v.value)
  ) {
    return false;
  }

  const reportParameters = ((state.reportId.options || []).find(
    r => r.value === state.reportId.value
  ) as any).parameters;

  const missingValues = values
    .filter(v => v.reportParam)
    .filter(v => {
      const relatedParameter = reportParameters.find(
        rp => rp.parameterName.toLowerCase() === v.key.toString().toLowerCase()
      );
      if (relatedParameter && relatedParameter.isRequired && !v.value) {
        return true;
      }
      return false;
    });

  return !Boolean(missingValues.length);
};

const initialState: { [k in string]: State<any> } = {
  reportId: {
    disabled: false,
    key: 'reportId',
    label: 'Report ID *',
    options: [],
    required: true,
    type: 'dropdown',
    value: undefined
  },
  accountId: {
    disabled: false,
    key: 'accountId',
    label: 'Account ID',
    options: [],
    reportParam: true,
    type: 'dropdown',
    value: undefined
  },
  primacyCode: {
    disabled: false,
    key: 'primacyCode',
    label: 'Primacy Code',
    options: [],
    reportParam: true,
    type: 'text',
    value: undefined
  },
  programId: {
    disabled: true,
    key: 'programId',
    label: 'Program ID',
    options: [],
    reportParam: true,
    type: 'dropdown',
    value: undefined
  },
  startDate: {
    disabled: false,
    key: 'startDate',
    label: 'Start Date',
    options: [],
    reportParam: true,
    type: 'date',
    value: undefined
  },
  endDate: {
    disabled: false,
    key: 'endDate',
    label: 'End Date',
    options: [],
    reportParam: true,
    type: 'date',
    value: undefined
  },
  outputType: {
    disabled: false,
    key: 'outputType',
    label: 'Output Type *',
    options: convertStringEnumToDropdownOptions(ReportOutputType),
    required: true,
    type: 'dropdown',
    value: undefined
  }
};

type ReportParamType = 'date' | 'dropdown' | 'number' | 'text';

export interface State<T> {
  disabled?: boolean;
  key: keyof T;
  label: string;
  options?: Options[];
  reportParam?: boolean;
  required?: boolean;
  type: ReportParamType;
  value: any;
}

interface Options {
  key: string;
  text: string;
  value: number | string;
}

interface CreateReportModalProps extends Pick<ModalProps, 'toggle'> {
  reportId?: number;
  reportName?: string;
}

interface ReportModalProps<P extends object> {
  params: {
    [key in keyof P]: State<P>;
  };
  reportName: string;
  onParamValueChange: ([key, value]: [
    keyof P,
    undefined | Date | number | string
  ]) => void;
}

enum DispatchType {
  ACCOUNTS_SUCCESS,
  PROGRAMS_SUCCESS,
  REPORTS_SUCCESS,
  VALUE_CHANGE,
  CLEAN_UP
}

const ReportFormStyled = styled.form`
  margin: 4rem;
`;

const ModalContentStyled = styled.div`
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
`;
