import {
  AccountType,
  User
} from '@120wateraudit/envirio-components/dist/models';
import * as React from 'react';
import {
  Divider,
  Form,
  TableCell,
  TableRow,
  TableHeader,
  TableHeaderCell,
  Table,
  TableBody,
  Grid,
  Segment
} from 'semantic-ui-react';
import './styles.css';
import { Account } from '@120wateraudit/envirio-components/dist/models';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Dropdown, Field } from 'src/components/Forms';
import { ApplicationState } from 'src/reducers';
import { Auth0Role } from 'src/types/Auth0Role';
import SearchAccount from 'src/components/Common/SearchAccount';
import MFA from 'src/components/Forms/User/MFA';

interface Props {
  user: User;
  isEditing: boolean;
  isNew?: boolean;
  onInputChanged: any;
  onSelectListChanged: any;
  roleOptions: any;
  onRoleTypeChanged: any;
  showRoles?: boolean;
  applicationRoles: { [key in Auth0Role['_id']]: boolean };
  defaultAccounts: { [key in string]: Account };
  defaultAccountSetter: (type: number, account: Account | null) => void;
  applicationRoleOptions: Auth0Role[];
  onCheckboxChanged: any;
}

const UserForm = ({
  user,
  roleOptions,
  showRoles = true,
  applicationRoles,
  defaultAccounts,
  defaultAccountSetter,
  applicationRoleOptions,
  onCheckboxChanged
}: Props) => (
  <Form>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column width={8}>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={8}>
                <Form.Field>
                  <Field label="First Name" name="firstName" />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={8}>
                <Form.Field>
                  <Field label="Last Name" name="lastName" />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Field>
                  <Field label="Email" name="email" />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={8} stretched={true}>
          <Segment>
            <MFA user={user} />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    {showRoles && (
      <>
        <Divider section />
        <Form.Group>
          <Dropdown multiple name="roles" label="Roles" options={roleOptions} />
        </Form.Group>
      </>
    )}
    <h6 style={{ marginTop: 20, marginBottom: 20 }}>Applications</h6>
    <div>
      Applications listed below indicate which applications the user is
      connected to and default Account the User is using for that application.
    </div>
    <div>
      <b>
        Note: if you set the PWS or Schools Default Account the default Account
        on the User table in the database will be set
      </b>
    </div>
    <div>
      <b>
        Note: if you select a default account for both PWS and Schools at this
        time PWS will take priority and the Schools Default Account will be
        blanked out.
      </b>
    </div>
    &nbsp;
    <Form.Group inline>
      <Table celled striped>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>&nbsp;Application</TableHeaderCell>
            <TableHeaderCell>&nbsp;Default Account</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {applicationRoleOptions
            .sort((one, two) => (one.name < two.name ? -1 : 1))
            .map(role => (
              <TableRow key={role._id}>
                <TableCell>
                  <Form.Checkbox
                    checked={applicationRoles && applicationRoles[role._id]}
                    onChange={(e, value) => onCheckboxChanged(role._id, value)}
                    key={role._id}
                    label={role.name === 'Hydra' ? 'Insights' : role.name}
                  />
                </TableCell>
                <TableCell>
                  {role.name === 'BackOffice' || !applicationRoles[role._id] ? (
                    '---'
                  ) : (
                    <SearchAccount
                      userId={user.id}
                      type={AccountType[role.name]}
                      account={defaultAccounts[role.name]}
                      setAccount={defaultAccountSetter}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Form.Group>
  </Form>
);

export default connect<any, any, any>(
  (state: ApplicationState, ownProps: Props) => ({
    initialValues: ownProps.isNew ? new User() : state.user.item
  })
)(
  reduxForm<object, Props>({
    form: 'user'
  })(UserForm)
);
