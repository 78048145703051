import React, { FC, useState } from 'react'
import { Table, TableRow, TableCell } from 'semantic-ui-react'
import { Button } from '@120wateraudit/envirio-components'
import { Lab, LabPartner } from '@120wateraudit/envirio-components/dist/models'
import { ToggleIcon } from './ToggleIcon'
import { Labs } from '../Labs'
import styled from 'styled-components'

interface Props {
  labPartner: LabPartner
  toggleCreateEditLabModal: (labPartner?: LabPartner, lab?: Lab) => void
}

export const LabPartnerGridRow: FC<Props> = ({
  labPartner,
  toggleCreateEditLabModal
}: Props) => {
  const [accordionRowIsOpen, setAccordionRowIsOpen] = useState(false)

  const toggleIsOpen = () => {
    setAccordionRowIsOpen(!accordionRowIsOpen)
  }
  return (
    <>
      <TableRow>
        <TableCell>{labPartner.name}</TableCell>
        <TableCell>{labPartner.labs.length}</TableCell>
        <TableCell>
          <ToggleIcon isOpen={accordionRowIsOpen} toggleIsOpen={toggleIsOpen} />
        </TableCell>
      </TableRow>
      {accordionRowIsOpen && (
        <AccordionTableRow
          key={`${labPartner.id}-accordion`}
          className={'accordionTableBody'}>
          <Table.Cell colSpan={7}>
            <Header>
              <Heading>{labPartner.name}'s Labs</Heading>
              <NewLabButton>
                <Button
                  variant="primary"
                  onClick={() => {
                    toggleCreateEditLabModal(labPartner)
                  }}>
                  Create New Lab
                </Button>
              </NewLabButton>
            </Header>
            <Labs
              labPartner={labPartner}
              toggleCreateEditLabModal={toggleCreateEditLabModal}
            />
          </Table.Cell>
        </AccordionTableRow>
      )}
    </>
  )
}

const AccordionTableRow = styled(TableRow)`
  > td {
    border-top: 1px solid rgb(69, 206, 162) !important;
    padding-bottom: 1.25rem !important;
    border-bottom: 1px solid rgb(69, 206, 162) !important;
  }

  &.closed {
    visibility: hidden;
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
  }

  &.open {
    height: 54px;
    background-color: white;
    opacity: 1;
  }

  h4 {
    margin-top: 0.925rem;
    margin-left: 0.125rem;
    font-size: 1.05em;
  }

  .headerCellContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0.25rem;
    font-size: 1em;
  }

  .subTable {
    border: none;

    .tableHeader {
      height: 36px !important;

      & th {
        border-bottom: 1px solid black;
        font-weight: normal;
        color: #002834;
        opacity: 0.5;
        font-size: 1em !important;
      }
    }

    & tr.tableRow {
      height: 15px !important;

      > td {
        font-size: 1em !important;
        border: none !important;
      }
    }
  }
`
const Header = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: 'Heading NewLabButton';
`
const Heading = styled.h4`
  grid-area: Heading;
`
const NewLabButton = styled.div`
  grid-area: NewLabButton;
`
