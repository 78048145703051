import React, { useState } from 'react';
import { Card, Container, Label, Loader } from 'semantic-ui-react';
import {
  colors,
  PageHeading,
  TextNavigation
} from '@120wateraudit/envirio-components';
import { useGetAccountGroupQuery } from 'src/services';
import AccountGroupCustomFields from 'src/modules/AccountGroup/AccountGroupCustomFields';

const AccountGroupsDetailContainer = (props: any) => {
  const [activeTab, setActiveTab] = useState('Custom Fields');
  const { data: accountGroup } = useGetAccountGroupQuery({
    accountGroupId: Number(props.match.params.id)
  });

  const renderTab = () => {
    if (accountGroup) {
      switch (activeTab) {
        case 'Custom Fields':
          return <AccountGroupCustomFields id={accountGroup.id} />;
        default:
          return <AccountGroupCustomFields id={accountGroup.id} />;
      }
    }
    return <Loader />;
  };

  return (
    <Container>
      <Card style={{ marginTop: '20px', minHeight: 600 }} fluid={true}>
        <div
          style={{ paddingLeft: '10px', paddingRight: '10px', minHeight: 600 }}>
          {accountGroup && (
            <PageHeading
              title={
                <div>
                  <h3> {accountGroup.name}</h3>
                  <Label> type: {accountGroup.type}</Label>
                </div>
              }>
              <TextNavigation
                activeColor={colors.primary}
                activeItem={activeTab}
                inactiveColor={colors.black}
                items={[{ name: 'Custom Fields' }]}
                onItemClicked={(e, name) => setActiveTab(name.name)}
              />
              <div>{renderTab()}</div>
            </PageHeading>
          )}
        </div>
      </Card>
    </Container>
  );
};

export default AccountGroupsDetailContainer;
