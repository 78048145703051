import React from 'react'

// Components
import LabPartners from 'src/components/LabPartners'

// Actions
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import LabPartnersActions from 'src/actions/labPartners'

interface Props {
  createLabPartner: typeof LabPartnersActions.createActions.createRequest
}

interface State {
  isCreateModalOpen: boolean
}
class LabPartnersContainer extends React.Component<any, State> {
  grid: any

  state = { isCreateModalOpen: false }

  toggleCreateModal = () => {
    this.setState({
      isCreateModalOpen: !this.state.isCreateModalOpen
    })
  }

  initDefinitionsGrid = (gridEl: any) => {
    this.grid = gridEl
  }

  reloadGrid = () => {
    this.grid.fetchData()
  }

  onSaveClicked = () => {
    const { labPartner } = this.props

    this.props.createLabPartner({
      labPartner,
      callback: () => {
        this.reloadGrid()
        this.toggleCreateModal()
      }
    })
  }

  render() {
    return (
      <LabPartners
        gridRef={this.initDefinitionsGrid}
        onNewClicked={this.toggleCreateModal}
        isCreateModalOpen={this.state.isCreateModalOpen}
        reloadGrid={this.reloadGrid}
        toggleModal={this.toggleCreateModal}
        onSaveClicked={this.onSaveClicked}
      />
    )
  }
}

const mapStateToProps = state => ({
  labPartner: getFormValues('labPartner')(state)
})

const mapDispatchToProps = {
  createLabPartner: LabPartnersActions.createActions.createRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabPartnersContainer)
