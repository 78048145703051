import * as React from 'react'
import { Placeholder, Table } from 'semantic-ui-react'

export const UserFormPlaceholder = () => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div>
          <Placeholder style={{ marginBottom: '10px', marginLeft: '5px' }}>
            <Placeholder.Line length="short" />
          </Placeholder>
          <Placeholder
            style={{
              width: '200px',
              marginLeft: '5px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
            <Placeholder.Paragraph>
              <Placeholder.Line length="full" />
            </Placeholder.Paragraph>
          </Placeholder>
        </div>
        <div>
          <Placeholder style={{ marginBottom: '10px', marginLeft: '5px' }}>
            <Placeholder.Line length="short" />
          </Placeholder>
          <Placeholder
            style={{
              width: '200px',
              marginLeft: '5px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
            <Placeholder.Paragraph>
              <Placeholder.Line length="full" />
            </Placeholder.Paragraph>
          </Placeholder>
        </div>
        <div>
          <Placeholder style={{ marginBottom: '10px', marginLeft: '5px' }}>
            <Placeholder.Line length="short" />
          </Placeholder>
          <Placeholder
            style={{
              width: '200px',
              marginLeft: '5px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
            <Placeholder.Paragraph>
              <Placeholder.Line length="full" />
            </Placeholder.Paragraph>
          </Placeholder>
        </div>
      </div>
      <h6>Applications</h6>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex' }}>
          <Placeholder
            style={{
              width: '25px',
              marginLeft: '5px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
            <Placeholder.Line length="full" />
          </Placeholder>
          <Placeholder
            style={{
              width: '50px',
              marginLeft: '5px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
            <Placeholder.Line length="full" />
          </Placeholder>
        </div>
        <div style={{ display: 'flex' }}>
          <Placeholder
            style={{
              width: '25px',
              marginLeft: '5px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
            <Placeholder.Line length="full" />
          </Placeholder>
          <Placeholder
            style={{
              width: '50px',
              marginLeft: '5px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
            <Placeholder.Line length="full" />
          </Placeholder>
        </div>
        <div style={{ display: 'flex' }}>
          <Placeholder
            style={{
              width: '25px',
              marginLeft: '5px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
            <Placeholder.Line length="full" />
          </Placeholder>
          <Placeholder
            style={{
              width: '50px',
              marginLeft: '5px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
            <Placeholder.Line length="full" />
          </Placeholder>
        </div>
        <div style={{ display: 'flex' }}>
          <Placeholder
            style={{
              width: '25px',
              marginLeft: '5px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
            <Placeholder.Line length="full" />
          </Placeholder>
          <Placeholder
            style={{
              width: '50px',
              marginLeft: '5px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
            <Placeholder.Line length="full" />
          </Placeholder>
        </div>
      </div>
    </>
  )
}

interface PlaceholderProps {
  style?: React.CSSProperties
}

export const GenericPlaceholder = ({ style }: PlaceholderProps) => {
  return (
    <Placeholder style={style}>
      <Placeholder.Paragraph>
        <Placeholder.Line length="full" />
      </Placeholder.Paragraph>
    </Placeholder>
  )
}

export const PlaceholderTable = () => {
  return (
    <>
      <GenericPlaceholder style={{ width: '300px' }} />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Is Default?</Table.HeaderCell>
            <Table.HeaderCell>Account Type</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>{''}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={12}>
            <Table.Cell>
              <GenericPlaceholder style={{ width: '200px' }} />
            </Table.Cell>
            <Table.Cell>
              <GenericPlaceholder style={{ width: '200px' }} />
            </Table.Cell>
            <Table.Cell>
              <GenericPlaceholder style={{ width: '200px' }} />
            </Table.Cell>
            <Table.Cell>
              <GenericPlaceholder style={{ width: '200px' }} />
            </Table.Cell>
          </Table.Row>
          <Table.Row key={13}>
            <Table.Cell>
              <GenericPlaceholder style={{ width: '200px' }} />
            </Table.Cell>
            <Table.Cell>
              <GenericPlaceholder style={{ width: '200px' }} />
            </Table.Cell>
            <Table.Cell>
              <GenericPlaceholder style={{ width: '200px' }} />
            </Table.Cell>
            <Table.Cell>
              <GenericPlaceholder style={{ width: '200px' }} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  )
}
