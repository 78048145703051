import * as React from 'react'

import { withRouter } from 'react-router-dom'
import { Container } from 'semantic-ui-react'
import Navigation from 'src/containers/Navigation'
import styled from 'src/theme'

const AppStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface Props {
  logout: any
  children: any
  match: any
  location: any
  history: any
}

const App = ({ children, logout }: Props) => (
  <AppStyled>
    <Navigation logout={logout} />
    {children}
  </AppStyled>
)

export default withRouter<Props>(App)
