import React from 'react';
import { Container } from 'semantic-ui-react';
import Skus from 'src/components/Skus';

const SkusContainer = () => {
  return (
    <Container>
      <Skus />
    </Container>
  );
};

export default SkusContainer;
