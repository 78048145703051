import * as React from 'react'
import styled from 'styled-components'
import { Button, ButtonVariant, Modal } from '@120wateraudit/envirio-components'

export const EditModal = React.memo(
  ({
    cancelButtonVariant = 'default',
    cancelDisabled = false,
    cancelText = 'Cancel',
    closeIcon = true,
    content,
    contentStyle = {},
    isOpen,
    onCloseClicked,
    onSaveClicked,
    overflowStyle = 'visible',
    saveButtonVariant = 'primary',
    saveDisabled = false,
    saveText = 'Save',
    style = {},
    toggle
  }: EditModalProps) => (
    <Modal
      closeIcon={closeIcon}
      content={
        <ModalWrapper style={contentStyle}>
          <CloseIcon src="/close.svg" onClick={toggle} />
          {content}
          <ButtonsWrapper>
            <Button
              disabled={cancelDisabled}
              variant={cancelButtonVariant}
              onClick={() => {
                if (typeof onCloseClicked === 'function') {
                  onCloseClicked()
                } else {
                  toggle()
                }
              }}>
              {cancelText}
            </Button>
            <Button
              disabled={saveDisabled}
              variant={saveButtonVariant}
              onClick={onSaveClicked}>
              {saveText}
            </Button>
          </ButtonsWrapper>
        </ModalWrapper>
      }
      isOpen={isOpen}
      overflowStyle={overflowStyle}
      style={{
        ...style
      }}
      toggle={toggle}
    />
  )
)

export interface EditModalProps {
  cancelButtonVariant?: ButtonVariant
  cancelDisabled?: boolean
  cancelText?: string
  closeIcon?: boolean
  content: JSX.Element
  contentStyle?: any
  isOpen: boolean
  overflowStyle?: 'auto' | 'hidden' | 'scroll' | 'visible'
  saveButtonVariant?: ButtonVariant
  saveDisabled?: boolean
  saveText?: string
  style?: any
  onCloseClicked?: () => void
  onSaveClicked?: () => void
  toggle: () => any
}

const ModalWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem 0;
  justify-items: center;
`

const CloseIcon = styled.img`
  justify-self: right;
  cursor: pointer;
`
const ButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 0 1rem;
`
