import React from 'react';

// Components
import { Container, Divider, Header, Icon } from 'semantic-ui-react';
import * as moment from 'moment';
import { Button, spacing } from '@120wateraudit/envirio-components';
import ReportCard from 'src/components/Common/ReportCard';
import { CreateReportModal } from 'src/components/Reports/CreateReportModal';
import ReportsTable from 'src/components/Reports/ReportsTable';

// Hooks
import { useTitle, useToggle } from 'src/hooks';

const ReportsContainer: React.FC<{}> = () => {
  useTitle('Reports');
  const { enabled, toggle } = useToggle();
  return (
    <>
      <Container style={{ paddingTop: '20px' }}>
        <Button onClick={toggle} variant="primary">
          <Icon className="plus" style={{ marginRight: '1rem' }} />
          Create Report
        </Button>
        <Header as="h2">Available Reports </Header>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '33% 33% 33%',
            gridRowGap: spacing.small,
            marginBottom: spacing.med
          }}>
          <div style={{ marginRight: spacing.med }}>
            <ReportCard
              description="View counts of kits shipped and samples analyzed for the previous month"
              filename={`pws-monthly-invoicing-report-${moment().format(
                'MM-DD-YYYY'
              )}`}
              heading="PWS Monthly Kit Invoicing Report"
              url={`/platform/account-management/rest/reporting/pws/monthlyinvoicingreport`}
            />
          </div>
          <div style={{ marginLeft: spacing.med }}>
            <ReportCard
              description="View counts of samples analyzed for the previous month"
              filename={`schools-monthly-invoicing-report-${moment().format(
                'MM-DD-YYYY'
              )}`}
              heading="Schools Monthly Invoicing Report"
              url={`/platform/account-management/rest/reporting/schools/monthlyinvoicingreport`}
            />
          </div>
          <div style={{ marginLeft: spacing.med }}>
            <ReportCard
              description="LIMS Report for Daily Denver Reports for their internal purposes."
              filename={`${moment().format('YYYYMMDD')}-LIMS`}
              heading="Denver Daily LIMS Report"
              url={`/platform/account-management/rest/reporting/pws/denverdailylimsreport`}
              csv={true}
            />
          </div>
          <div style={{ marginRight: spacing.med }}>
            <ReportCard
              description="Tracking Report for Denver Reports for their internal purposes."
              filename={`${moment().format('YYYYMMDD')}-TrackingReport`}
              heading="Denver Water Tracking Report"
              url={`/platform/account-management/rest/reporting/pws/denverwatertrackingreport`}
              csv={true}
            />
          </div>
          <div style={{ marginLeft: spacing.med }}>
            <ReportCard
              description="DIST Location Export for Denver when doing Imports"
              filename={`${moment().format(
                'YYYYMMDD'
              )}-DISTLocationExportReport`}
              heading="Denver DIST Location Export Report"
              url={`/platform/account-management/rest/reporting/pws/denverdistlocationexportreport`}
              csv={true}
            />
          </div>
          <div style={{ marginLeft: spacing.med }}>
            <ReportCard
              description="Denver Returned To Sender Report"
              filename={`${moment().format('YYYYMMDD')}-ReturnedToSenderReport`}
              heading="Denver Returned To Sender Report"
              url={`/platform/account-management/rest/reporting/pws/denverreturnedtosenderreport`}
              csv={true}
            />
          </div>
          <div>
            <ReportCard
              description="Monthly Letters Report"
              filename={`${moment().format('YYYYMMDD')}-MonthlyLettersReport`}
              heading="Monthly Letters Report"
              url={`/platform/account-management/rest/reporting/pws/monthlylettersreport`}
              csv={true}
            />
          </div>
          <div style={{ marginLeft: spacing.med }}>
            <ReportCard
              description="CoC Issues Report"
              filename={`${moment().format('YYYYMMDD')}-CoCIssuesReport`}
              heading="CoC Issues Report"
              url={`/platform/account-management/rest/reporting/pws/CoCIssuesReport`}
              csv={true}
            />
          </div>
        </div>
        <Divider />
        <Header as="h2">Generated Reports</Header>
        <ReportsTable />
      </Container>
      {enabled && <CreateReportModal toggle={toggle} />}
    </>
  );
};

export default ReportsContainer;
