import { call, put, takeLatest } from 'redux-saga/effects'
import { ICrudActions } from 'src/actions/helpers/CrudActions'
import { APIProvider } from 'src/utils/API'
import { pushRoute } from 'src/utils/Navigation'

import { toast, ToastOptions } from 'react-toastify'
import { getErrorMessageFromResponse } from '../../utils/ApiErrors'

const defaultToastOpts: ToastOptions = {
  position: 'top-center',
  autoClose: 2500,
  hideProgressBar: true
}

const postSuccessMessage = (msg: string) =>
  toast(msg, {
    ...defaultToastOpts,
    type: 'success'
  })
const postErrorMessage = (msg: string) =>
  toast(msg, {
    ...defaultToastOpts,
    type: 'error'
  })

interface CrudSagaOptions<T> {
  entityName: string
  actions: ICrudActions
  createEntityMethod?: any
  createSuccessMessage?: (createdItem: T) => string
  fetchEntityMethod?: any
  updateEntityMethod?: any
  updateSuccessfulMessage?: (updatedItem: T) => string
  deleteEntityMethod?: any
  deleteSuccessMessage?: () => string
  fetchCollectionMethod?: any
}

export default function GenerateCrudSaga<T>({
  entityName,
  actions,
  createEntityMethod,
  createSuccessMessage,
  fetchEntityMethod,
  updateEntityMethod,
  updateSuccessfulMessage,
  deleteEntityMethod,
  deleteSuccessMessage,
  fetchCollectionMethod
}: CrudSagaOptions<T>) {
  function* fetchIndexSaga(action: any) {
    try {
      const item: T = yield ((call as unknown) as any)(
        fetchEntityMethod,
        ...[action.payload]
      )
      yield put(actions.detailsActions.fetchSuccess({ [entityName]: item }))
    } catch (error) {
      yield put(actions.detailsActions.fetchFailure({ error }))
    }
  }

  function* fetchCollectionSaga(action: any) {
    try {
      const response = yield ((call as unknown) as any)(
        fetchCollectionMethod,
        ...[action.payload]
      )
      yield put(
        actions.collectionActions.fetchSuccess({ response: response.items })
      )
    } catch (error) {
      yield put(actions.collectionActions.fetchFailure({ error }))
    }
  }

  function* updateSaga(action: any) {
    try {
      const item: T = yield ((call as unknown) as any)(
        updateEntityMethod,
        ...[action.payload]
      )
      const { redirect, callback } = action.payload

      const updatedAccount = yield put(
        actions.updateActions.updateSuccess({ [entityName]: item })
      )

      if (redirect) {
        pushRoute(redirect)
      }

      if (typeof callback === 'function') {
        callback()
      }

      if (typeof updateSuccessfulMessage === 'function') {
        postSuccessMessage(updateSuccessfulMessage(updatedAccount))
      }
    } catch (error) {
      yield put(actions.updateActions.updateFailure({ error }))
    }
  }

  function* deleteSaga(action: any) {
    try {
      const { redirect, callback } = action.payload

      yield ((call as unknown) as any)(deleteEntityMethod, ...[action.payload])
      yield put(actions.deleteActions.deleteSuccess())

      if (redirect) {
        pushRoute(redirect)
      }

      if (typeof callback === 'function') {
        callback()
      }
    } catch (error) {
      yield put(actions.deleteActions.deleteFailure({ error }))
    }
  }

  function* createSaga(action: any) {
    try {
      const { redirect, callback } = action.payload
      const item: T = yield ((call as unknown) as any)(
        createEntityMethod,
        ...[action.payload]
      )
      yield put(actions.createActions.createSuccess({ [entityName]: item }))

      if (redirect) {
        pushRoute(redirect)
      }

      if (typeof callback === 'function') {
        callback()
      }
    } catch (error) {
      try {
        const errorMessage = yield call(getErrorMessageFromResponse, error)
        yield put(actions.createActions.createFailure({ errors: errorMessage }))
      } catch (catchError) {
        yield put(
          actions.createActions.createFailure({ error: 'Unknown Error' })
        )
      }
    }
  }

  return function* crudSaga() {
    if (fetchEntityMethod) {
      yield takeLatest(
        actions.detailsActions.fetchRequest.toString(),
        fetchIndexSaga
      )
    }

    if (updateEntityMethod) {
      yield takeLatest(
        actions.updateActions.updateRequest.toString(),
        updateSaga
      )
    }

    if (deleteEntityMethod) {
      yield takeLatest(
        actions.deleteActions.deleteRequest.toString(),
        deleteSaga
      )
    }

    if (fetchCollectionMethod) {
      yield takeLatest(
        actions.collectionActions.fetchRequest.toString(),
        fetchCollectionSaga
      )
    }

    if (createEntityMethod) {
      yield takeLatest(
        actions.createActions.createRequest.toString(),
        createSaga
      )
    }
  }
}
