import { School } from '@120wateraudit/envirio-components/dist/models'
import { ApplicationState } from '../reducers/index'

export const getSchools = (state: ApplicationState): School[] =>
  state.schools.items

export const getSchoolsAsSelectList = (state: ApplicationState) =>
  getSchools(state).map(school => ({
    key: `${school.id}-${school.schoolName}`,
    text: school.schoolName,
    value: school.id
  }))
