// Libs
import { call, put, takeLatest } from 'redux-saga/effects'

// Actions
import {
  fetchSchoolDistrictsFailure,
  fetchSchoolDistrictsRequest,
  fetchSchoolDistrictsSuccess
} from 'src/actions/schoolDistricts'

// Utils
import { APIProvider } from '../utils/API'

// Types
import { SchoolDistrict } from '@120wateraudit/envirio-components/dist/models'

function* fetchSchoolDistrictsSaga(action: any) {
  try {
    const schoolDistricts: SchoolDistrict[] = yield call(
      APIProvider.fetchSchoolDistricts,
      action.payload.accountId
    )
    yield put(fetchSchoolDistrictsSuccess({ schoolDistricts }))
  } catch (error) {
    yield put(fetchSchoolDistrictsFailure({ error }))
  }
}

export default function* schoolDistrictsSaga() {
  yield takeLatest(
    fetchSchoolDistrictsRequest.toString(),
    fetchSchoolDistrictsSaga
  )
}
