import { MutationTuple, useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Feature } from '../types/Feature';
import { useState } from 'react';
import { featuresClient } from 'src/apolloClient';

const ALL_FEATURES_QUERY = gql`
  query allFeatures($accountType: Int!) {
    allFeatures(accountType: $accountType) {
      code
      name
      id
    }
  }
`;
const ACCOUNT_FEATURES_QUERY = gql`
  query features($accountId: Int!) {
    features(accountId: $accountId) {
      code
      name
      id
    }
  }
`;
const AVAILABLE_FEATURES_QUERY = gql`
  query availableFeatures($accountId: Int!) {
    availableFeatures(accountId: $accountId) {
      code
      name
      id
    }
  }
`;

const CREATE_ACCOUNT_FEATURE_MUTATION = gql`
  mutation createAccountFeature($accountId: Int!, $featureId: Int!) {
    createAccountFeature(accountId: $accountId, featureId: $featureId) {
      id
    }
  }
`;

const DELETE_ACCOUNT_FEATURE_MUTATION = gql`
  mutation deleteAccountFeature($accountId: Int!, $featureId: Int!) {
    deleteAccountFeature(accountId: $accountId, featureId: $featureId) {
      accountId
      featureId
    }
  }
`;
interface AllFeaturesResponse {
  allFeatures: Feature[];
}

interface FeaturesResponse {
  features: Feature[];
}

interface AvailableFeaturesResponse {
  availableFeatures: Feature[];
}

export const useAllFeatures = (accountType: number) => {
  const [allFeatures, setAllFeatures] = useState<Feature[]>();
  const { loading } = useQuery<AllFeaturesResponse>(ALL_FEATURES_QUERY, {
    client: featuresClient,
    fetchPolicy: 'cache-and-network',
    onCompleted(data: AllFeaturesResponse) {
      let accountFeaturesData: Feature[] = [];

      if (data && data.allFeatures) {
        accountFeaturesData = data.allFeatures;
      }
      setAllFeatures(accountFeaturesData);
    },
    variables: { accountType }
  });

  return { allFeatures, loading };
};
export const useFeaturesForAccount = (accountId: number) => {
  const [accountFeatures, setAccountFeatures] = useState<Feature[]>();
  const { loading } = useQuery<FeaturesResponse>(ACCOUNT_FEATURES_QUERY, {
    client: featuresClient,
    fetchPolicy: 'cache-and-network',
    onCompleted(data: FeaturesResponse) {
      let accountFeaturesData: Feature[] = [];

      if (data && data.features) {
        accountFeaturesData = data.features;
      }
      setAccountFeatures(accountFeaturesData);
    },
    variables: { accountId }
  });

  return { accountFeatures, loading };
};

export const useAvailableFeatures = (accountId?: number) => {
  const [availableFeatures, setAvailableFeatures] = useState<Feature[]>();
  const { loading } = useQuery<AvailableFeaturesResponse>(
    AVAILABLE_FEATURES_QUERY,
    {
      client: featuresClient,
      fetchPolicy: 'cache-and-network',
      onCompleted(data: AvailableFeaturesResponse) {
        let featuresData: Feature[] = [];

        if (data && data.availableFeatures) {
          featuresData = data.availableFeatures;
        }
        setAvailableFeatures(featuresData);
      },
      variables: { accountId }
    }
  );

  return { availableFeatures, loading };
};

interface AccountFeatureMutation {
  accountId: number;
  featureId: number;
}

interface AccountFeature {
  id: number;
}

export const useCreateAccountFeatureMutation = (): MutationTuple<
  AccountFeature,
  AccountFeatureMutation
> => {
  const [createAccountFeature, result] = useMutation<
    AccountFeature,
    AccountFeatureMutation
  >(CREATE_ACCOUNT_FEATURE_MUTATION, { client: featuresClient });
  return [createAccountFeature, result];
};

export const useDeleteAccountFeatureMutation = (): MutationTuple<
  AccountFeatureMutation,
  AccountFeatureMutation
> => {
  const [deleteAccountFeature, result] = useMutation<
    AccountFeatureMutation,
    AccountFeatureMutation
  >(DELETE_ACCOUNT_FEATURE_MUTATION, { client: featuresClient });

  return [deleteAccountFeature, result];
};
