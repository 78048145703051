import { handleActions } from 'redux-actions'

// Actions
import {
  fetchSchoolDistrictsFailure,
  fetchSchoolDistrictsRequest,
  fetchSchoolDistrictsSuccess
} from 'src/actions/schoolDistricts'

// Types
import { SchoolDistrict } from '@120wateraudit/envirio-components/dist/models'

export interface SchoolDistrictsState {
  error: any
  isFetching: boolean
  items: SchoolDistrict[]
}

const initialState = {
  error: undefined,
  isFetching: false,
  items: []
}

const schoolDistrictsReducer = handleActions(
  {
    [fetchSchoolDistrictsRequest.toString()]: (state: any, action: any) => ({
      ...state,
      error: undefined,
      isFetching: true
    }),
    [fetchSchoolDistrictsSuccess.toString()]: (state: any, action: any) => ({
      ...state,
      error: undefined,
      isFetching: false,
      items: action.payload.schoolDistricts
    }),
    [fetchSchoolDistrictsFailure.toString()]: (state: any, action: any) => ({
      ...state,
      error: action.payload.error,
      isFetching: false
    })
  },
  initialState
)

export default schoolDistrictsReducer
