import React from 'react';
import { Container } from 'semantic-ui-react';
import Grid from 'src/components/Grid';
import { GeoserverRefresh } from './GeoserverRefresh';
import { LocationDelete } from './LocationDelete';
import { LocationDeleteGridRow } from './LocationDeleteGridRow';
import { AWS_BASE } from 'src/utils/API';
import styled from 'src/theme';

export interface Props {
  accountId: number;
}

export const DataTools = ({ accountId }: Props) => {
  return (
    <>
      <h4 style={{ marginTop: 10 }}>Data Tools</h4>
      <GeoserverRefresh accountId={accountId} />
      <LocationDelete accountId={accountId} />
      <GridContainer>
        <Grid
          fetchUrl={`${AWS_BASE}/pws/accounts/${accountId}/delete/log`}
          columns={['Details', 'Deleted By', 'Deleted On', 'Status']}
          rowRenderer={item => (
            <LocationDeleteGridRow key={item.id} result={item} />
          )}
        />
      </GridContainer>
    </>
  );
};

const GridContainer = styled(Container)`
  padding: 15px;
`;
