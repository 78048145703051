import {
  colors,
  fontSize,
  lineHeight,
  Loader,
  spacing
} from '@120wateraudit/envirio-components';
import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from '../../theme/index';
import { useAuth0 } from '@auth0/auth0-react';
import { loginRequest } from 'src/actions/user';

interface Props {
  error: any | null;
  loginRequest: typeof loginRequest;
}

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex: 1;
`;

const Form = styled.form`
  display: flex;
  background: #ffffff;
  flex-direction: column;
  align-items: center;
  width: 27.33rem;
  margin: 0px auto;
  padding: 3rem;
  border-radius: 8px;
`;

const FormHeader = styled.div`
  background: #ffffff;
  padding-bottom: ${spacing.huge};
  text-align: center;
  flex-shrink: 0;
`;

const FormGroup = styled.div`
  margin-bottom: 1.33rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
`;

const FormGroupError = styled(FormGroup)`
  color: ${colors.error};
  display: inline-block;
  text-align: center;
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption};
`;

const Login = ({ error, loginRequest: loadUser }: Props) => {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0();
  const redirectUri = window.location.origin + '/login';

  const fetchUser = useCallback(async () => {
    const token = await getAccessTokenSilently();
    loadUser({
      token
    });
  }, [loadUser, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      fetchUser();
    }
    if (!isAuthenticated && !isLoading && !error) {
      loginWithRedirect({
        redirectUri
      });
    }
  }, [isAuthenticated, isLoading, error, loginWithRedirect]);

  if (isLoading || isAuthenticated || !error) {
    return <Loader />;
  }

  return (
    <FormWrapper>
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}>
        <FormHeader>
          <img
            style={{
              width: '18.5rem',
              height: '3rem'
            }}
            src="/logo-login.svg"
          />
        </FormHeader>
        {error && (
          <FormGroupError>
            {error.error === 'unauthorized' ? (
              <>
                Your user account does not have the permission needed to access.
                Please contact
                <Link to="mailto:feedback@120water.com">
                  {' '}
                  120WaterAudit Support{' '}
                </Link>
                for help.
              </>
            ) : (
              error
            )}
          </FormGroupError>
        )}
      </Form>
    </FormWrapper>
  );
};

export default connect(null, { loginRequest })(Login);
