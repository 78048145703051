import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { DARK_BLUE } from '@120wateraudit/waterworks';

interface Props {
  text: string | number;
  to: LinkProps['to'];
}

const LinkCell: React.FC<Props> = ({ text, to }: Props) => {
  return <StyledLink to={to}>{text}</StyledLink>;
};

const StyledLink = styled(Link)`
  -webkit-font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
  color: ${DARK_BLUE};
  word-break: break-word;
  &:hover {
    color: #0891b2;
  }
`;

export default LinkCell;
