import { User } from '@120wateraudit/envirio-components/dist/models'
import actionCreatorFactory from 'typescript-fsa'
const action = actionCreatorFactory()

export const loginRequest = action<{ token: string }>('LOGIN_REQUEST')
export const loginSuccess = action<{ user: User }>('LOGIN_SUCCESS')
export const loginFailure = action<{ error: string }>('LOGIN_FAILURE')
export const logout = action('LOGOUT')

export const createUser = action<{ user: User }>('CREATE_USER_REQUEST')
export const createUserSuccess = action<{ user: User }>('CREATE_USER_SUCCESS')
export const createUserFailuer = action<{ error: string }>(
  'CREATE_USER_FAILURE'
)
