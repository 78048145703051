import { useEffect } from 'react'

const baseTitle = 'BackOffice'

/**
 * `useTitle` used to update the documents title
 *
 * @param text text of the title added to the base title
 */
export const useTitle = (text: string) => {
  useEffect(() => {
    document.title = `${document.title} - ${text}`
    return () => {
      document.title = baseTitle
    }
  }, [text])
}
