import actionCreatorFactory from 'typescript-fsa'
const action = actionCreatorFactory()

import { SchoolDistrict } from '@120wateraudit/envirio-components/dist/models'

export const fetchSchoolDistrictsRequest = action<{ accountId: number }>(
  'FETCH_SCHOOL_DISTRICTS_REQUEST'
)
export const fetchSchoolDistrictsSuccess = action<{
  schoolDistricts: SchoolDistrict[]
}>('FETCH_SCHOOL_DISTRICTS_SUCCESS')
export const fetchSchoolDistrictsFailure = action<{ error: string }>(
  'FETCH_SCHOOL_DISTRICTS_FAILURE'
)
