import { useRef, useCallback } from 'react'

/**
 * `useAbort` - controller object that allows you to abort one or more Web requests as and when desired.
 */
export const useAbort = () => {
  const controller = useRef(new AbortController())
  const hasAborted = controller.current.signal.aborted

  const abort = useCallback(() => {
    if (!hasAborted) {
      controller.current.abort()
    }
  }, [controller, hasAborted])

  return { abort, hasAborted, controller: controller.current }
}
