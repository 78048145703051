import { Loader } from '@120wateraudit/envirio-components';
import * as React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { AUTH0_CLIENT_ID } from 'src/constants';
import jwt from 'jsonwebtoken';

import 'react-toastify/dist/ReactToastify.css';

// Actions
import { fetchRolesRequest } from 'src/actions/roles';
import { fetchSchoolDistrictsRequest } from 'src/actions/schoolDistricts';
import { fetchSchoolsRequest } from 'src/actions/schools';
import { loginSuccess, logout } from 'src/actions/user';
import MissingRole from 'src/router/MissingRole';

// Components
import App from 'src/components/App';

// State
import { UserState } from 'src/reducers/applicationUser';
import { pushRoute } from 'src/utils/Navigation';
import styled from 'styled-components';

interface Props extends WithAuth0Props {
  user: UserState;
  logout: any;
  loginSuccess: any;
  fetchRoles: typeof fetchRolesRequest;
  fetchSchoolDistricts: typeof fetchSchoolDistrictsRequest;
  fetchSchools: typeof fetchSchoolsRequest;
}

class AppContainer extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    let lsUser = window.localStorage.getItem('120_backoffice_user');

    if (lsUser && typeof lsUser === 'string' && !props.user) {
      try {
        lsUser = JSON.parse(lsUser);
        this.props.loginSuccess({ user: lsUser });
        this.props.fetchRoles();
      } catch (e) {
        console.group('Loading User Failed');
        // tslint:disable-next-line:no-console
        console.log(e);
        console.groupEnd();
      }
    }
    this.state = {
      user: props.user ? props.user : lsUser
    };
  }

  logout() {
    this.props.logout();
    this.props.auth0.logout({
      returnTo: window.location.origin + '/login',
      client_id: AUTH0_CLIENT_ID
    });
  }

  authTokenPresent = (): boolean => {
    const tokenKey = 'envirio_admin_token';
    return !!window.localStorage[tokenKey];
  };

  isAdminTokenExpired = () => {
    const tokenKey = 'envirio_admin_token';
    const token = window.localStorage[tokenKey];

    if (!token) {
      return true;
    }

    const current = Math.floor(Date.now() / 1000);
    const decoded = jwt.decode(token);
    return decoded.exp < current;
  };

  render() {
    if (!this.authTokenPresent()) {
      pushRoute('/login');
      return null;
    }

    if (this.isAdminTokenExpired()) {
      return this.logout();
    }

    if (!this.props.user) {
      return <LoadPage />;
    }

    const isPlatformAdmin = this.state?.user?.permissions?.some(
      r => r === 'platform:admin'
    );

    if (!isPlatformAdmin) {
      return <MissingRole logout={() => this.logout()} />;
    }

    return [
      <App
        key="application"
        logout={() => {
          this.logout();
        }}
        children={this.props.children}
      />,
      <ToastContainer key="toast-notifications" />
    ];
  }
}

const LoadPage = () => (
  <LoaderWrapper>
    <Loader />
  </LoaderWrapper>
);

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const mapDispatchToProps = {
  logout,
  loginSuccess,
  fetchRoles: fetchRolesRequest,
  fetchSchoolDistricts: fetchSchoolDistrictsRequest,
  fetchSchools: fetchSchoolsRequest
};

export default connect(null, mapDispatchToProps)(withAuth0(AppContainer));
