// Libs
import { call, put, takeLatest } from 'redux-saga/effects'
// Actions
import { loginFailure, loginRequest, loginSuccess } from 'src/actions/user'
// Utils
import { APIProvider } from 'src/utils/API'
import { pushRoute } from 'src/utils/Navigation'
import { setToken } from 'src/utils/Token'

interface LoginRequestOptions {
  type: string
  payload: {
    token: string
  }
}

function* loginSaga({ payload: { token } }: LoginRequestOptions) {
  try {
    // Save token to localStorage
    yield call(setToken, token)

    // Fetch current user
    const user = yield call(APIProvider.getCurrentUser)

    // Save user to localStorage
    window.localStorage.setItem('120_backoffice_user', JSON.stringify(user))

    // Update store
    yield put(loginSuccess({ user }))
    yield call(pushRoute, '/accounts')
  } catch (error) {
    let message = error.message

    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      message = 'Incorrect username or password.'
    } else if (
      error.response &&
      error.response.status &&
      error.response.status === 403
    ) {
      message = '403'
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      message = error.response.data.message
    }
    yield put(loginFailure({ error: message }))
  }
}

export default function* login() {
  yield takeLatest(loginRequest.toString(), loginSaga)
}
