import { MutationTuple, useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useState } from 'react'
import { featuresClient } from 'src/apolloClient'
import { SoftwarePackage } from 'src/types/SoftwarePackage'

export const GET_ALL_PACKAGES_QUERY = gql`
  query softwarePackages($accountType: Int!) {
    softwarePackages(accountType: $accountType) {
      id
      lastModifiedBy
      lastModifiedOn
      createdBy
      createdOn
      name
      accountType
    }
  }
`

export const GET_PACKAGE_FOR_ACCOUNT_QUERY = gql`
  query accountSoftwarePackage($accountId: Int!) {
    accountSoftwarePackage(accountId: $accountId) {
      packageId
      packageName
    }
  }
`

export const UPDATE_ACCOUNT_PACKAGE_MUTATION = gql`
  mutation updateAccountSoftwarePackage(
    $accountId: Int!
    $softwarePackageId: Int!
  ) {
    updateAccountSoftwarePackage(
      accountId: $accountId
      softwarePackageId: $softwarePackageId
    ) {
      packageId
      packageName
    }
  }
`

interface AllPackagesQuery {
  softwarePackages: SoftwarePackage[]
}

interface PackageForAccountQuery {
  accountSoftwarePackage: AccountPackage
}

interface AccountPackage {
  packageId: number
  packageName: string
}

export const useAllPackages = (accountType: number) => {
  const [packages, setPackages] = useState<SoftwarePackage[]>([])
  const { loading } = useQuery<AllPackagesQuery>(GET_ALL_PACKAGES_QUERY, {
    client: featuresClient,
    fetchPolicy: 'cache-and-network',
    onCompleted(data: AllPackagesQuery) {
      let packageData: SoftwarePackage[] = []
      if (data && data.softwarePackages) {
        packageData = data.softwarePackages
      }
      setPackages(packageData)
    },
    variables: { accountType }
  })

  return { packages, loading }
}

export const usePackageForAccount = (accountId: number) => {
  const [accountPackage, setAccountPackage] = useState<AccountPackage>()
  const { loading } = useQuery<PackageForAccountQuery>(
    GET_PACKAGE_FOR_ACCOUNT_QUERY,
    {
      client: featuresClient,
      fetchPolicy: 'cache-and-network',
      onCompleted(data: PackageForAccountQuery) {
        let packageData: AccountPackage | undefined
        if (data && data.accountSoftwarePackage) {
          packageData = data.accountSoftwarePackage
        }
        setAccountPackage(packageData)
      },
      variables: { accountId }
    }
  )

  return { accountPackage, loading }
}

interface UpdateAccountPackageMutation {
  accountId: number
  softwarePackageId: number
}

export const useUpdateAccountPackageMutation = (): MutationTuple<
  UpdateAccountPackageMutation,
  UpdateAccountPackageMutation
> => {
  const [updateAccountSoftwarePackage, result] = useMutation<
    UpdateAccountPackageMutation,
    UpdateAccountPackageMutation
  >(UPDATE_ACCOUNT_PACKAGE_MUTATION, { client: featuresClient })
  return [updateAccountSoftwarePackage, result]
}
