import * as React from 'react';

// Components
import { Card, FlatButton, Table as WWTable } from '@120wateraudit/waterworks';
import * as FileSaver from 'file-saver';

// Custom Hooks
import { useTableState } from 'src/hooks/useTableState';
import { useGetAccountImportsQuery } from 'src/services';
import {
  AccountImportError,
  AccountImportJob
} from 'src/types/AccountManagementTypes';

const generateErrors = (errors: AccountImportError[]) => {
  const csvRows: string[] = [];
  csvRows.push(`Row,Error`);
  const values = errors.map(value => `${value.row},${value.error}`);
  csvRows.push(...values);
  const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
  FileSaver.saveAs(blob, `errors.csv`, true);
};

export const AccountImportJobs = () => {
  const { params, setPageSize, setPageNumber } = useTableState({
    initialPageSize: 5
  });

  const { data: response, isFetching } = useGetAccountImportsQuery(
    {
      paramsData: params
    },
    {
      pollingInterval: 10 * 1000, // 10s
      refetchOnMountOrArgChange: true
    }
  );

  const COLUMNS = [
    {
      key: 'createdBy',
      Header: 'Created By',
      sortable: false,
      accessor: 'createdBy'
    },
    {
      key: 'lastModifiedOn',
      Header: 'Last Updated',
      sortable: false,
      accessor: 'lastModifiedOn'
    },
    {
      key: 'fileName',
      Header: 'File Name',
      sortable: false,
      accessor: 'fileName'
    },
    {
      key: 'totalRows',
      Header: 'Row Count',
      sortable: false,
      accessor: (job: AccountImportJob) => (
        <div>{job.status === 'Processing' ? '-' : job.metaData.rows}</div>
      )
    },
    {
      key: 'inserts',
      Header: 'Inserts',
      sortable: false,
      accessor: (job: AccountImportJob) => (
        <div>{job.status === 'Processing' ? '-' : job.metaData.inserts}</div>
      )
    },
    {
      key: 'updates',
      Header: 'Updates',
      sortable: false,
      accessor: (job: AccountImportJob) => (
        <div>{job.status === 'Processing' ? '-' : job.metaData.updates}</div>
      )
    },
    {
      key: 'totalErrors',
      Header: 'Errors',
      sortable: false,
      accessor: ({ status, metaData }: AccountImportJob) => (
        <div>
          {status === 'Processing' && '-'}
          {status !== 'Processing' && (
            <>
              {metaData.errorCount === 0 && `0`}
              {metaData.errorCount > 0 && (
                <FlatButton
                  variant={'error'}
                  style={{ padding: 5 }}
                  onClick={() => generateErrors(metaData.errors)}>
                  Download
                </FlatButton>
              )}
            </>
          )}
        </div>
      )
    },
    {
      key: 'status',
      Header: 'Status',
      sortable: false,
      accessor: 'status'
    }
  ];

  return (
    <Card style={{ marginTop: '20px' }}>
      <WWTable
        columns={COLUMNS}
        data={response?.items || []}
        itemName="Account Import Jobs"
        totalRecords={response?.count || 0}
        loading={isFetching}
        paginated
        page={params.pageNumber}
        pageSize={params.pageSize}
        onPageChanged={setPageNumber}
        onPageSizeChanged={setPageSize}
        searchable={false}
      />
    </Card>
  );
};
