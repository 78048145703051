import { createBrowserHistory } from 'history'

export const browserHistory = Object.assign(createBrowserHistory(), {
  getSearchObject: (searchString: string) => {
    try {
      const s = searchString.substring(1)
      return JSON.parse(
        '{"' +
          decodeURI(s)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      )
    } catch (e) {
      return {}
    }
  }
})

export const pushRoute = (route: string) => {
  browserHistory.push(route)
}

export const replaceRoute = (route: string) => {
  browserHistory.replace(route)
}

export const goBack = (fallback: string) => {
  if (browserHistory.length > 0) {
    browserHistory.goBack()
  } else {
    browserHistory.push(fallback)
  }
}
