import React from 'react';
import { toast } from 'react-toastify';
import { SearchResultProps } from 'semantic-ui-react';
import styled from 'styled-components';

export const SearchResult = ({ title, description }: SearchResultProps) => {
  return (
    <ResultWrapper>
      <UserWrapper>
        <Header>{`${title}`}</Header>
        <DescriptionHeader>{description}</DescriptionHeader>
      </UserWrapper>
    </ResultWrapper>
  );
};

const ResultWrapper = styled.div`
  display: flex;
`;
const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionHeader = styled.h6`
  color: grey;
  margin: 0;
  font-size: 1.1em;
  font-weight: 400;
  word-break: break-word;
`;

const Header = styled.h6`
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 400;
  word-break: break-word;
`;

export const toastSuccess = (message: string) => {
  toast(message, {
    position: 'top-center',
    autoClose: 1500,
    hideProgressBar: true,
    type: 'success'
  });
};

export const toastError = (message: string) => {
  toast(message, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    type: 'error'
  });
};
