import * as configcat from 'configcat-js';
import { useState } from 'react';
import { CONFIGCAT_KEY } from 'src/constants';

import { useAuth0 } from '@auth0/auth0-react';

export enum Flags {
  StateDashboardAccountType = 'stateDashboardAccountType',
  BackofficeAccountGroup = 'backofficeaccountgroup'
}

const DEBUG = 2;
const OFF = -1;
const logLevel = process.env.NODE_ENV !== 'development' ? OFF : DEBUG;
const logger = configcat.createConsoleLogger(logLevel);
const configCatClient = configcat.getClient(
  CONFIGCAT_KEY,
  configcat.PollingMode.AutoPoll,
  { logger }
);

// Because configcat does not export the type for their User class we use any here. :(
const useUser = (): any => {
  const auth = useAuth0();
  return { email: auth.user?.email, identifier: auth.user?.sub };
};

const getValueForFlag = async (
  user: any,
  flag: string,
  defaultValue = false
): Promise<boolean> => {
  const value = await configCatClient.getValueAsync(flag, defaultValue, user);
  return !!value;
};

export const useConfig = (flag: string, defaultValue = false): boolean => {
  const user = useUser();
  const [value, setValue] = useState(false);
  getValueForFlag(user, flag, defaultValue).then(setValue);
  return value;
};
