import React, { FC } from 'react'
import { Lab, LabPartner } from '@120wateraudit/envirio-components/dist/models'
import { LabRow } from './LabRow'
import { Table, TableBody } from 'semantic-ui-react'

interface Props {
  labPartner: LabPartner
  toggleCreateEditLabModal: (labPartner?: LabPartner, lab?: Lab) => void
}

export const LabsList: FC<Props> = ({
  labPartner,
  toggleCreateEditLabModal
}: Props) => {
  const labColumns = [
    {
      key: 'labId',
      name: 'Lab Id'
    },
    {
      key: 'labName',
      name: 'Lab Name'
    },
    {
      key: 'addressLine1',
      name: 'Address Line 1'
    },
    {
      key: 'addressLine2',
      name: 'Address Line 2'
    },
    {
      key: 'city',
      name: 'City'
    },
    {
      key: 'state',
      name: 'State'
    },
    {
      key: 'zip',
      name: 'Zip'
    },
    {
      key: 'contactName',
      name: 'Contact Name'
    },
    {
      key: 'phoneNumber',
      name: 'Phone Number'
    },
    {
      key: 'email',
      name: 'Email'
    }
  ]
  const labsList = labPartner.labs.map(lab => {
    return (
      <LabRow
        key={lab.id}
        lab={lab}
        labPartner={labPartner}
        toggleCreateEditLabModal={toggleCreateEditLabModal}
      />
    )
  })
  return (
    <Table className={'subTable'}>
      <TableBody>
        <Table.Row className={'tableHeader'}>
          {labColumns.map((labColumn, columnIndex) => {
            return (
              <Table.HeaderCell
                key={`table-headercell-${labColumn.key}-${columnIndex}`}>
                {labColumn.name}
              </Table.HeaderCell>
            )
          })}
        </Table.Row>
        {labsList}
      </TableBody>
    </Table>
  )
}
