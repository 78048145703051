import { StateList } from '@120wateraudit/envirio-components';
import {
  Account,
  AccountType
} from '@120wateraudit/envirio-components/dist/models';
import { ValidationErrors } from 'final-form';
import omit from 'lodash/omit';
import React from 'react';
import { Field, useField } from 'react-final-form';
import { Divider, Form, Icon, Input, Label } from 'semantic-ui-react';
import styled from 'styled-components';

import Dropdown from 'src/components/Dropdown/Dropdown';
import TextField from 'src/components/TextField/TextField';
import {
  ownerOptions,
  pwsTypeOptions,
  timezoneOptions
} from 'src/utils/SelectList';
import {
  isEmptyString,
  isValidZip,
  isValidSubdomain
} from 'src/utils/validation';

export interface Values {
  accountType?: AccountType | 'pws' | 'schools' | 'state' | 'hydra';
  city?: string;
  companyName?: string;
  connection?: string;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: string;
  isAccountDisabled?: boolean;
  isDemo?: boolean;
  latitude?: number | string;
  longitude?: number | string;
  name?: string;
  owner?: string;
  populationServedCount?: number | string;
  primacyCode?: string;
  pwsType?: string;
  pwsid?: string;
  sdwisContact?: string;
  serviceLineConnectionsCount?: number | string;
  state?: string;
  step: 'info' | 'type';
  streetAddressLine1?: string;
  streetAddressLine2?: string | null;
  subdomain?: string | null;
  timezone?: string;
  zip?: string;
}

const SDWIS_ID_REGEX = /^[a-zA-Z0-9]+$/;

export type AccountWithTimezone = Account & {
  owner?: string;
  populationServedCount?: number;
  primacyCode?: string;
  pwsType?: string;
  pwsid?: string;
  sdwisContact?: string;
  serviceLineConnectionsCount?: number;
  timezone?: string;
};

export const validate = (values: Values): ValidationErrors => {
  const errors: ValidationErrors = {};
  if (isEmptyString(values.name)) {
    errors.name = 'Name is Required';
  }

  if (isEmptyString(values.companyName)) {
    errors.companyName = 'Company Name is Required';
  }

  if (isEmptyString(values.streetAddressLine1)) {
    errors.streetAddressLine1 = 'Address Line 1 is Required';
  }

  if (isEmptyString(values.city)) {
    errors.city = 'City is Required';
  }

  if (isEmptyString(values.state)) {
    errors.state = 'State is Required';
  }

  if (!values.zip || isEmptyString(values.zip)) {
    errors.zip = 'ZIP is Required';
  } else if (!isValidZip(values.zip)) {
    errors.zip = 'ZIP must be a valid postal code';
  }

  if (values.subdomain && !isValidSubdomain(values.subdomain)) {
    errors.subdomain =
      'Subdomain must be a only letters, underscores, and dashes';
  }

  if (values.subdomain && values.subdomain.length > 50) {
    errors.subdomain = 'Subdomain must be a maximum of 50 characters';
  }

  if (isEmptyString('contactName')) {
    errors.contactName = 'Contact Name is Required';
  }

  if (isEmptyString('contactEmail')) {
    errors.contactEmail = 'Contact Email is Required';
  }

  if (
    (values.accountType === 'pws' || values.accountType === AccountType.PWS) &&
    values.pwsid &&
    (values.pwsid.trim().length !== 9 ||
      !SDWIS_ID_REGEX.test(values.pwsid.trim()))
  ) {
    errors.pwsid =
      'PWSID should be prefixed with the state code and have 7 or 5 numbers or 6 numbers and one letter following that. Ex IN1234567, UTAH12345, WA123456V';
  }

  if (
    (values.accountType === 'state' ||
      values.accountType === AccountType.StateDashboard) &&
    isEmptyString(values.primacyCode)
  ) {
    errors.primacyCode = 'Primacy Code is Required';
  }

  return errors;
};

export const sanitizeAccount = (values: Values): Partial<Values> => {
  const clonedValues = omit(values, ['__typename']);

  if (clonedValues.pwsid) {
    clonedValues.pwsid = clonedValues.pwsid.trim();
  }
  if (!clonedValues.isDemo) {
    clonedValues.isDemo = false;
  }

  if (clonedValues.contactPhone !== undefined) {
    clonedValues.contactPhone = clonedValues.contactPhone?.replace(/\D/g, '');
  }

  if (!clonedValues.streetAddressLine2) {
    clonedValues.streetAddressLine2 = null;
  }

  if (isEmptyString(clonedValues.subdomain || '')) {
    clonedValues.subdomain = null;
  }

  if (clonedValues.pwsid) {
    let primacyCode = clonedValues.pwsid.replace(/\d/g, '');
    if (!/[a-zA-Z]/.test(primacyCode)) {
      primacyCode = clonedValues.pwsid.slice(0, 2);
    }
    clonedValues.primacyCode = primacyCode;
  }

  if (clonedValues.primacyCode && clonedValues.primacyCode?.length > 4) {
    clonedValues.primacyCode = clonedValues.primacyCode
      .replace(/\d/g, '')
      .slice(0, 4);
  }

  if (clonedValues.populationServedCount) {
    clonedValues.populationServedCount = parseInt(
      `${clonedValues.populationServedCount}`,
      10
    );
  }

  if (clonedValues.serviceLineConnectionsCount) {
    clonedValues.serviceLineConnectionsCount = parseInt(
      `${clonedValues.serviceLineConnectionsCount}`,
      10
    );
  }

  if (clonedValues.latitude) {
    clonedValues.latitude = parseFloat(`${clonedValues.latitude}`);
  }

  if (clonedValues.longitude) {
    clonedValues.longitude = parseFloat(`${clonedValues.longitude}`);
  }

  switch (clonedValues.accountType) {
    case 'pws':
      clonedValues.accountType = AccountType.PWS;
      break;
    case 'schools':
      clonedValues.accountType = AccountType.Schools;
      break;
    case 'state':
      clonedValues.accountType = AccountType.StateDashboard;
      break;
    case 'hydra':
      clonedValues.accountType = AccountType.Hydra;
      break;
    default:
      clonedValues.accountType = AccountType.Unknown;
      break;
  }

  return clonedValues;
};

export const AccountForm = (): JSX.Element => {
  const {
    input: { value: accountType }
  } = useField('accountType');
  const isState =
    accountType === 'state' || accountType === AccountType.StateDashboard;
  const isHydra = accountType === 'hydra' || accountType === AccountType.Hydra;
  const isBackOffice =
    accountType === 'backoffice' || accountType === AccountType.Unknown;
  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field required>
          <Field<string> component={TextField} label="Name *" name="name" />
        </Form.Field>
        {!isBackOffice && (
          <Form.Field required>
            <Field<string>
              component={TextField}
              label="Company Name *"
              name="companyName"
            />
          </Form.Field>
        )}
        {isState && (
          <Form.Field>
            <Field<string>
              component={TextField}
              label="Primacy Agency Code *"
              name="primacyCode"
            />
          </Form.Field>
        )}
      </Form.Group>
      {!isState && !isHydra && !isBackOffice && (
        <Form.Group widths="equal">
          <SubdomainInput />
        </Form.Group>
      )}
      <Divider section />
      {!isBackOffice && (
        <>
          <Form.Group>
            <Form.Field style={{ width: '60%' }}>
              <Field<string>
                component={TextField}
                label="Address Line 1 *"
                name="streetAddressLine1"
              />
            </Form.Field>
            <Form.Field style={{ width: '40%' }}>
              <Field<string> component={TextField} label="City *" name="city" />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field style={{ width: '40%' }}>
              <Field<string>
                component={TextField}
                label="Address Line 2"
                name="streetAddressLine2"
              />
            </Form.Field>
            <Form.Field style={{ width: '35%' }}>
              <Field<string>
                component={Dropdown}
                fluid
                label="State *"
                name="state"
                options={StateList}
              />
            </Form.Field>
            <Form.Field style={{ width: '25%' }}>
              <Field<string> component={TextField} label="ZIP *" name="zip" />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Field<string>
                component={TextField}
                label="Latitude"
                name="latitude"
              />
            </Form.Field>
            <Form.Field>
              <Field<string>
                component={TextField}
                label="Longitude"
                name="longitude"
              />
            </Form.Field>
          </Form.Group>
          <Divider section />
          <Form.Group widths="equal">
            <Form.Field>
              <Field<string>
                component={TextField}
                label="Contact Name *"
                name="contactName"
              />
            </Form.Field>
            <Form.Field>
              <Field<string>
                component={TextField}
                label="Contact Phone"
                name="contactPhone"
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Field<string>
                component={TextField}
                label="Contact Email *"
                name="contactEmail"
              />
            </Form.Field>
          </Form.Group>
          <Divider section />
          <Form.Group widths="equal">
            <Form.Field>
              <Field<string>
                component={TextField}
                label="Auth0 Connection"
                name="connection"
              />
            </Form.Field>
            <Form.Field>
              <Field<string>
                component={Dropdown}
                fluid
                label="Timezone Setting"
                name="timezone"
                options={timezoneOptions}
              />
            </Form.Field>
          </Form.Group>
        </>
      )}
      {(accountType === 'pws' || accountType === AccountType.PWS) && (
        <PwsInfo />
      )}
    </Form>
  );
};

const SubdomainInput = (): JSX.Element => {
  const {
    input: { value: accountType }
  } = useField('accountType');
  const { input } = useField('subdomain');
  const isSchools =
    accountType === 'schools' || accountType === AccountType.Schools;
  const labelPosition = isSchools ? 'right' : 'left';

  const subdomainErrorStyling =
    input.value && !isValidSubdomain(input.value)
      ? { borderColor: 'red', borderWidth: '2px' }
      : {};

  const content = isSchools ? (
    <>
      <Label>https://</Label>
      <input
        style={subdomainErrorStyling}
        onChange={evt => input.onChange(evt.target.value)}
        value={input.value || ''}
      />
      <Label>.120wateraudit.com</Label>
    </>
  ) : (
    <>
      <Label>https://pws-ptd.120wateraudit.com/</Label>
      <input
        style={subdomainErrorStyling}
        onChange={evt => input.onChange(evt.target.value)}
        value={input.value || ''}
      />
    </>
  );

  return (
    <Form.Field>
      <label>
        PTD URL ( Note: The PTD URL for a School Account cannot be updated after
        initially set. Please get technical assistance if this is needed)
      </label>
      <Input labelPosition={labelPosition} type="text">
        {content}
      </Input>
    </Form.Field>
  );
};

const PwsInfo = (): JSX.Element => {
  return (
    <>
      <Divider section />
      <PWSIDHelpText>
        <p>
          For PWS Accounts we require the PWSID below to be blank or to provide
          the SDWIS ID.{` `}
          <a href="https://www.epa.gov/enviro/sdwis-search" target="_blank">
            You can help locate the correct ID here if desired.
          </a>
        </p>
        <a href="https://www.epa.gov/enviro/sdwis-search" target="_blank">
          <Icon name="question circle outline" size="large" />
        </a>
      </PWSIDHelpText>
      <Form.Group widths="equal">
        <Form.Field>
          <Field<string> component={TextField} label="PWSID" name="pwsid" />
        </Form.Field>
        <Form.Field>
          <Field<string>
            component={Dropdown}
            fluid
            label="PWS Type"
            name="pwsType"
            options={pwsTypeOptions}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <Field<string>
            component={TextField}
            label="SDWIS Contact"
            name="sdwisContact"
          />
        </Form.Field>
        <Form.Field>
          <Field<string>
            component={Dropdown}
            fluid
            label="PWS Owner"
            name="owner"
            options={ownerOptions}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <Field<string>
            component={TextField}
            label="Service Line Connections"
            min="0"
            name="serviceLineConnectionsCount"
            step="1"
            type="number"
          />
        </Form.Field>
        <Form.Field>
          <Field<string>
            component={TextField}
            label="Population Served"
            min="0"
            name="populationServedCount"
            step="1"
            type="number"
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

const PWSIDHelpText = styled.div`
  opacity: 0.5;
  font-weight: 800 !important;
  font-size: 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;
