import { Button } from '@120wateraudit/envirio-components'
import { User } from '@120wateraudit/envirio-components/dist/models'
import * as React from 'react'
import { Breadcrumb, Segment } from 'semantic-ui-react'
import UserForm from 'src/components/Forms/User'

// Utils
import { goBack } from 'src/utils/Navigation'

interface Props {
  user: User
  isEditing: boolean
  isSaving: boolean
  toggleEditing: () => any
  onInputChanged: any
  onSelectListChanged: any
  onClickUpdate: () => any
  roleOptions: any
  onRoleTypeChanged: any
}

const AccountUserDetails = ({
  user,
  onInputChanged,
  onSelectListChanged,
  isEditing,
  isSaving,
  toggleEditing,
  onClickUpdate,
  roleOptions,
  onRoleTypeChanged
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginTop: '20px'
      }}>
      <Segment clearing attached>
        <Breadcrumb size="huge">
          <Breadcrumb.Section link onClick={() => goBack('/accounts')}>
            Account
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section as="h5" active>
            Edit User
          </Breadcrumb.Section>
        </Breadcrumb>
        {!isEditing && (
          <div style={{ float: 'right' }}>
            <Button onClick={toggleEditing}>Edit</Button>
          </div>
        )}
      </Segment>
      <Segment attached clearing>
        <div style={{ paddingBottom: '20px' }}>
          <UserForm
            user={user}
            isEditing={isEditing}
            onInputChanged={onInputChanged}
            onSelectListChanged={onSelectListChanged}
            roleOptions={roleOptions}
            onRoleTypeChanged={onRoleTypeChanged}
          />
        </div>
      </Segment>
      {isEditing && (
        <Segment attached clearing>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={toggleEditing}>Cancel</Button>
            <Button
              variant="primary"
              disabled={isSaving}
              onClick={onClickUpdate}>
              {isSaving ? 'Updating...' : 'Update'}
            </Button>
          </div>
        </Segment>
      )}
    </div>
  )
}

export default AccountUserDetails
