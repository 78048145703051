import * as React from 'react'
import {
  WhiteFullWidthBox,
  CenteringWrapper,
  CenteredCardTitle,
  CenteredCardSubtitle,
  DefinedMapping
} from './shared'
import {
  FileUploadButton,
  Icons,
  Loader,
  colors
} from '@120wateraudit/envirio-components'
import { Table } from 'semantic-ui-react'
import { getInfoColumn } from './MappingViewRow'

interface EddTemplateDisplayProps {
  errorMessage?: string
  mappings: DefinedMapping[]
  onFileSelected(
    event: React.ChangeEvent<HTMLInputElement>,
    { file }: { file: File }
  ): void | Promise<void>
}

export const EddTemplateDisplay = ({
  errorMessage,
  mappings,
  onFileSelected
}: EddTemplateDisplayProps) => (
  <WhiteFullWidthBox>
    <CenteringWrapper>
      <Icons.Upload />
    </CenteringWrapper>
    <CenteredCardTitle>Edd Template</CenteredCardTitle>
    <CenteredCardSubtitle>Most recent for this account.</CenteredCardSubtitle>
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>System Field</Table.HeaderCell>
          <Table.HeaderCell>User Field</Table.HeaderCell>
          <Table.HeaderCell>Info</Table.HeaderCell>
        </Table.Row>
        {mappings.map(({ systemField, userField }, index) => (
          <Table.Row key={index}>
            <Table.Cell>{systemField.name}</Table.Cell>
            <Table.Cell>{userField.name}</Table.Cell>
            <Table.Cell>{getInfoColumn({ systemField, userField })}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Header>
    </Table>
    <CenteringWrapper>
      <div style={{ color: colors.error }}>{errorMessage}</div>
    </CenteringWrapper>
    <br />
    <CenteringWrapper>
      <br />
      <FileUploadButton
        accept=".xls, .xlsx"
        text="Upload New Template"
        onFileSelected={onFileSelected}
      />
    </CenteringWrapper>
  </WhiteFullWidthBox>
)
