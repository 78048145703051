import React, { useState } from 'react';

// Models
import { SearchParams, Sort } from 'src/services/types';
import { ActiveFilters, TableProps } from '@120wateraudit/waterworks';

type OnFilterChangeFunc = Required<
  TableProps<Record<string, unknown>>
>['onFilterChanged'];

type UseFiltersContext = [ActiveFilters, OnFilterChangeFunc];
type ValidFilterValue =
  | undefined
  | Array<boolean | number | string>
  | boolean
  | number
  | string;

export const useFilters = (
  defaultFilters?: ActiveFilters
): UseFiltersContext => {
  const [activeFilters, setActiveFilters] = React.useState<ActiveFilters>(
    defaultFilters === null || defaultFilters === undefined
      ? {}
      : defaultFilters
  );
  const onFilterChanged: OnFilterChangeFunc = ({ key, value }) => {
    if (value === null) {
      const newFilters = { ...activeFilters };
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newFilters[key];
      setActiveFilters(newFilters);
    } else {
      setActiveFilters({
        ...activeFilters,
        [key]: value
      });
    }
  };

  return [activeFilters, onFilterChanged];
};

interface TableContext {
  params: SearchParams;
  setSearchTermWrapper: (searchTerm: string) => void;
  setPageSize: (pageSize: number) => void;
  setPageNumber: (page: number) => void;
  setSort: (sort: Sort) => void;
  setFiltersWrapper: OnFilterChangeFunc;
}

interface TableStateParams {
  searchQueryType?: string;
  initialPageSize?: number;
  defaultFilters?: ActiveFilters;
}

export const useTableState = ({
  searchQueryType,
  initialPageSize,
  defaultFilters
}: TableStateParams): TableContext => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(initialPageSize || 10);
  const [sort, setSort] = React.useState<Sort | undefined>();
  const [activeFilters, setFilters] = useFilters(defaultFilters);

  const setSearchTermWrapper = (search: string) => {
    setPageNumber(1);
    setSearchTerm(search);
  };

  const setFiltersWrapper = (filters: {
    key: string;
    value: ValidFilterValue | null;
  }) => {
    setPageNumber(1);
    setFilters(filters);
  };

  const params = React.useMemo(() => {
    return {
      searchTerm,
      pageNumber,
      pageSize,
      sort,
      filters: activeFilters,
      searchQueryType
    };
  }, [searchTerm, pageNumber, pageSize, sort, activeFilters]);

  return {
    params,
    setSearchTermWrapper,
    setPageSize,
    setPageNumber,
    setSort,
    setFiltersWrapper
  };
};
