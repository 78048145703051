import * as React from 'react'

export const useToggle = (
  defaultValue = false
): { enabled: boolean; toggle: () => void } => {
  const [enabled, setEnabled] = React.useState<boolean>(defaultValue)
  const toggle = () => {
    setEnabled(!enabled)
  }

  return { enabled, toggle }
}
