import { handleActions } from 'redux-actions'

// Actions
import {
  fetchRolesFailure,
  fetchRolesRequest,
  fetchRolesSuccess
} from 'src/actions/applicationAccessRoles'

// Types
import { Auth0Role } from '../types/Auth0Role'

export interface Auth0RolesState {
  error: any
  isFetching: boolean
  items: Auth0Role[]
}

const initialState = {
  error: undefined,
  isFetching: false,
  items: []
}

const rolesReducer = handleActions(
  {
    [fetchRolesRequest.toString()]: (state: any, action: any) => ({
      ...state,
      error: undefined,
      isFetching: true
    }),
    [fetchRolesSuccess.toString()]: (state: any, action: any) => ({
      ...state,
      error: undefined,
      isFetching: false,
      items: action.payload.roles
    }),
    [fetchRolesFailure.toString()]: (state: any, action: any) => ({
      ...state,
      error: action.payload.error,
      isFetching: false
    })
  },
  initialState
)

export default rolesReducer
