// Libs
import { call, put, takeLatest } from 'redux-saga/effects'

// Actions
import {
  fetchSchoolsFailure,
  fetchSchoolsRequest,
  fetchSchoolsSuccess
} from 'src/actions/schools'

// Utils
import { APIProvider } from '../utils/API'

// Types
import { School } from '@120wateraudit/envirio-components/dist/models'

function* fetchSchoolsSaga(action: any) {
  try {
    const schools: School[] = yield call(
      APIProvider.fetchSchools,
      action.payload.accountId
    )
    yield put(fetchSchoolsSuccess({ schools }))
  } catch (error) {
    yield put(fetchSchoolsFailure({ error }))
  }
}

export default function* schoolsSaga() {
  yield takeLatest(fetchSchoolsRequest.toString(), fetchSchoolsSaga)
}
