import CrudReducer from 'src/reducers/helpers/DetailsCrudReducer'

// Accounts
import UserActions from 'src/actions/users'

export default CrudReducer({
  entityName: 'user',
  fetchAction: UserActions.detailsActions.fetchRequest,
  fetchSuccessAction: UserActions.detailsActions.fetchSuccess,
  fetchFailureAction: UserActions.detailsActions.fetchFailure,
  updateActionRequest: UserActions.updateActions.updateRequest,
  updateActionSuccess: UserActions.updateActions.updateSuccess,
  updateActionFailure: UserActions.updateActions.updateFailure,
  unloadAction: UserActions.detailsActions.unload,
  deleteAction: UserActions.deleteActions.deleteRequest,
  deleteSuccessAction: UserActions.deleteActions.deleteSuccess,
  deleteFailureAction: UserActions.deleteActions.deleteFailure,
  createActionRequest: UserActions.createActions.createRequest,
  createActionSuccess: UserActions.createActions.createSuccess,
  createActionFailure: UserActions.createActions.createFailure
})
