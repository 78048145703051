import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useCallback } from 'react';
import { Form as FinalForm, useField } from 'react-final-form';
import { Button, Container, Modal } from 'semantic-ui-react';

import { ApolloError } from 'apollo-client';
import { accountMgmtClient } from 'src/apolloClient';
import { toastError } from 'src/components/shared';
import { Flags, useConfig } from 'src/hooks/useConfig';
import { pushRoute } from 'src/utils/Navigation';
import { getErrorMessagesFromResponse } from 'src/utils/graphQLErrors';
import { AccountForm, Values, sanitizeAccount, validate } from './Form';
import { AccountType } from '@120wateraudit/envirio-components/dist/models';

const CREATE_ACCOUNT_MUTATION = gql`
  mutation createAccount($account: AccountInputType, $timezone: String!) {
    createAccount(account: $account, timezone: $timezone) {
      id
    }
  }
`;

const useSubmit = () => {
  const [createAccount] = useMutation(CREATE_ACCOUNT_MUTATION, {
    client: accountMgmtClient,
    onError(error: ApolloError) {
      toastError(getErrorMessagesFromResponse(error));
    }
  });
  return useCallback(
    async (values: Values) => {
      const { step, timezone, ...account } = sanitizeAccount(values);
      // The API for creation does not currently accept the `isDemo` flag
      delete account.isDemo;

      if (account.accountType === AccountType.PWS && !account.pwsType) {
        account.pwsType = 'CWS';
      }

      const newAccount = await createAccount({
        variables: { account, timezone }
      });
      const accountId = newAccount?.data?.createAccount?.id;
      if (accountId) {
        pushRoute(`/accounts/${accountId}`);
      } else {
        toastError('Account could not be created');
      }
    },
    [createAccount]
  );
};

const INITIAL_VALUES: Values = {
  step: 'type',
  timezone: 'Eastern'
};

interface Props {
  open: boolean;
  onClose(): void;
}

export const CreateAccountModal = ({ onClose, open }: Props): JSX.Element => {
  const submit = useSubmit();
  return (
    <Modal closeIcon onClose={onClose} open={open} size="large">
      <Modal.Header>Create a New Account</Modal.Header>
      <FinalForm
        initialValues={INITIAL_VALUES}
        onSubmit={submit}
        render={({ values, valid, handleSubmit }) => (
          <>
            <Modal.Content>
              {values.step === 'type' && <AccountTypeChoice />}
              {values.step === 'info' && <AccountForm />}
            </Modal.Content>
            <Modal.Actions>
              <BackButton onClose={onClose} />
              <AdvanceButton valid={valid} handleSubmit={handleSubmit} />
            </Modal.Actions>
          </>
        )}
        validate={validate}
      />
    </Modal>
  );
};

const AccountTypeChoice = (): JSX.Element => {
  const hasStateType = useConfig(Flags.StateDashboardAccountType);
  const {
    input: { onChange, value }
  } = useField('accountType');
  return (
    <Container
      style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
      <AccountChoice
        active={value === 'schools'}
        description="Run Sampling Programs for Schools & Day Cares"
        label="Schools"
        onClick={() => onChange('schools')}
      />
      <AccountChoice
        active={value === 'pws'}
        description="Run Sampling, Inventory, and Communications for a PWS"
        label="PWS"
        onClick={() => onChange('pws')}
      />
      {hasStateType && (
        <AccountChoice
          active={value === 'state'}
          description="Handle compliance reporting to the EPA for a State"
          label="State"
          onClick={() => onChange('state')}
        />
      )}
      <AccountChoice
        active={value === 'hydra'}
        description="Metrics application view for child accounts"
        label="Insights"
        onClick={() => onChange('hydra')}
      />
    </Container>
  );
};

const AccountChoice = ({
  active,
  description,
  label,
  onClick
}: {
  active?: boolean;
  description: string;
  label: string;
  onClick(): void;
}) => (
  <Button
    onClick={onClick}
    primary={active}
    size="large"
    style={{ width: '164px' }}>
    <h5>{label}</h5>
    <p>{description}</p>
  </Button>
);

const BackButton = ({ onClose }: { onClose(): void }): JSX.Element => {
  const {
    input: { onChange, value: step }
  } = useField('step');

  if (step === 'type') {
    return <Button onClick={onClose}>Cancel</Button>;
  }

  return <Button onClick={() => onChange('type')}>Back</Button>;
};

const AdvanceButton = ({
  handleSubmit,
  valid
}: {
  valid: boolean;
  handleSubmit(): void;
}): JSX.Element => {
  const {
    input: { onChange, value: step }
  } = useField('step');
  const {
    input: { value: accountType }
  } = useField('accountType');
  const onTypeStep = step === 'type';
  const onClick = () => {
    if (onTypeStep) {
      onChange('info');
    } else {
      handleSubmit();
    }
  };
  return (
    <Button
      disabled={onTypeStep ? !accountType : !valid}
      onClick={onClick}
      positive>
      {onTypeStep ? 'Next' : 'Create'}
    </Button>
  );
};
