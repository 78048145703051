import * as React from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

// Containers
import FormContainer from 'src/containers/FormContainer'

// Components
import CreateUser from 'src/components/Users/create'

// Utils
import { pushRoute } from 'src/utils/Navigation'

// Actions
import UserActions from 'src/actions/users'
import { ApplicationState } from 'src/reducers'
import { getRolesAsSelectList } from '../../selectors/roles'

class CreateUserContainer extends FormContainer<any, any> {
  onCancelClicked() {
    pushRoute('/users')
  }

  onSaveClicked = () => {
    const { account } = this.props
    const redirect = `/users`

    this.props.createAccount({
      account,
      redirect
    })
  }

  render() {
    const { isSaving, roles } = this.props

    return (
      <CreateUser
        isSaving={isSaving}
        onClickSave={this.onSaveClicked}
        onCancelClicked={this.onCancelClicked}
        roleOptions={roles}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  isSaving: state.account.isSaving,
  user: getFormValues('user')(state),
  roles: getRolesAsSelectList(state)
})

const mapDispatchToProps = {
  createUser: UserActions.createActions.createRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserContainer)
