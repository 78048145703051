import {
  Setting,
  SettingDefinition
} from '@120wateraudit/envirio-components/dist/models'
import SettingsActions from 'src/actions/settings'
import CrudSaga from 'src/sagas/helpers/CrudSaga'

import { APIProvider } from 'src/utils/API'

export default CrudSaga<Setting>({
  entityName: 'setting',
  actions: SettingsActions,
  fetchCollectionMethod: APIProvider.fetchSettings,
  createEntityMethod: APIProvider.createSettings,
  createSuccessMessage: (settings: Setting) => `Setting created!`,
  updateEntityMethod: APIProvider.updateSettings,
  updateSuccessfulMessage: (settings: Setting) =>
    `Settings successfully updated!`,
  deleteEntityMethod: APIProvider.deleteSettings
})
