import { createApi } from '@reduxjs/toolkit/query/react';

// Models
import { Tag, TagLabel, SearchParams } from 'src/services/types';
import PagedModel from 'src/types/PagedModel';
import { PWSSampleRecord } from 'src/types/Fulfillment';

// Utils
import baseQuery from 'src/services/baseQuery';
import { buildParameters } from 'src/services/utils';
import { SampleStatus } from 'src/types/Sample';

interface GeneralRequest {
  paramsData: SearchParams;
}

interface UpdatePWSSampleStatusRequest {
  status: SampleStatus;
  sampleIds: number[];
}

interface UpdatedPWSSampleStatus {
  id: number;
  newStatus: string;
  status: number;
}

export const pwsService = createApi({
  baseQuery: baseQuery({
    baseUrl: '/pws/rest/'
  }),
  reducerPath: 'fulfillment',
  tagTypes: [Tag.Fulfillment],
  endpoints: builder => ({
    getFulfillmentSamples: builder.query<
      PagedModel<PWSSampleRecord>,
      GeneralRequest
    >({
      providesTags: [{ id: TagLabel.List, type: Tag.Fulfillment }],
      query: ({ paramsData }) => {
        const params = buildParameters(paramsData).toString();
        return {
          method: 'GET',
          url: `fulfillment/samples?${params}`
        };
      }
    }),
    updatePWSSampleStatus: builder.mutation<
      UpdatedPWSSampleStatus[],
      UpdatePWSSampleStatusRequest
    >({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.Fulfillment }],
      query: ({ status, sampleIds }) => {
        return {
          method: 'POST',
          body: {
            sampleIds,
            status
          },
          url: `/fulfillment/samples/status`
        };
      }
    })
  })
});

export const {
  useGetFulfillmentSamplesQuery,
  useUpdatePWSSampleStatusMutation
} = pwsService;

export default pwsService;
