import React, { useState } from 'react';
import {
  Button,
  Modal,
  Radio,
  Loader
} from '@120wateraudit/envirio-components';
import ReactDropzone from 'react-dropzone';
import { AWS_BASE, APIProvider } from 'src/utils/API';
import { ApiError } from 'src/types/ApiError';
import axios from 'axios';
import styled from 'src/theme';

interface Props {
  accountId: number;
}

export const LocationDelete = ({ accountId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState<File | any>(undefined);
  const [deleteAllLocations, setDeleteAllLocations] = useState(false);
  const [toggleConfirmModal, setToggleConfirmModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState<Array<ApiError | string> | undefined>(
    undefined
  );

  const onDeleteLocations = async () => {
    setIsUploading(true);
    setErrors([]);

    const data = new FormData();

    data.append('file', file);

    try {
      await axios.delete(
        `${AWS_BASE}/pws/accounts/${accountId}/delete/locations`,
        {
          headers: {
            ...APIProvider.getAPICredentials().headers
          },
          data
        }
      );
      setIsModalOpen(false);
    } catch (e) {
      let errorsReceived: Array<ApiError | string> = [];
      if (
        e.response &&
        e.response.data &&
        e.response.data.name === 'BadRequestError'
      ) {
        if (typeof e.response.data.message === 'string') {
          const errorsInString = e.response.data.message.split('<br />');
          errorsReceived = [...errorsInString];
        } else {
          errorsReceived.push('An error occurred processing your file.');
        }
      }
      setErrors(errorsReceived);
    } finally {
      setIsUploading(false);
    }
  };

  const getInitialState = () => {
    setIsModalOpen(false);
    setFile(undefined);
    setDeleteAllLocations(false);
    setToggleConfirmModal(false);
    setIsUploading(false);
    setErrors(undefined);
  };

  const toggleModal = () => {
    getInitialState();
    if (isModalOpen) {
      getInitialState();
    } else {
      setIsModalOpen(true);
    }
  };

  const toggleDeleteLocationsRadio = () => {
    if (deleteAllLocations) {
      setDeleteAllLocations(false);
    } else {
      setDeleteAllLocations(true);
      setFile(undefined);
    }
  };

  const onFileDropped = ([droppedFile]: File[]) => {
    if (!file && deleteAllLocations) {
      toggleDeleteLocationsRadio();
    }
    setFile(droppedFile);
  };

  const onToggleConfirmModal = () => {
    setToggleConfirmModal(true);
  };

  return (
    <>
      <DeleteLocationsButton>
        <h5>Delete Records</h5>
        <Button variant="primary" onClick={toggleModal}>
          Initiate
        </Button>
      </DeleteLocationsButton>

      <Modal
        content={
          <>
            {toggleConfirmModal ? (
              <ConfirmDeleteHeader>
                Are you sure you want to delete {deleteAllLocations && 'ALL '}
                records for this account?
                {isUploading && <Loader />}
                {!isUploading && errors && (
                  <Errors>
                    {errors.map((e, idx) => (
                      <li key={`${idx}-upload-error`}>{e}</li>
                    ))}
                  </Errors>
                )}
              </ConfirmDeleteHeader>
            ) : (
              <div>
                <ModalHeader>
                  <h3>Delete Records</h3>
                </ModalHeader>
                <TextBody>
                  <div>
                    Locations with activity, attachment, communication, kit,
                    sample, or work order relationships will not be deleted. All
                    contacts and assets related to the deleted locations will be
                    removed as well.
                  </div>
                </TextBody>

                <FileUploadContainer>
                  <FileUploadHeader>
                    <p>Upload File</p>
                    <Button
                      type="button"
                      variant="primary"
                      onClick={onToggleConfirmModal}
                      disabled={!deleteAllLocations && !file}>
                      Next
                    </Button>
                  </FileUploadHeader>

                  <ReactDropzone
                    className="locations-upload"
                    onDrop={onFileDropped}>
                    <UploadInstructions>
                      {file ? (
                        <p>{file.name}</p>
                      ) : (
                        <>
                          <UploadPromptText>
                            Drag and drop data file here,
                            <br />
                            or <LinkText>click to browse files</LinkText>
                          </UploadPromptText>
                        </>
                      )}

                      <Button type="button">Browse</Button>
                    </UploadInstructions>
                  </ReactDropzone>
                  <AcceptedFileTypes>
                    <div>Accepted file type: .csv only</div>
                    <Secure>
                      <i className="lock icon" />
                      SECURE
                    </Secure>
                  </AcceptedFileTypes>
                </FileUploadContainer>
              </div>
            )}

            <ModalFooter>
              {toggleConfirmModal ? (
                <Button
                  type="button"
                  variant="primary"
                  onClick={onDeleteLocations}>
                  Confirm
                </Button>
              ) : (
                <Radio
                  label="Delete All Data For This Account"
                  onChange={toggleDeleteLocationsRadio}
                  checked={deleteAllLocations}
                  toggle
                />
              )}
              <Button onClick={toggleModal}>Cancel</Button>
            </ModalFooter>
          </>
        }
        isOpen={isModalOpen}
        toggle={toggleModal}
      />
    </>
  );
};

const ConfirmDeleteHeader = styled.div`
  font-size: 1.3rem;
`;

const Secure = styled.div`
  display: flex;
  align-items: baseline;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

const TextBody = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const AcceptedFileTypes = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
`;

const FileUploadHeader = styled.div`
  font-weight: bold;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  p {
    font-size: 1.5rem;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  marginbottom: 2rem;
  justify-content: center;
`;

const UploadPromptText = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
`;

const LinkText = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const UploadInstructions = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 2rem;
  align-items: center;
  margin: 0px auto;
  text-align: center;
  height: 116px;
  border-radius: 4px;
  border: 2px dashed ${props => props.theme.colors.primary};
  margin-top: 2rem;

  p {
    margin: unset;
  }
`;

const FileUploadContainer = styled.div`
  background-color: #f6f7f7;
  padding: 1rem;
`;

const DeleteLocationsButton = styled.div`
  display: block;
  padding: 15px;
`;

const Errors = styled.ul`
  margin-top: 2rem;
  color: ${props => props.theme.colors.error};
`;
