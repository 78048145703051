import * as React from 'react'
import {
  EddTemplateWizardProps,
  EddField
} from '../../components/EddTemplate/shared'
import { NoEddTemplateFound } from '../../components/EddTemplate/NoEddTemplateFound'
import { EddTemplateDisplay } from '../../components/EddTemplate/EddTemplateDisplay'
import {
  uploadEddTemplate,
  CREATE_EDD_TEMPLATE,
  GET_EDD_WIZARD_DATA
} from './data-access'
import { Mutation } from 'react-apollo'
import { AccountType } from '@120wateraudit/envirio-components/dist/models'
import { Loader } from '@120wateraudit/envirio-components'

interface EddUploadViewContainerProps {
  accountId: number
  accountType: AccountType
  mostRecentEddTemplate: { EDDJSONData: string } | null
  requiredFields: EddField[]
  optionalFields: EddField[]
  wizardRenderer(renderProps: EddTemplateWizardProps): JSX.Element
  hasDefinitionsRow: boolean
  onDefinitionsRowChanged(): void
}

export class EddUploadViewContainer extends React.Component<
  EddUploadViewContainerProps,
  EddUploadViewContainerState
> {
  constructor(props: EddUploadViewContainerProps) {
    super(props)
    this.init()
  }

  render() {
    const {
      accountId,
      accountType,
      optionalFields,
      requiredFields,
      mostRecentEddTemplate,
      hasDefinitionsRow,
      onDefinitionsRowChanged
    } = this.props

    const { uploadData, wizardOpen } = this.state

    return (
      <Mutation
        mutation={CREATE_EDD_TEMPLATE}
        refetchQueries={getRefetchQueries({ accountId, accountType })}>
        {(createEddTemplate, { loading }) => {
          if (loading || this.state.loading) {
            return <Loader />
          }
          if (wizardOpen && uploadData) {
            return this.props.wizardRenderer({
              requiredFields,
              optionalFields,
              uploadData,
              closeWizard: this.closeWizard,
              onCompleted: this.onWizardComplete(createEddTemplate),
              hasDefinitionsRow,
              onDefinitionsRowChanged,
              accountType
            })
          }

          if (mostRecentEddTemplate === null) {
            return (
              <NoEddTemplateFound
                onFileSelected={this.onFileSelected}
                errorMessage={this.state.uploadErrorMessage}
              />
            )
          }

          return (
            <EddTemplateDisplay
              errorMessage={this.state.uploadErrorMessage}
              mappings={JSON.parse(mostRecentEddTemplate.EDDJSONData).mappings}
              onFileSelected={this.onFileSelected}
            />
          )
        }}
      </Mutation>
    )
  }

  private clearError = () => {
    this.setState({ uploadErrorMessage: undefined })
  }

  private onWizardComplete = createEddTemplate => async mappings => {
    for (const { systemField } of mappings) {
      if (!systemField.meta) {
        continue
      }
      delete systemField.meta.__typename
    }
    await createEddTemplate({
      variables: {
        accountId: this.props.accountId,
        mappings,
        resultRow: this.props.hasDefinitionsRow ? 4 : 1
      }
    })

    this.closeWizard()
    this.clearError()
  }

  private onFileSelected = async (_e, { file }) => {
    this.setState({
      loading: true
    })
    const response = await uploadEddTemplate({
      accountId: this.props.accountId,
      file
    })
    if (response.httpCode && response.httpCode === 400) {
      this.setState({
        uploadErrorMessage: response.message,
        loading: false
      })
      return
    }
    this.openWizard(response)
  }

  private openWizard = (uploadData: object): void => {
    this.setState({
      wizardOpen: true,
      uploadData,
      loading: false
    })
  }

  private closeWizard = (): void => {
    this.setState({
      wizardOpen: false
    })
  }

  private init(): void {
    this.state = {
      uploadData: undefined,
      wizardOpen: false,
      loading: false,
      uploadErrorMessage: undefined
    }
  }
}

interface EddUploadViewContainerState {
  uploadData?: object
  wizardOpen: boolean
  loading: boolean
  uploadErrorMessage?: string
}

const getRefetchQueries = ({ accountId, accountType }) => [
  { query: GET_EDD_WIZARD_DATA, variables: { accountId, accountType } }
]
