import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import * as FileSaver from 'file-saver';

import ReactDropzone from 'react-dropzone';
import styled from 'src/theme';
import { useImportChildAccountsMutation } from 'src/services';
import { toastSuccess, toastError } from 'src/components/shared';
import { Dropdown } from '@120wateraudit/envirio-components';
import { APIProvider, AWS_BASE } from 'src/utils/API';
import { FlatButton } from '@120wateraudit/waterworks';
import { useToggle } from 'src/hooks';
import { ChildAccountImports } from './ChildAccountImports';

interface Props {
  open: boolean;
  onClose(): void;
  parentAccountId: number;
}

export const ImportChildAccountsModal = ({
  onClose,
  open,
  parentAccountId
}: Props): JSX.Element => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [importAccounts] = useImportChildAccountsMutation();
  const { enabled: imported, toggle: toggleImported } = useToggle();

  useEffect(() => {
    if (imported) {
      onImport();
    }
  }, [imported]);

  const onFileDropped = ([droppedFile]: File[]) => {
    setFile(droppedFile);
  };

  const onImport = async () => {
    if (file) {
      await importAccounts({
        file,
        parentAccountId
      })
        .unwrap()
        .then(() => toastSuccess('Successfully started account import.'))
        .catch(() =>
          toastError(
            'There was an error importing your accounts, please try again.'
          )
        );
    }
  };

  const onResetForm = () => {
    setFile(undefined);
    toggleImported();
  };

  const closeModal = () => {
    setFile(undefined);
    onClose();
  };

  const onDownloadTemplate = async () => {
    try {
      const results: any = await APIProvider.fetchCSV(
        `${AWS_BASE}/platform/account-management/rest/import/childaccounts/template/download`
      );

      const blob = new Blob([results], {
        type: 'text/csv'
      });

      FileSaver.saveAs(blob, `ChildAccountsImportTemplate.csv`, true);
    } catch (e) {
      toastError('Unable to download template');
    }
  };

  return (
    <Modal closeIcon onClose={closeModal} open={open} size="large">
      <Modal.Header>Import Accounts</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <h6 style={{ margin: 0 }}>
            You can associate many child accounts at once using the provided
            template.
          </h6>
          <div style={{ fontSize: 14 }}>
            - If a child account has already been associated with this account,
            it will be marked as an "updated" record.
          </div>
          <div style={{ fontSize: 14 }}>
            - Only provide <b>ONE OF</b> the ACCOUNT_ID or PWS_ID. If both are
            supplied, ACCOUNT_ID will take precedent over the PWS_ID (which will
            be ignored).
          </div>
        </Modal.Description>
        <Modal.Description
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 10
          }}>
          <FlatButton onClick={onDownloadTemplate}>
            Download Template
          </FlatButton>
        </Modal.Description>
        {!imported && (
          <>
            <ReactDropzone
              onDrop={onFileDropped}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}>
              <UploadInstructions>
                <p>
                  {file?.name
                    ? file.name
                    : `Drag and Drop a file, or click to Browse.`}
                </p>
              </UploadInstructions>
            </ReactDropzone>

            <Modal.Actions style={{ marginTop: 15 }}>
              <Button onClick={closeModal}>Cancel</Button>
              <Button disabled={!file} onClick={toggleImported} positive>
                {'Import'}
              </Button>
            </Modal.Actions>
          </>
        )}
        {imported && (
          <Modal.Description
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 10
            }}>
            <hr />
            <h3>You have successfully launched a child accounts import!</h3>
            <FlatButton onClick={onResetForm}>Reset Import Form</FlatButton>
          </Modal.Description>
        )}
      </Modal.Content>
      <Modal.Description>
        <ChildAccountImports accountId={parentAccountId} />
      </Modal.Description>
    </Modal>
  );
};

const UploadInstructions = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  align-items: center;
  width: 75%;
  text-align: center;
  height: 116px;
  border-radius: 4px;
  border: 2px dashed ${props => props.theme.colors.primary};
  margin-top: 2rem;

  p {
    color: ${props => props.theme.colors.black50};
  }
`;

const ModalDropdown = styled(Dropdown)`
  margin-top: 1rem;
  > .dropdown {
    width: 100%;
    > .icon {
      float: right;
    }
  }
`;
