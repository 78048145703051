import React, { SyntheticEvent, useCallback, useState, useEffect } from 'react';
import { Search, SearchProps } from 'semantic-ui-react';
import { APIProvider } from 'src/utils/API';
import {
  Account,
  AccountType
} from '@120wateraudit/envirio-components/dist/models';
import { Button } from '@120wateraudit/envirio-components';
import styled from 'styled-components';

interface SearchAccountProps {
  userId: number;
  type: number;
  setAccount: (type: number, account: Account | null) => void;
  account: Account | null;
}

const SearchAccount = (props: SearchAccountProps) => {
  const [init, setInit] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<Account[]>([]);
  const [selected, setSelected] = useState<Account | null>(props.account);

  const getResults = async (filter: string): Promise<void> => {
    let url = `/platform/account-management/rest/users/${props.userId}/accounts?pageNumber=0&pageSize=10&type=${props.type}`;
    if (filter) {
      url += `&filter=${filter}`;
    }
    const response = await APIProvider.fetch(url);
    setResults(response.items);
  };

  const onSearchChange = async (
    event: React.MouseEvent,
    data: SearchProps
  ): Promise<void> => {
    const value = data.value || '';
    setSearchTerm(value);
    await getResults(value);
  };

  const onSelectValue = async (
    event: SyntheticEvent,
    data: { result: { id: number; title: string } }
  ) => {
    setSearchTerm(data.result.title);
    const selectedAccount = results.find(a => a.id === data.result.id);
    if (selectedAccount) {
      props.setAccount(props.type, selectedAccount);
    }
  };

  const clearAccount = async () => {
    setSearchTerm('');
    setSelected(null);
    props.setAccount(props.type, null);
    await getResults('');
  };

  useEffect(() => {
    if (!init || props.account) {
      setSearchTerm(
        props.account ? props.account?.id + ' - ' + props.account?.name : ''
      );
      getResults(props.account?.id.toString() || '');
      setInit(true);
    }
  }, [init, props.account]);

  return (
    <SearchWrapper>
      <Search
        fluid={true}
        style={{ display: 'inline-block', width: '80%' }}
        disabled={false}
        icon="caret down"
        input="search"
        loading={false}
        minCharacters={0}
        onFocus={(e: SyntheticEvent) => (e.target as HTMLInputElement).select()}
        onSearchChange={useCallback(onSearchChange, [onSearchChange])}
        results={results.map(result => ({
          id: result.id,
          title: result.id + ' - ' + result.name
        }))}
        value={searchTerm}
        onResultSelect={onSelectValue}
      />
      <Button
        variant="secondary"
        style={{ marginLeft: '20px' }}
        onClick={clearAccount}>
        Clear
      </Button>
    </SearchWrapper>
  );
};

const SearchWrapper = styled.div`
  width: 100%;
  &&& {
    .ui.input {
      width: 100%;
    }
  }
`;

export default SearchAccount;
