import * as React from 'react'
import { MappingStepState, MappingStepProps } from './shared'
import {
  DropdownItemProps,
  Table,
  TableHeader,
  TableBody,
  TableHeaderCell
} from 'semantic-ui-react'
import { MappingEditRow } from './MappingEditRow'

export abstract class BaseMappingStep extends React.Component<
  MappingStepProps
> {
  protected abstract headers: string[]

  constructor(props: MappingStepProps) {
    super(props)
    this.init()
  }

  render() {
    const { systemFields } = this.props

    if (systemFields.length === 0) {
      return null
    }

    return (
      <Table striped>
        <TableHeader>
          <Table.Row>{this.headers.map(this.renderTableHeaderCell)}</Table.Row>
        </TableHeader>
        <TableBody>
          {this.props.mappings.map((mapping, i) => (
            <MappingEditRow
              key={`${mapping.systemField.name}-${i}`}
              mapping={mapping}
              options={this.getOptions()}
              onChange={(_, { value }) => {
                const newMappingsState = this.props.mappings.map(m => {
                  if (m.userFieldName === value) {
                    return {
                      ...m,
                      userFieldName: undefined
                    }
                  }
                  if (m.systemField !== mapping.systemField) {
                    return m
                  }
                  return {
                    ...m,
                    userFieldName: value as string
                  }
                })
                this.props.updateUserFieldData({
                  mappings: newMappingsState
                })
              }}
            />
          ))}
        </TableBody>
      </Table>
    )
  }

  private renderTableHeaderCell(header: string, index: number) {
    return <TableHeaderCell key={header + index}>{header}</TableHeaderCell>
  }

  private getOptions = () => {
    return [
      { text: undefined, value: undefined, key: 'undefined-key' },
      ...this.props.userFields.sort().map(stringToDropdownOption)
    ]
  }

  private init() {
    this.props.getInitialMappings()
  }
}

const stringToDropdownOption = (str: string): DropdownItemProps => ({
  text: str,
  value: str,
  key: str
})
