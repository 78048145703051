import React, { FC } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Form } from 'react-final-form'
import { Lab, LabPartner } from '@120wateraudit/envirio-components/dist/models'
import { accountMgmtClient } from 'src/apolloClient'
import { getStringFromFormattedPhone } from 'src/utils/FormatPhoneNumber'
import { trimmedStringOrNull } from 'src/utils/strings'
import { EditModal } from '../EditModal'
import {
  CreateLabMutationArgs,
  CreateOrUpdateLabMutationResponse,
  CREATE_LAB_MUTATION,
  UpdateLabMutationArgs,
  UPDATE_LAB_MUTATION
} from './data-access'
import LabForm from './LabForm'
import {
  generateInitialLabFormValues,
  getLabPartnerDropdownOptions,
  LabValues
} from './utils'
import { LabPartnerData } from './data-access'

interface Props {
  createEditLabModalIsOpen: boolean
  currentLab?: Lab
  labPartnerData: LabPartnerData
  labPartner?: LabPartner
  reloadGrid: () => void
  toggleCreateEditLabModal: (labPartner?: LabPartner, lab?: Lab) => void
}

export const CreateEditLabModal: FC<Props> = ({
  createEditLabModalIsOpen,
  currentLab,
  labPartnerData,
  labPartner,
  reloadGrid,
  toggleCreateEditLabModal
}: Props) => {
  const [updateLabMutation] = useMutation<
    CreateOrUpdateLabMutationResponse,
    UpdateLabMutationArgs
  >(UPDATE_LAB_MUTATION, {
    client: accountMgmtClient
  })

  const [createLabMutation] = useMutation<
    CreateOrUpdateLabMutationResponse,
    CreateLabMutationArgs
  >(CREATE_LAB_MUTATION, {
    client: accountMgmtClient
  })

  const validateLab = (values: LabValues) => {
    const validated =
      !!values &&
      !!values.name &&
      !!values.addressLine1 &&
      !!values.city &&
      !!values.state &&
      !!values.zip &&
      !!labPartner

    return validated
  }

  const onSubmit = async (values: LabValues) => {
    if (currentLab) {
      await updateLabMutation({
        variables: {
          labId: currentLab.id,
          lab: {
            addressLine1: trimmedStringOrNull(values.addressLine1),
            addressLine2: trimmedStringOrNull(values.addressLine2),
            city: trimmedStringOrNull(values.city),
            state: trimmedStringOrNull(values.state),
            zip: trimmedStringOrNull(values.zip),
            county: trimmedStringOrNull(values.county),
            country: trimmedStringOrNull(values.country),
            name: trimmedStringOrNull(values.name),
            labPartnerId: labPartner?.id || null,
            contactName: trimmedStringOrNull(values.contactName),
            contactPhone: getStringFromFormattedPhone(values.contactPhone),
            contactPhoneExt: trimmedStringOrNull(values.contactPhoneExt),
            contactEmail: trimmedStringOrNull(values.contactEmail)
          }
        }
      })
    } else {
      await createLabMutation({
        variables: {
          lab: {
            addressLine1: values.addressLine1 ? values.addressLine1.trim() : '',
            addressLine2: values.addressLine2 && values.addressLine2.trim(),
            city: values.city && values.city.trim(),
            state: values.state && values.state.trim(),
            zip: values.zip && values.zip.trim(),
            county: values.county && values.county.trim(),
            country: values.country ? values.country.trim() : 'USA',
            name: values.name ? values.name.trim() : '',
            labPartnerId: labPartner?.id || null,
            contactName: values.contactName && values.contactName.trim(),
            contactPhone: values.contactPhone && values.contactPhone.trim(),
            contactPhoneExt:
              values.contactPhoneExt && values.contactPhoneExt.trim(),
            contactEmail: values.contactEmail && values.contactEmail.trim()
          }
        }
      })
    }
    toggleCreateEditLabModal()
    reloadGrid()
  }

  return (
    <Form
      initialValues={generateInitialLabFormValues({
        labPartnerName: labPartner?.name,
        lab: currentLab
      })}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, submitting }) => (
        <EditModal
          isOpen={createEditLabModalIsOpen}
          onSaveClicked={handleSubmit}
          overflowStyle="scroll"
          saveDisabled={!validateLab(values) || submitting}
          toggle={toggleCreateEditLabModal}
          content={
            <>
              {currentLab && <h5>Update Lab</h5>}
              {!currentLab && <h5>Create Lab</h5>}
              <LabForm
                labPartnerDropdownOptions={getLabPartnerDropdownOptions(
                  labPartnerData.items
                )}
                onSubmit={handleSubmit}
              />
            </>
          }
        />
      )}
    />
  )
}
