import {
  Role,
  SchoolDistrict
} from '@120wateraudit/envirio-components/dist/models'
import { ApplicationState } from '../reducers/index'

export const getSchoolDistricts = (state: ApplicationState): SchoolDistrict[] =>
  state.schoolDistricts.items

export const getSchoolDistrictsAsSelectList = (state: ApplicationState) =>
  getSchoolDistricts(state).map(sd => ({
    key: `${sd.id}-${sd.name}`,
    text: sd.name,
    value: sd.id
  }))
