import CrudReducer from 'src/reducers/helpers/IndexCrudReducer'

// Accounts
import AccountActions from 'src/actions/accounts'

export default CrudReducer({
  fetchAction: AccountActions.collectionActions.fetchRequest,
  fetchSuccessAction: AccountActions.collectionActions.fetchSuccess,
  fetchFailureAction: AccountActions.collectionActions.fetchFailure,
  unloadAction: AccountActions.collectionActions.unload,
  collectionName: 'accounts'
})
