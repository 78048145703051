export const isEmptyString = (str?: string): boolean => {
  if (!str) {
    return true;
  }

  if (!str.trim()) {
    return true;
  }

  return false;
};

const ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const SUBDOMAIN_REGEX = /^[a-zA-Z0-9_-]{1,49}$/;

export const isValidZip = (zip: string) => ZIP_REGEX.test(zip);
export const isValidSubdomain = (url: string) => SUBDOMAIN_REGEX.test(url);
