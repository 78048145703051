import React from 'react';
import { Segment } from 'semantic-ui-react';
import { getToken } from 'src/utils/Token';
import { AWS_BASE } from 'src/utils/API';
import { AdminCustomFieldDefinitions } from '@120wateraudit/custom-fields-ui-components';

interface Props {
  id: number;
}

const AccountGroupCustomFields = (props: Props) => {
  return (
    <Segment clearing attached>
      <AdminCustomFieldDefinitions
        id={props.id}
        idType={'accountGroup'}
        accountType={'pws'}
        baseUrl={AWS_BASE}
        token={getToken()}
        showReadOnly={false}
        readOnly={false}
      />
    </Segment>
  );
};

export default AccountGroupCustomFields;
