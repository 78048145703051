import React, { useEffect, useState } from 'react';

// Models
import { User } from 'src/types/AccountManagementTypes';

// Components
import { Button } from '@120wateraudit/waterworks';
import { colors, Loader, Modal } from '@120wateraudit/envirio-components';
import { toastError, toastSuccess } from 'src/components/shared';
import styled from 'styled-components';
import { APIProvider } from 'src/utils/API';

// Custom Hooks
import { useRemoveUserFromAccountMutation } from 'src/services';

interface Props {
  accountId: number;
  accountName: string;
  userToRemove: User | undefined;
  isLoading: boolean;
  deleteLoading: boolean;
  setDeleteLoading: (loadingState: boolean) => void;
  isRemovingUserOpen: boolean;
  setRemoveUserOpen: (isModalOpen: boolean) => void;
}

const RemoveAccountModal = ({
  accountId,
  accountName,
  userToRemove,
  isLoading,
  deleteLoading,
  setDeleteLoading,
  isRemovingUserOpen,
  setRemoveUserOpen
}: Props) => {
  const [removeUser] = useRemoveUserFromAccountMutation();
  const [
    userToDeleteSingleAccountOnly,
    setUserToDeleteSingleAccountOnly
  ] = useState(false);

  useEffect(() => {
    if (isRemovingUserOpen) {
      (async () => {
        if (userToRemove) {
          const userAccounts = await APIProvider.fetch(
            `/platform/account-management/rest/users/${userToRemove.id}/accounts/?page=0&pageSize=2`
          );
          const userAccountsArray = userAccounts.items || [];
          setUserToDeleteSingleAccountOnly(userAccountsArray.length < 2);
        }
      })();
    }
  }, [isRemovingUserOpen]);

  const onSubmitRemoveUser = async () => {
    setDeleteLoading(true);
    if (userToRemove) {
      await removeUser({
        userId: userToRemove.id,
        accountId
      })
        .unwrap()
        .then(() =>
          toastSuccess('Successfully removed user from this account.')
        )
        .catch(() => toastError('Remove user failed, please try again.'));
    }
    setDeleteLoading(false);
    setRemoveUserOpen(false);
  };

  return (
    <Modal
      content={
        isLoading ? (
          <Loader />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <h4>Remove User</h4>
            {deleteLoading ? (
              <Loader />
            ) : (
              <>
                <RemoveHelperText style={{ color: colors.warning }}>
                  {userToRemove &&
                    userToDeleteSingleAccountOnly &&
                    `Warning: This user only has 1 account meaning they will be set to inactive.`}
                </RemoveHelperText>
                <RemoveHelperText>
                  {userToRemove &&
                    `Are you sure you want to remove
                    ${userToRemove.firstName} ${userToRemove.lastName} from ${accountName}'s account?`}
                </RemoveHelperText>
                <div>
                  <div
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                    <Button onClick={() => setRemoveUserOpen(false)}>
                      Cancel
                    </Button>
                    <Button variant="error" onClick={onSubmitRemoveUser}>
                      Remove
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        )
      }
      isOpen={isRemovingUserOpen}
      overflowStyle={'visible'}
      toggle={() => setRemoveUserOpen(false)}
    />
  );
};

const RemoveHelperText = styled.h5`
  margin-top: 0;
  text-align: center;
  color: ${colors.black50};
  word-break: break-word;
`;

export default RemoveAccountModal;
