import React, { FC } from 'react'
import { Container, Form, Icon } from 'semantic-ui-react'
import { FormRenderProps, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import styled from 'styled-components'
import { Button } from '@120wateraudit/envirio-components'
import { Analyte } from '@120wateraudit/envirio-components/dist/models'
import { UnitOfMeasureEntity } from 'src/types/UnitOfMeasureEntity'
import { AnalyteRow } from './AnalyteRow'
import { EddConfigurationValues } from '.'

const ANALYTES_NAME = 'analytes'
const NEW_ANALYTE = {
  analyteId: null,
  labAnalyteId: null,
  unitId: null,
  labUnitId: null
}

interface Props {
  analytes: Analyte[]
  onSubmit: FormRenderProps['handleSubmit']
  units: UnitOfMeasureEntity[]
  values: EddConfigurationValues
}

export const EddConfigurationForm: FC<Props> = ({
  analytes,
  onSubmit,
  units,
  values
}) => {
  const { mutators } = useForm()
  const addAnalyte = () => mutators.push(ANALYTES_NAME, NEW_ANALYTE)

  return (
    <Form onSubmit={onSubmit}>
      <Container>
        <FieldArray name={ANALYTES_NAME}>
          {({ fields }) => (
            <>
              {fields.map((name, index) => (
                <>
                  <AnalyteRow
                    analytes={analytes}
                    fieldName={name}
                    index={index}
                    key={name}
                    units={units}
                    onDelete={() => fields.remove(index)}
                    values={values}
                  />
                </>
              ))}
              <AddButton
                basic
                circular
                disabled={fields.length === analytes.length}
                icon
                onClick={addAnalyte}
                size="small"
                type="button">
                <Icon color="blue" name="plus" />
                <span>Add Analyte</span>
              </AddButton>
              <SubmitButton variant="primary" type="submit">
                Submit Configuration
              </SubmitButton>
            </>
          )}
        </FieldArray>
      </Container>
    </Form>
  )
}

const AddButton = styled(Button)`
  &&& {
    box-shadow: none;
    & > span {
      margin-left: 7px;
      color: #2563eb;
    }
  }
`
const SubmitButton = styled(Button)`
  float: right;
  margin-right: 2rem;
`
