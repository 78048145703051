import CrudReducer from 'src/reducers/helpers/IndexCrudReducer'

// Actions
import DefinitionsActions from 'src/actions/definitions'

export default CrudReducer({
  fetchAction: DefinitionsActions.collectionActions.fetchRequest,
  fetchSuccessAction: DefinitionsActions.collectionActions.fetchSuccess,
  fetchFailureAction: DefinitionsActions.collectionActions.fetchFailure,
  unloadAction: DefinitionsActions.collectionActions.unload,
  createActionRequest: DefinitionsActions.createActions.createRequest,
  createActionSuccess: DefinitionsActions.createActions.createSuccess,
  createActionFailure: DefinitionsActions.createActions.createFailure,
  collectionName: 'definitions'
})
