import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import LoginForm from 'src/components/Login';

const Login = () => {
  const { error } = useAuth0();

  return <LoginForm error={error} />;
};

export default Login;
