import CrudReducer from 'src/reducers/helpers/DetailsCrudReducer'

// Accounts
import AccountActions from 'src/actions/accounts'

export default CrudReducer({
  entityName: 'account',
  fetchAction: AccountActions.detailsActions.fetchRequest,
  fetchSuccessAction: AccountActions.detailsActions.fetchSuccess,
  fetchFailureAction: AccountActions.detailsActions.fetchFailure,
  updateActionRequest: AccountActions.updateActions.updateRequest,
  updateActionSuccess: AccountActions.updateActions.updateSuccess,
  updateActionFailure: AccountActions.updateActions.updateFailure,
  unloadAction: AccountActions.detailsActions.unload,
  deleteAction: AccountActions.deleteActions.deleteRequest,
  deleteSuccessAction: AccountActions.deleteActions.deleteSuccess,
  deleteFailureAction: AccountActions.deleteActions.deleteFailure,
  createActionRequest: AccountActions.createActions.createRequest,
  createActionSuccess: AccountActions.createActions.createSuccess,
  createActionFailure: AccountActions.createActions.createFailure
})
