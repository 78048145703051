import * as React from 'react'
import {
  EddTemplateWizardProps,
  MappingStepState,
  Mapping,
  SavedStepStates,
  DefinedMapping
} from './shared'
import {
  Wizard,
  WizardStep,
  ConfirmModal
} from '@120wateraudit/envirio-components'
import { OptionalMappingStep } from './OptionalMappingStep'
import { RequiredMappingStep } from './RequiredMappingStep'
import { ConfirmMappingStep } from './ConfirmMappingStep'

export class EddTemplateWizard extends React.Component<
  EddTemplateWizardProps,
  EddTemplateWizardState
> {
  private savableMappings: DefinedMapping[] = []

  private stepValidationErrors: StepValidationErrors = {
    required: [],
    optional: []
  }

  constructor(props: EddTemplateWizardProps) {
    super(props)
    this.init()
  }

  render() {
    const { onCompleted, closeWizard } = this.props

    return (
      <>
        <ConfirmModal
          isOpen={this.state.closeWizardConfirmationModalIsShowing}
          onConfirm={closeWizard}
          toggle={() => this.showConfirmationModal(false)}
          header="Are you sure?"
          message="Any Edd Template creation progress will be lost."
        />
        <Wizard
          steps={this.getSteps()}
          onCompleted={() => onCompleted(this.savableMappings)}
          onCancel={this.onWizardCancelClicked}
          onNextClicked={_ => {
            this.setState({
              stepIndex: this.state.stepIndex + 1
            })
            this.savableMappings = formatMappingsForSave(
              this.state.savedStates,
              this.props.uploadData
            )
          }}
          onBackClicked={_ => {
            this.setState({
              stepIndex: this.state.stepIndex - 1
            })
          }}
        />
      </>
    )
  }

  private getSteps(): WizardStep[] {
    const { optionalFields, accountType } = this.props
    if (accountType === 1) {
      if (optionalFields.length > 0) {
        return [
          this.buildRequiredStep(),
          this.buildOptionalStep(),
          this.buildConfirmStep()
        ]
      }
      return [this.buildRequiredStep(), this.buildConfirmStep()]
    }
    if (optionalFields.length > 0) {
      return [
        this.buildRequiredStep(),
        this.buildOptionalStep(),
        this.buildConfirmStep()
      ]
    }
    return [this.buildRequiredStep(), this.buildConfirmStep()]
  }

  private onWizardCancelClicked = (): void => {
    this.showConfirmationModal(true)
  }

  private showConfirmationModal = (shouldShow: boolean): void => {
    this.setState({
      closeWizardConfirmationModalIsShowing: shouldShow
    })
  }

  private buildRequiredStep(): WizardStep {
    return {
      render: this.renderRequiredStep,
      title: 'Required Fields',
      validate: this.validateRequiredStep
    }
  }

  private validateRequiredStep = (): string[] => {
    return this.stepValidationErrors.required
  }

  private buildConfirmStep(): WizardStep {
    return {
      render: this.renderConfirmStep,
      title: 'Confirm'
    }
  }

  private buildOptionalStep(): WizardStep {
    return {
      render: this.renderOptionalStep,
      title: 'Optional Fields'
    }
  }

  private renderConfirmStep = (): JSX.Element => {
    return <ConfirmMappingStep mappings={this.savableMappings} />
  }

  private renderOptionalStep = (): JSX.Element => {
    const { optionalFields } = this.props
    return (
      <OptionalMappingStep
        systemFields={optionalFields}
        userFields={this.state.availableUserFields}
        updateUserFieldData={this.onOptionalStepMappingChanged}
        mappings={this.state.savedStates.optional.mappings}
        getInitialMappings={this.getInitialMappings}
      />
    )
  }

  private renderRequiredStep = (): JSX.Element => {
    const { requiredFields, uploadData } = this.props
    const userFields = Object.values(uploadData)
    return (
      <RequiredMappingStep
        systemFields={requiredFields}
        userFields={userFields}
        updateUserFieldData={this.updateUserFieldData}
        mappings={this.state.savedStates.required.mappings}
        getInitialMappings={this.getInitialMappings}
      />
    )
  }

  private getInitialMappings = () => {
    const isRequiredStep = this.state.stepIndex === 0

    const systemFields = isRequiredStep
      ? this.props.requiredFields
      : this.props.optionalFields

    const targetState = isRequiredStep
      ? this.state.savedStates.required
      : this.state.savedStates.optional

    if (!targetState.mappings.length) {
      const { uploadData } = this.props
      const userFields = Object.values(uploadData)

      const initialMappings = systemFields.sort().map(field => {
        const systemFieldName = field.name
        const userField =
          userFields.find(
            f =>
              f
                .trim()
                .toLowerCase()
                .replace(/_|-/g, ' ') ===
              systemFieldName
                .trim()
                .toLowerCase()
                .replace(/_|-/g, ' ')
          ) || undefined
        return {
          systemField: field,
          userFieldName: userField
        }
      })

      if (isRequiredStep) {
        this.setState({
          savedStates: {
            ...this.state.savedStates,
            required: {
              mappings: initialMappings
            }
          }
        })
      } else {
        const mappedFields = this.state.savedStates.required.mappings.filter(
          m => m.userFieldName !== undefined
        )
        const availableUserFields = this.getUnusedUserFields(mappedFields)
        this.setState({
          savedStates: {
            ...this.state.savedStates,
            optional: {
              mappings: initialMappings
            }
          },
          availableUserFields
        })
      }
    }
  }

  private updateUserFieldData = (stepState: MappingStepState) => {
    const mappedFields = stepState.mappings.filter(
      m => m.userFieldName !== undefined
    )

    emptyArray(this.stepValidationErrors.required)

    if (mappedFields.length !== stepState.mappings.length) {
      this.stepValidationErrors.required.push(
        'All Required Fields Must be Mapped to a User Defined Value.'
      )
    }

    this.setState({
      savedStates: {
        ...this.state.savedStates,
        required: stepState
      }
    })
  }

  private getUnusedUserFields = (mappedFields: Mapping[]): string[] => {
    return Object.values(this.props.uploadData).filter(
      uf =>
        mappedFields.map(({ userFieldName }) => userFieldName).indexOf(uf) ===
        -1
    )
  }

  private onOptionalStepMappingChanged = (
    stepState: MappingStepState
  ): void => {
    this.setState({
      savedStates: {
        ...this.state.savedStates,
        optional: stepState
      }
    })
  }

  private init(): void {
    this.state = {
      closeWizardConfirmationModalIsShowing: false,
      savedStates: {
        required: {
          mappings: []
        },
        optional: {
          mappings: []
        }
      },
      availableUserFields: [],
      stepIndex: 0
    }
  }
}

interface EddTemplateWizardState {
  closeWizardConfirmationModalIsShowing: boolean
  savedStates: SavedStepStates
  availableUserFields: string[]
  stepIndex: number
}

interface StepValidationErrors {
  required: string[]
  optional: string[]
}

const emptyArray = (arr: any[]): void => {
  for (const _ of arr) {
    arr.pop()
  }
}

export const formatMappingsForSave = (
  { required, optional }: SavedStepStates,
  uploadData: any
): DefinedMapping[] => {
  const requiredMappings: DefinedMapping[] = required.mappings.map(m => ({
    systemField: {
      name: m.systemField.name,
      meta: {
        isRequired: true,
        ...(m.systemField.meta ? m.systemField.meta : {})
      }
    },
    userField: {
      name: m.userFieldName as string
    }
  }))

  const optionalMappings: DefinedMapping[] = optional.mappings
    .filter(m => m.userFieldName)
    .map(m => ({
      systemField: {
        name: m.systemField.name,
        meta: {
          isRequired: false,
          ...(m.systemField.meta ? m.systemField.meta : {})
        }
      },
      userField: {
        name: m.userFieldName as string
      }
    }))

  const merged = [...requiredMappings, ...optionalMappings]

  return Object.values(uploadData)
    .map((v: string) => merged.find(m => m.userField.name === v))
    .filter(Boolean) as DefinedMapping[]
}
