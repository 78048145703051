import { TextField } from '@120wateraudit/envirio-components'
import React from 'react'
import { TableCell } from 'semantic-ui-react'
import { AccountAnalyte, AccountAnalyteWithRelations } from '../index'

interface Props {
  accountAnalyte: AccountAnalyteWithRelations
  accountAnalyteToUpdate: AccountAnalyte
  isEditing: boolean
  setAccountAnalyteToUpdate(value: AccountAnalyte): void
}

export const ExceedanceLimitCell = ({
  accountAnalyte,
  accountAnalyteToUpdate,
  isEditing,
  setAccountAnalyteToUpdate
}: Props) => {
  return (
    <TableCell>
      {!isEditing && (accountAnalyte.exceedanceLimit || '--')}
      {isEditing && (
        <TextField
          onChange={e => {
            setAccountAnalyteToUpdate({
              ...accountAnalyteToUpdate,
              exceedanceLimit: Number(e.target.value)
            })
          }}
          type={'number'}
          value={
            `${accountAnalyteToUpdate.exceedanceLimit}` ||
            `${accountAnalyte.exceedanceLimit}`
          }
        />
      )}
    </TableCell>
  )
}
