import React, { useMemo } from 'react';
import {
  Breadcrumb,
  Button,
  Dropdown,
  Form,
  Header,
  Input,
  Segment,
  DropdownItemProps,
  Card,
  Placeholder
} from 'semantic-ui-react';
import SettingsForm from 'src/components/Forms/AccountSettings';

// Models
import {
  Account,
  AccountType,
  Setting
} from '@120wateraudit/envirio-components/dist/models';
import {
  Datepicker,
  Button as EnvirioButton
} from '@120wateraudit/envirio-components';
import { useEffect, useState } from 'react';

import { Field, Form as FinalForm } from 'react-final-form';
import { DropdownWithSelectedItems } from '../Dropdown/DropdownWithSelectedItems';
import { toastError, toastSuccess } from '../shared';
import {
  useGetAvailableCounties,
  useGetCountiesServed,
  useUpdateCountiesServed
} from 'src/containers/Accounts/data-access';
import styled from 'src/theme';
import { stateOptions } from 'src/utils/SelectList';

interface Props {
  account: Account & { primacyCode: string };
  accountId: number;
  accountType: AccountType;
  gridRef: any;
  isCreating: boolean;
  isEditing: boolean;
  isModalOpen: boolean;
  isSaving: boolean;
  onClickAdd: (event: any, data: any) => any;
  onClickDelete: () => any;
  onClickEdit: (e: Setting) => any;
  onClickUpdate: () => any;
  onBlurCreateSettingText: any;
  onBlurEditSettingText: any;
  onInputChanged: any;
  onSelectListChanged: (event: any, data: any) => any;
  onSettingValueChanged: (e: any) => any;
  selectedSetting?: any;
  settingDefinitionOptions: any;
  settings: Setting[];
  targetSetting?: Setting;
  toggleCreating: () => any;
  toggleEditing: () => any;
  toggleModal: () => any;
  templateOptions: DropdownItemProps[];
  timezoneOptions: DropdownItemProps[];
  onTemplateCreateChanged: (e: any, data: any) => any;
  onTemplateEditChanged: (e: any, data: any) => any;
  onDropdownCreateSettingChanged: (e: any, data: any) => any;
  onDropdownEditSettingChanged: (e: any, data: any) => any;
  onLeadBanDateCreate?: (date: Date) => any;
  onLeadBanDateEdit?: (date: Date) => any;
  sendGridHtml: string;
  isLoading: boolean;
  selectedDefinitionName: string;
  selectedDefinitionPlaceholder: string;
  newSettingValue: any;
}

const Settings = ({
  account,
  gridRef,
  isCreating,
  isEditing,
  isModalOpen,
  isSaving,
  onClickAdd,
  onClickDelete,
  onClickEdit,
  onClickUpdate,
  onBlurCreateSettingText,
  onBlurEditSettingText,
  onInputChanged,
  onSelectListChanged,
  onSettingValueChanged,
  selectedSetting,
  settingDefinitionOptions,
  settings,
  targetSetting,
  toggleEditing,
  toggleModal,
  templateOptions,
  timezoneOptions,
  onLeadBanDateCreate,
  onLeadBanDateEdit,
  onTemplateCreateChanged,
  onTemplateEditChanged,
  onDropdownCreateSettingChanged,
  onDropdownEditSettingChanged,
  sendGridHtml,
  isLoading,
  selectedDefinitionName,
  selectedDefinitionPlaceholder,
  newSettingValue
}: Props) => {
  const { id, primacyCode } = account;
  const [leadDate, setLeadDate] = useState<Date | undefined>();
  const { updateCountiesServed } = useUpdateCountiesServed(id);
  const { countiesServed } = useGetCountiesServed(id);
  const { availableCounties } = useGetAvailableCounties(id);

  const mappedAvailableCounties = availableCounties.map(
    ({ shortName, countyFIPS }) => {
      const textName = `${shortName} (${countyFIPS})`;
      return {
        text: textName,
        value: textName
      };
    }
  );

  const mappedCountiesServed = countiesServed.map(
    ({ shortName, countyFIPS }) => {
      const textName = `${shortName} (${countyFIPS})`;
      return {
        text: textName,
        value: textName
      };
    }
  );

  const hasCountiesEnabled = primacyCode && mappedAvailableCounties.length > 0;

  useEffect(() => {
    if (
      targetSetting &&
      targetSetting.value &&
      selectedDefinitionName === 'Lead Ban Date'
    ) {
      setLeadDate(new Date(targetSetting.value));
    }
  }, [targetSetting]);

  const onSubmit = async data => {
    try {
      await updateCountiesServed(getCountiesServedCodes(data.countiesServed));

      toastSuccess('Counties updated');
    } catch {
      toastError('Error saving counties');
    }
  };

  const getCountiesServedCodes = (counties: string[]) =>
    counties.map(county => {
      const match = county.match(/\((.*?)\)/);
      return match ? match[1] : '';
    });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Segment clearing attached>
        <Breadcrumb size="huge">
          <Breadcrumb.Section active>
            Manage {account.name}'s Settings
          </Breadcrumb.Section>
        </Breadcrumb>
      </Segment>
      {settingDefinitionOptions.length > 0
        ? [
            <Segment attached clearing key="1plus-header-seg">
              <Header>
                <Header.Subheader textalign="center">
                  <b>
                    You have {settingDefinitionOptions.length} setting(s) left.{' '}
                  </b>{' '}
                  Add more using the dropdown below.
                </Header.Subheader>
              </Header>
            </Segment>,
            <Segment attached clearing key="1plus-form-seg">
              <form key="newSettingsKey">
                <div style={{ display: 'flex' }}>
                  <Dropdown
                    placeholder="Select a Setting"
                    fluid
                    selection
                    options={settingDefinitionOptions}
                    onChange={onSelectListChanged}
                    value={selectedSetting}
                    style={{ marginRight: 15 }}
                  />
                  {isCreating && [
                    <Form.Field
                      key="addSettingValue"
                      style={{ 'min-width': '350px' }}>
                      {selectedDefinitionName === 'SendGrid Email Template'
                        ? [
                            <Dropdown
                              key="email-template-dropdown"
                              placeholder="Select an Email Template"
                              fluid
                              selection
                              options={templateOptions}
                              onChange={onTemplateCreateChanged}
                              value={newSettingValue}
                            />
                          ]
                        : selectedDefinitionName === 'Timezone'
                        ? [
                            <Dropdown
                              key="timezone-template-dropdown"
                              placeholder="Select a Timezone"
                              fluid
                              selection
                              options={timezoneOptions}
                              onChange={onDropdownCreateSettingChanged}
                              value={newSettingValue}
                            />
                          ]
                        : selectedDefinitionName === 'Lead Ban Date'
                        ? [
                            <Datepicker
                              style={{ width: '300px' }}
                              key="lead-ban-dropdown"
                              onChange={onLeadBanDateCreate}
                              value={leadDate}
                            />
                          ]
                        : selectedDefinitionName === 'ReturnAddressState'
                        ? [
                            <Dropdown
                              key="return-address-state-dropdown"
                              placeholder="Select a State"
                              fluid
                              selection
                              options={stateOptions}
                              onChange={onDropdownCreateSettingChanged}
                              value={newSettingValue}
                            />
                          ]
                        : [
                            <Input
                              key="settings-input"
                              name="value"
                              placeholder={
                                selectedDefinitionPlaceholder || 'Add Value'
                              }
                              onBlur={onBlurCreateSettingText}
                              onChange={onInputChanged}
                              value={newSettingValue}
                            />
                          ]}
                    </Form.Field>,
                    <Button
                      key="addSettingConfirm"
                      primary
                      onClick={onClickAdd}>
                      {isSaving ? 'Adding...' : 'Add'}
                    </Button>
                  ]}
                </div>
              </form>
              {selectedDefinitionName === 'SendGrid Email Template' &&
                isCreating === true && [
                  <Card key="email-template-preview" fluid>
                    {isLoading ? (
                      <Placeholder fluid>
                        <Placeholder.Image rectangle />
                      </Placeholder>
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: sendGridHtml }} />
                    )}
                  </Card>
                ]}
              <SettingsForm
                account={account}
                gridRef={gridRef}
                isCreating={isCreating}
                isEditing={isEditing}
                isModalOpen={isModalOpen}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
                onClickUpdate={onClickUpdate}
                onBlurEditSettingText={onBlurEditSettingText}
                onSettingValueChanged={onSettingValueChanged}
                settings={settings}
                targetSetting={targetSetting}
                toggleEditing={toggleEditing}
                toggleModal={toggleModal}
                templateOptions={templateOptions}
                onTemplateChanged={onTemplateEditChanged}
                timezoneOptions={timezoneOptions}
                onDropdownChanged={onDropdownEditSettingChanged}
                onLeadBanDateChanged={onLeadBanDateEdit}
              />
              {hasCountiesEnabled && (
                <Segment>
                  <FinalForm
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <CountyContainer>
                          <Form.Field required>
                            <Field<string>
                              component={DropdownWithSelectedItems}
                              label="Counties Served"
                              name="countiesServed"
                              options={mappedAvailableCounties}
                              current={mappedCountiesServed}
                            />
                          </Form.Field>
                          <EnvirioButton
                            variant="primary"
                            style={{ height: '100%' }}>
                            Update
                          </EnvirioButton>
                        </CountyContainer>
                      </Form>
                    )}
                  />
                </Segment>
              )}
            </Segment>
          ]
        : [
            <Segment attached clearing key="0-header-seg">
              <Header>
                <Header.Subheader textalign="center">
                  All available settings have been configured for this account.
                </Header.Subheader>
              </Header>
            </Segment>,
            <Segment attached clearing key="0-form-seg">
              <SettingsForm
                account={account}
                gridRef={gridRef}
                isCreating={isCreating}
                isEditing={isEditing}
                isModalOpen={isModalOpen}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
                onClickUpdate={onClickUpdate}
                onBlurEditSettingText={onBlurEditSettingText}
                onSettingValueChanged={onSettingValueChanged}
                settings={settings}
                targetSetting={targetSetting}
                toggleEditing={toggleEditing}
                toggleModal={toggleModal}
                templateOptions={templateOptions}
                onTemplateChanged={onTemplateEditChanged}
                onDropdownChanged={onDropdownEditSettingChanged}
                timezoneOptions={timezoneOptions}
                onLeadBanDateChanged={onLeadBanDateEdit}
              />
              {hasCountiesEnabled && (
                <Segment>
                  <FinalForm
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <CountyContainer>
                          <Form.Field required>
                            <Field<string>
                              component={DropdownWithSelectedItems}
                              label="Counties Served"
                              name="countiesServed"
                              options={mappedAvailableCounties}
                              current={mappedCountiesServed}
                            />
                          </Form.Field>
                          <EnvirioButton
                            variant="primary"
                            style={{ height: '100%' }}>
                            Update
                          </EnvirioButton>
                        </CountyContainer>
                      </Form>
                    )}
                  />
                </Segment>
              )}
            </Segment>
          ]}
    </div>
  );
};

export default Settings;

const CountyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    opacity: unset;
  }
  div {
    margin-right: 10px;
  }
`;
