import {
  AccountType,
  UserType
} from '@120wateraudit/envirio-components/dist/models';
import { sortBy } from 'lodash';
import { SampleStatus } from 'src/types/Sample';

export interface ReactSelectOption {
  key: string;
  text: string;
  value: any;
}

type ExtendedReactSelectOption<T, R> = Omit<T, keyof R> & R;

export const convertStringEnumToDropdownOptions = (o: object) =>
  Object.keys(o).map((key, index) => ({
    key: key + '-' + index,
    text: key,
    value: o[key]
  }));

export const convertEnumToListItems = (
  enumToConvert: any,
  alphabetical?: boolean
): ReactSelectOption[] => {
  let options: any = [];

  for (const e in enumToConvert) {
    if (enumToConvert.hasOwnProperty(e)) {
      const isValueProperty = parseInt(e, 10) >= 0;
      if (isValueProperty) {
        options.push({
          key: e,
          text: enumToConvert[e],
          value: e
        });
      }
    }
  }

  if (alphabetical) {
    options = sortBy(options, 'label');
  }

  return options;
};

export const accountTypeOptions: Array<ExtendedReactSelectOption<
  ReactSelectOption,
  { key: AccountType }
>> = [
  {
    key: AccountType.Schools,
    text: 'Schools',
    value: AccountType.Schools
  },
  {
    key: AccountType.PWS,
    text: 'PWS',
    value: AccountType.PWS
  }
];

export const timezoneOptions: ReactSelectOption[] = [
  { key: 'Eastern', text: 'Eastern', value: 'Eastern' },
  { key: 'Central', text: 'Central', value: 'Central' },
  { key: 'Mountain', text: 'Mountain', value: 'Mountain' },
  { key: 'Pacific', text: 'Pacific', value: 'Pacific' },
  { key: 'Alaska', text: 'Alaska', value: 'Alaska' },
  { key: 'Hawaii', text: 'Hawaii', value: 'Hawaii' },
  { key: 'PuertoRico', text: 'Puerto Rico', value: 'PuertoRico' }
];

export const pwsTypeOptions: ReactSelectOption[] = [
  { key: 'CWS', text: 'Community Water Systems', value: 'CWS' },
  {
    key: 'NTNCWS',
    text: 'Non-Transient Non-Community Water Systems',
    value: 'NTNCWS'
  },
  {
    key: 'TNCWS',
    text: 'Transient Non-Community Water Systems',
    value: 'TNCWS'
  }
];

export const ownerOptions: ReactSelectOption[] = [
  { key: 'F', text: 'F (Federal Government)', value: 'F' },
  { key: 'L', text: 'L (Local Government)', value: 'L' },
  { key: 'M', text: 'M (Public/Private)', value: 'M' },
  { key: 'N', text: 'N (Native American)', value: 'N' },
  { key: 'P', text: 'P (Private)', value: 'P' },
  { key: 'S', text: 'S (State Government)', value: 'S' }
];

export const userTypeOptions: Array<ExtendedReactSelectOption<
  ReactSelectOption,
  { key: UserType }
>> = [
  {
    key: UserType.FieldUser,
    text: 'Field User',
    value: UserType.FieldUser
  },
  {
    key: UserType.FieldAdmin,
    text: 'Field Admin',
    value: UserType.FieldAdmin
  },
  {
    key: UserType.FacilityUser,
    text: 'Facility User',
    value: UserType.FacilityUser
  },
  {
    key: UserType.DistrictUser,
    text: 'District User',
    value: UserType.DistrictUser
  },
  {
    key: UserType.AccountUser,
    text: 'Account User',
    value: UserType.AccountUser
  },
  {
    key: UserType.AccountAdmin,
    text: 'Account Admin',
    value: UserType.AccountAdmin
  },
  {
    key: UserType.SystemAdmin,
    text: 'System Admin',
    value: UserType.SystemAdmin
  }
];

export const booleanOptions: Array<ExtendedReactSelectOption<
  ReactSelectOption,
  { key: number }
>> = [
  {
    key: 0,
    text: 'No',
    value: false
  },
  {
    key: 1,
    text: 'Yes',
    value: true
  }
];

export const stateOptions: Array<ExtendedReactSelectOption<
  ReactSelectOption,
  { key: number }
>> = [
  { key: 0, text: 'ALABAMA', value: 'AL' },
  { key: 1, text: 'ALASKA', value: 'AK' },
  { key: 2, text: 'ARIZONA', value: 'AZ' },
  { key: 3, text: 'ARKANSAS', value: 'AR' },
  { key: 4, text: 'CALIFORNIA', value: 'CA' },
  { key: 5, text: 'COLORADO', value: 'CO' },
  { key: 6, text: 'CONNECTICUT', value: 'CT' },
  { key: 7, text: 'DELAWARE', value: 'DE' },
  { key: 8, text: 'DISTRICT OF COLUMBIA', value: 'DC' },
  { key: 9, text: 'FLORIDA', value: 'FL' },
  { key: 10, text: 'GEORGIA', value: 'GA' },
  { key: 11, text: 'HAWAII', value: 'HI' },
  { key: 12, text: 'IDAHO', value: 'ID' },
  { key: 13, text: 'ILLINOIS', value: 'IL' },
  { key: 14, text: 'INDIANA', value: 'IN' },
  { key: 15, text: 'IOWA', value: 'IA' },
  { key: 16, text: 'KANSAS', value: 'KS' },
  { key: 17, text: 'KENTUCKY', value: 'KY' },
  { key: 18, text: 'LOUISIANA', value: 'LA' },
  { key: 19, text: 'MAINE', value: 'ME' },
  { key: 20, text: 'MARYLAND', value: 'MD' },
  { key: 21, text: 'MASSACHUSETTS', value: 'MA' },
  { key: 22, text: 'MICHIGAN', value: 'MI' },
  { key: 23, text: 'MINNESOTA', value: 'MN' },
  { key: 24, text: 'MISSISSIPPI', value: 'MS' },
  { key: 25, text: 'MISSOURI', value: 'MO' },
  { key: 26, text: 'MONTANA', value: 'MT' },
  { key: 27, text: 'NEBRASKA', value: 'NE' },
  { key: 28, text: 'NEVADA', value: 'NV' },
  { key: 29, text: 'NEW HAMPSHIRE', value: 'NH' },
  { key: 30, text: 'NEW JERSEY', value: 'NJ' },
  { key: 31, text: 'NEW MEXICO', value: 'NM' },
  { key: 32, text: 'NEW YORK', value: 'NY' },
  { key: 33, text: 'NORTH CAROLINA', value: 'NC' },
  { key: 34, text: 'NORTH DAKOTA', value: 'ND' },
  { key: 35, text: 'OHIO', value: 'OH' },
  { key: 36, text: 'OKLAHOMA', value: 'OK' },
  { key: 37, text: 'OREGON', value: 'OR' },
  { key: 38, text: 'PENNSYLVANIA', value: 'PA' },
  { key: 39, text: 'RHODE ISLAND', value: 'RI' },
  { key: 40, text: 'SOUTH CAROLINA', value: 'SC' },
  { key: 41, text: 'SOUTH DAKOTA', value: 'SD' },
  { key: 42, text: 'TENNESSEE', value: 'TN' },
  { key: 43, text: 'TEXAS', value: 'TX' },
  { key: 44, text: 'UTAH', value: 'UT' },
  { key: 45, text: 'VERMONT', value: 'VT' },
  { key: 46, text: 'VIRGINIA', value: 'VA' },
  { key: 47, text: 'WASHINGTON', value: 'WA' },
  { key: 48, text: 'WEST VIRGINIA', value: 'WV' },
  { key: 49, text: 'WISCONSIN', value: 'WI' },
  { key: 50, text: 'WYOMING', value: 'WY' }
];

export const sampleStatusListOptions = Object.keys(SampleStatus).map(key => ({
  id: key,
  label: key,
  value: key
}));
