import React from 'react';
import { Container } from 'semantic-ui-react';
import EDDResultsTable from 'src/components/EDDResults';

const EDDResultsContainer = () => {
  return (
    <Container>
      <EDDResultsTable />
    </Container>
  );
};

export default EDDResultsContainer;
