import { Button, Dropdown } from '@120wateraudit/envirio-components';
import * as React from 'react';
import { useState } from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { Props } from 'src/containers/AccountSoftwarePackages';
import {
  useAvailableFeatures,
  useCreateAccountFeatureMutation
} from 'src/hooks/useFeatures';
import styled from 'src/theme';
import { Feature } from 'src/types/Feature';

export const AddFeature = ({ accountId }: Props) => {
  const [selectedFeature, setSelectedFeature] = useState<number | undefined>(
    undefined
  );
  const { availableFeatures } = useAvailableFeatures(accountId);

  const [createAccountFeature] = useCreateAccountFeatureMutation();

  const onSelectedFeatureChanged = (
    event: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    if (typeof value === 'number') {
      setSelectedFeature(value);
    }
  };

  const onAddFeatureClicked = async () => {
    if (selectedFeature) {
      await createAccountFeature({
        variables: {
          accountId,
          featureId: selectedFeature
        },
        refetchQueries: ['features', 'availableFeatures']
      });
      setSelectedFeature(undefined);
    }
  };

  return (
    <UpdateFeaturesWrapper>
      <ModalDropdown
        label="Add Feature"
        options={getFeaturesDropdown(availableFeatures)}
        placeholder={'Select Individual Feature'}
        onChange={onSelectedFeatureChanged}
        fluid
        optionTextMaxLength={45}
        search
      />
      <Button variant="primary" onClick={onAddFeatureClicked}>
        ADD FEATURE
      </Button>
    </UpdateFeaturesWrapper>
  );
};

export const getFeaturesDropdown = (
  features: Feature[] | undefined
): DropdownProps['options'] => {
  return features?.map(f => ({
    text: f.name,
    value: f.id
  }));
};

const ModalDropdown = styled(Dropdown)`
  margin-bottom: 1rem;
`;

const UpdateFeaturesWrapper = styled.div`
   {
    width: 50%;
    padding: 0 15px;
  }
`;
