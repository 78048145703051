import * as React from 'react'
import { connect } from 'react-redux'
import { toast, ToastOptions } from 'react-toastify'

// Actions
import {
  resendInviteRequest,
  revokeInviteRequest
} from 'src/actions/userInvite'

// Utils
import { pushRoute } from 'src/utils/Navigation'

// Components
import AccountUserInvites from 'src/components/AccountUserInvites'

interface Props {
  accountId: number
  resendInvite: ({
    inviteId,
    callback
  }: {
    inviteId: number
    callback: any
  }) => void
  revokeInvite: ({
    inviteId,
    callback
  }: {
    inviteId: number
    callback: any
  }) => void
}

class AccountsContainer extends React.Component<Props, any> {
  // Reference to the child grid component
  // This is used to refresh the grid after revoke/resend actions
  inviteGrid: any

  initInviteGrid = (gridEl: any) => {
    this.inviteGrid = gridEl
  }

  onInviteClicked = () => {
    const { accountId } = this.props
    pushRoute(`/accounts/${accountId}/user/invite`)
  }

  reloadGrid = () => {
    this.inviteGrid.fetchData()
  }

  onRevokeClicked = (inviteId: number) => {
    if (typeof inviteId === 'number') {
      const callback = this.reloadGrid

      this.props.revokeInvite({
        callback,
        inviteId
      })
    }
  }

  onResendClicked = (inviteId: number) => {
    if (typeof inviteId === 'number') {
      const callback = this.reloadGrid

      this.props.resendInvite({
        callback,
        inviteId
      })
    }
  }

  onInviteUrlCopy = (inviteUrl: string) => {
    const hiddenField = document.createElement('textarea')
    hiddenField.innerText = inviteUrl
    document.body.appendChild(hiddenField)
    hiddenField.select()
    document.execCommand('copy')
    hiddenField.remove()

    const defaultToastOpts: ToastOptions = {
      position: 'top-center',
      autoClose: 2500,
      hideProgressBar: true
    }

    toast('Invitation url has been copied to clipboard.', {
      ...defaultToastOpts,
      type: 'success'
    })
  }

  render() {
    const { accountId } = this.props

    return (
      <AccountUserInvites
        accountId={accountId}
        gridRef={this.initInviteGrid}
        onInviteClicked={this.onInviteClicked}
        onResendClicked={this.onResendClicked}
        onRevokeClicked={this.onRevokeClicked}
        onInviteUrlCopy={this.onInviteUrlCopy}
      />
    )
  }
}

const mapDispatchToProps = {
  resendInvite: resendInviteRequest,
  revokeInvite: revokeInviteRequest
}

export default connect<Props, any, any>(
  null,
  mapDispatchToProps
)(AccountsContainer)
