import { Button, Dropdown } from '@120wateraudit/envirio-components'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { DropdownProps } from 'semantic-ui-react'
import { Props } from 'src/containers/AccountSoftwarePackages'
import {
  useAllPackages,
  usePackageForAccount,
  useUpdateAccountPackageMutation
} from 'src/hooks/usePackages'
import styled from 'src/theme'
import { SoftwarePackage } from 'src/types/SoftwarePackage'

export const UpdatePackage = ({ accountId, accountType }: Props) => {
  const [selectedPackage, setSelectedPackage] = useState<number | undefined>(
    undefined
  )
  const { packages: allPackages } = useAllPackages(accountType)
  const { accountPackage } = usePackageForAccount(accountId)

  const [updateAccountSoftwarePackage] = useUpdateAccountPackageMutation()

  const onSelectedSoftwarePackageChanged = (
    event: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    if (typeof value === 'number') {
      setSelectedPackage(value)
    }
  }

  const onAddSoftwarePackageClicked = async () => {
    if (selectedPackage) {
      await updateAccountSoftwarePackage({
        variables: {
          accountId,
          softwarePackageId: selectedPackage
        },
        refetchQueries: [
          'accountSoftwarePackage',
          'features',
          'availableFeatures'
        ]
      })
      setSelectedPackage(undefined)
    }
  }

  useEffect(() => {
    if (accountPackage) {
      setSelectedPackage(accountPackage.packageId)
    }
  }, [accountPackage])

  return (
    <UpdateFeaturesWrapper>
      <ModalDropdown
        label="Current Software Package"
        placeholder={'No Package Selected'}
        options={transformSoftwarePackagesToDropdownOptions(allPackages)}
        value={selectedPackage}
        onChange={onSelectedSoftwarePackageChanged}
        fluid
        optionTextMaxLength={45}
        search
      />
      <Button variant="primary" onClick={onAddSoftwarePackageClicked}>
        UPDATE PACKAGE
      </Button>
    </UpdateFeaturesWrapper>
  )
}

export const transformSoftwarePackagesToDropdownOptions = (
  softwarePackages: SoftwarePackage[] | undefined
): DropdownProps['options'] => {
  return softwarePackages?.map(sp => ({
    text: sp.name,
    value: sp.id
  }))
}

const ModalDropdown = styled(Dropdown)`
  margin-bottom: 1rem;
`

const UpdateFeaturesWrapper = styled.div`
   {
    width: 50%;
    padding: 0 15px;
  }
`
