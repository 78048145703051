import React from 'react';
import { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { DropdownItemProps } from 'semantic-ui-react';
import { Dropdown } from '@120wateraudit/envirio-components';

interface DropdownWithSelectedItemsProps
  extends FieldRenderProps<string, HTMLElement> {
  options: DropdownItemProps[];
}

export const DropdownWithSelectedItems: React.FC<DropdownWithSelectedItemsProps> = ({
  current,
  options,
  input,
  ...rest
}) => {
  useEffect(() => {
    setCurrentValues(current?.map((v: any) => v.text) || []);
  }, [current]);

  const [currentValues, setCurrentValues] = useState<string[]>(current);

  const handleChange = (_, { value }: any) => {
    setCurrentValues(value);
    input.onChange(value);
  };

  return (
    <Dropdown
      {...input}
      {...rest}
      options={options}
      onChange={handleChange}
      value={currentValues}
      multiple
    />
  );
};
