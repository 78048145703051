import {
  Dropdown as EnvirioDropdown,
  colors
} from '@120wateraudit/envirio-components'
import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { DropdownProps } from 'semantic-ui-react'
import styled from 'styled-components'

export type Props = FieldRenderProps<string, HTMLElement>

const Dropdown: FC<Props> = ({ input, meta: { error, touched }, ...rest }) => {
  const onChange: DropdownProps['onChange'] = (_event, { value }) => {
    input.onChange(value)
    input.onBlur()
  }
  return (
    <DropdownWithErrors
      {...input}
      error={touched && !!error}
      onChange={onChange}
      {...rest}
    />
  )
}

const getBorderColor = props => (props.error ? colors.error : 'inherit')

const DropdownWithErrors = styled(EnvirioDropdown)`
  .error {
    border-color: ${getBorderColor} !important;
  }
`

export default Dropdown
