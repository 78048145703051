import './styles.css';

import {
  Account,
  AccountType,
  School,
  SchoolDistrict,
  User
} from '@120wateraudit/envirio-components/dist/models';
import * as React from 'react';
import { Divider, Form, Select } from 'semantic-ui-react';
import { RoleOptionToggle } from 'src/containers/AccountUsers/invite';
import { Auth0Role } from 'src/types/Auth0Role';

interface Props {
  account: Account;
  user: Partial<User> & {
    applicationRoles: { [key in Auth0Role['_id']]: boolean };
    schoolDistricts: SchoolDistrict[];
    schools: School[];
    roleOptionToggle: RoleOptionToggle;
    useSSO: boolean;
  };
  onInputChanged: any;
  onCheckboxChanged: any;
  ssoCheckboxChanged: () => void;
  onSelectListChanged: any;
  roleOptions: any;
  schoolDistrictOptions: any;
  schoolOptions: any;
  onRoleTypeChanged: any;
  onSchoolDistrictsChanged: any;
  onSchoolsChanged: any;
  applicationRoles: Auth0Role[];
}

const UserInvite = ({
  account,
  user,
  roleOptions,
  schoolDistrictOptions,
  schoolOptions,
  onCheckboxChanged,
  ssoCheckboxChanged,
  onInputChanged,
  onRoleTypeChanged,
  onSchoolDistrictsChanged,
  onSchoolsChanged,
  applicationRoles
}: Props) => (
  <Form>
    <Form.Group>
      <Divider section />
      <Form.Input
        label="Email Address"
        width="4"
        name="email"
        onChange={onInputChanged}
        value={user.email || ''}
      />
    </Form.Group>
    <Form.Group>
      <Form.Input
        search
        control={Select}
        multiple
        label="Roles"
        width="4"
        value={user.roles ? user.roles.map(r => r.id) : undefined}
        options={roleOptions}
        onChange={(e, data) => {
          onRoleTypeChanged(data, 'roles');
        }}
      />
    </Form.Group>
    {account.accountType === AccountType.Schools && (
      <>
        <Form.Group>
          <Form.Input
            search
            control={Select}
            multiple
            label="School Districts"
            width="4"
            value={
              user.schoolDistricts
                ? user.schoolDistricts.map(district => district.id)
                : undefined
            }
            options={schoolDistrictOptions}
            onChange={(e, data) => {
              onSchoolDistrictsChanged(data, 'schoolDistrictOptions');
            }}
            disabled={
              user.roleOptionToggle.isAdminUser ||
              user.roleOptionToggle.isFacilityUser
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            search
            control={Select}
            multiple
            label="Schools"
            width="4"
            value={
              user.schools ? user.schools.map(school => school.id) : undefined
            }
            options={schoolOptions}
            onChange={(e, data) => {
              onSchoolsChanged(data, 'schoolOptions');
            }}
            disabled={
              user.roleOptionToggle.isAdminUser ||
              user.roleOptionToggle.isDistrictUser
            }
          />
        </Form.Group>
      </>
    )}
    <Form.Group>
      <Form.Checkbox
        onChange={ssoCheckboxChanged}
        checked={user.useSSO}
        label="Using SSO"
      />
    </Form.Group>
    <h6>Applications</h6>
    <Form.Group inline>
      {applicationRoles.map(role => {
        return (
          <Form.Checkbox
            checked={user.applicationRoles && user.applicationRoles[role._id]}
            onChange={(e, value) => onCheckboxChanged(role._id, value)}
            key={role._id}
            label={role.name}
          />
        );
      })}
    </Form.Group>
  </Form>
);

export default UserInvite;
