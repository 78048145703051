// Libs
import { call, put, takeLatest } from 'redux-saga/effects'

// Actions
import {
  fetchRolesFailure,
  fetchRolesRequest,
  fetchRolesSuccess
} from 'src/actions/applicationAccessRoles'

// Utils
import { APIProvider } from '../utils/API'

// Types
import { Auth0Role } from '../types/Auth0Role'

function* fetchApplicationAccessRoles(action: any) {
  try {
    const roles: Auth0Role[] = yield call(
      APIProvider.fetchApplicationAccessRoles
    )
    yield put(fetchRolesSuccess({ roles }))
  } catch (error) {
    yield put(fetchRolesFailure({ error }))
  }
}

export default function* rolesSaga() {
  yield takeLatest(fetchRolesRequest.toString(), fetchApplicationAccessRoles)
}
