import UserActions from 'src/actions/users'
import CrudSaga from 'src/sagas/helpers/CrudSaga'

import { APIProvider } from 'src/utils/API'

import { User } from '@120wateraudit/envirio-components/dist/models'

export default CrudSaga<User>({
  entityName: 'user',
  actions: UserActions,
  fetchEntityMethod: APIProvider.fetchUser,
  updateEntityMethod: APIProvider.updateUser,
  updateSuccessfulMessage: (user: User) => 'User updated successfully.'
})
