import * as React from 'react';

export default class FormContainer<TProps, TState> extends React.Component<
  any,
  any
> {
  onInputChanged = (e: any) => {
    if (e.target.name) {
      this.setState({
        model: {
          ...this.state.model,
          [e.target.name]: e.target.value
        }
      });
    }
  };

  onSelectListChanged = (data: any, modelKey: string) => {
    if (this.state.model.hasOwnProperty(modelKey)) {
      this.setState({
        model: {
          ...this.state.model,
          [modelKey]: data.value
        }
      });
    }
  };

  render(): any {
    return null;
  }
}
