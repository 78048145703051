import * as React from 'react';
import { Account } from 'src/types/AccountManagementTypes';
import { Button, Icon, Label } from 'semantic-ui-react';
import { AccountType } from '@120wateraudit/envirio-components/dist/models';
import styled from 'src/theme';
import {
  useGetChildAccountsQuery,
  useDisassociateChildAccountMutation
} from 'src/services';
import { useTableState } from 'src/hooks/useTableState';
import {
  Button as WWButton,
  Table as WWTable
} from '@120wateraudit/waterworks';
import AddAccountModal from 'src/containers/AccountChildAccounts/AddAccountModal';
import { useState } from 'react';
import { toastError, toastSuccess } from 'src/components/shared';
import { useToggle } from 'src/hooks';
import { ImportChildAccountsModal } from './ImportChildAccountsModal';

export interface Props {
  accountId: number;
}

const AccountNameWrapper = styled.div`
  max-width: 250px;
`;

export const AccountChildAccounts = ({ accountId }: Props) => {
  const { enabled: importModalOpen, toggle: toggleImportModal } = useToggle();

  const [disassociateAccount] = useDisassociateChildAccountMutation();

  const COLUMNS = [
    {
      key: 'id',
      Header: 'Account Id',
      accessor: ({ id, isAccountDisabled }: Account) => {
        let Disabled;
        if (isAccountDisabled) {
          Disabled = (
            <Label color="red" horizontal>
              Disabled
            </Label>
          );
        }
        return (
          <div>
            {Disabled}
            <a href={`/accounts/${id}?tab=Details`}>{id}</a>
          </div>
        );
      }
    },
    {
      key: 'pwsId',
      Header: 'PWS Id',
      accessor: 'pwsid'
    },
    {
      key: 'name',
      Header: 'Account Name',
      accessor: ({ id, name, isDemo }: Account) => {
        let Demo;
        if (isDemo) {
          Demo = (
            <Label color="red" horizontal>
              Demo
            </Label>
          );
        }
        return (
          <AccountNameWrapper>
            {Demo}
            <a href={`/accounts/${id}?tab=Details`}>{name}</a>
          </AccountNameWrapper>
        );
      }
    },
    {
      key: 'accountType',
      Header: 'Account Type',
      accessor: ({ accountType }: Account) => (
        <div>{AccountType[accountType]}</div>
      )
    },
    {
      key: 'companyName',
      Header: 'Company Name',
      accessor: 'companyName'
    },
    {
      key: 'remove',
      Header: '',
      accessor: (account: Account) => {
        return (
          <WWButton
            disabled={false}
            variant={'error'}
            onClick={async e => {
              e.stopPropagation();
              await disassociateAccount({
                parentAccountId: accountId,
                childAccountId: account.id
              })
                .unwrap()
                .then(() =>
                  toastSuccess(
                    'Successfully removed child account from this account.'
                  )
                )
                .catch(() =>
                  toastError('Removing child account failed, please try again.')
                );
            }}>
            Remove
          </WWButton>
        );
      }
    }
  ];

  const [addingToAccount, setAddingToAccount] = useState<boolean>(false);

  const toggleAddingToAccount = () => {
    setAddingToAccount(!addingToAccount);
  };

  const { params, setPageSize, setPageNumber } = useTableState({
    initialPageSize: 20
  });

  const { data: response, isFetching } = useGetChildAccountsQuery(
    {
      accountId,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize
    },
    {
      pollingInterval: 10 * 1000, // 10s
      refetchOnMountOrArgChange: true
    }
  );

  return (
    <div style={{ margin: '20px', width: '100%' }}>
      <Button
        disabled={false}
        icon
        labelPosition="right"
        onClick={e => {
          e.stopPropagation();
          toggleAddingToAccount();
        }}
        primary
        style={{ marginBottom: 20, marginRight: 15 }}>
        Add Child Account
        <Icon name="plus circle" />
      </Button>
      <Button
        disabled={false}
        icon
        labelPosition="right"
        onClick={toggleImportModal}
        primary
        style={{ marginBottom: 20 }}>
        Import Child Accounts
        <Icon name="plus circle" />
      </Button>
      <WWTable
        columns={COLUMNS}
        data={response?.items || []}
        itemName="Child Accounts"
        totalRecords={response?.count || 0}
        loading={isFetching}
        paginated
        page={params.pageNumber}
        pageSize={params.pageSize}
        onPageChanged={setPageNumber}
        onPageSizeChanged={setPageSize}
      />
      <AddAccountModal
        parentAccountId={accountId}
        toggleAddingToAccount={toggleAddingToAccount}
        addingToAccount={addingToAccount}
      />
      <ImportChildAccountsModal
        onClose={toggleImportModal}
        open={importModalOpen}
        parentAccountId={accountId}
      />
    </div>
  );
};
