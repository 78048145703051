import * as React from 'react'
import { Segment } from 'semantic-ui-react'

// Models
import { Role } from '@120wateraudit/envirio-components/dist/models'

// Components
import UserForm from 'src/components/Forms/User'

import { Button } from '@120wateraudit/envirio-components'

interface Props {
  isSaving: boolean
  onClickSave: any
  onCancelClicked: any
  roleOptions: Role[]
}

const CreateUser = ({
  isSaving,
  onClickSave,
  onCancelClicked,
  roleOptions
}: Props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginTop: '20px'
    }}>
    <Segment clearing attached>
      <h4 style={{ float: 'left' }}>New User</h4>
    </Segment>
    <Segment attached clearing loading={isSaving}>
      <UserForm isNew roleOptions={roleOptions} />
    </Segment>
    {
      <Segment attached clearing>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onCancelClicked}>Cancel</Button>
          <Button variant="primary" disabled={isSaving} onClick={onClickSave}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </Segment>
    }
  </div>
)

export default CreateUser
