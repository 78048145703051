import CrudReducer from 'src/reducers/helpers/DetailsCrudReducer'

// Accounts
import AccountUserActions from 'src/actions/accountUsers'

export default CrudReducer({
  entityName: 'accountUser',
  fetchAction: AccountUserActions.detailsActions.fetchRequest,
  fetchSuccessAction: AccountUserActions.detailsActions.fetchSuccess,
  fetchFailureAction: AccountUserActions.detailsActions.fetchFailure,
  updateActionRequest: AccountUserActions.updateActions.updateRequest,
  updateActionSuccess: AccountUserActions.updateActions.updateSuccess,
  updateActionFailure: AccountUserActions.updateActions.updateFailure,
  unloadAction: AccountUserActions.detailsActions.unload,
  deleteAction: AccountUserActions.deleteActions.deleteRequest,
  deleteSuccessAction: AccountUserActions.deleteActions.deleteSuccess,
  deleteFailureAction: AccountUserActions.deleteActions.deleteFailure,
  createActionRequest: AccountUserActions.createActions.createRequest,
  createActionSuccess: AccountUserActions.createActions.createSuccess,
  createActionFailure: AccountUserActions.createActions.createFailure
})
