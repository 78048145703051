import { Analyte } from '@120wateraudit/envirio-components/dist/models'
import { useCallback, useEffect, useState } from 'react'
import { Configuration } from 'src/types/Configuration'
import { LabConfiguration } from 'src/types/LabConfiguration'
import { UnitOfMeasureEntity } from 'src/types/UnitOfMeasureEntity'
import { APIProvider } from 'src/utils/API'

export const useAnalytes = () => {
  const [loading, setLoading] = useState(true)
  const [analytes, setAnalytes] = useState<Analyte[]>([])
  const fetchAnalytes = useCallback(async () => {
    setLoading(true)
    const url = '/platform/account-management/rest/analytes'
    const allAnalytes = (await APIProvider.fetch(url)) as Analyte[]
    setAnalytes(allAnalytes)
    setLoading(false)
  }, [setLoading])
  useEffect(() => {
    fetchAnalytes()
  }, [fetchAnalytes])
  return { analytes, loading, refetch: fetchAnalytes }
}

export const useEDDConfigs = (labId: number) => {
  const [loading, setLoading] = useState(true)
  const [labConfig, setLabConfig] = useState<undefined | LabConfiguration>(
    undefined
  )
  const fetchConfig = useCallback(async () => {
    setLoading(true)
    const url = `/edd-processor/lab/configuration/${labId}`
    const config = (await APIProvider.fetch(url)) as LabConfiguration
    setLabConfig(config)
    setLoading(false)
  }, [setLoading])
  useEffect(() => {
    fetchConfig()
  }, [fetchConfig])
  return { labConfig, loading, refetch: fetchConfig }
}

export const useUnitsOfMeasure = () => {
  const [loading, setLoading] = useState(true)
  const [units, setUnits] = useState<UnitOfMeasureEntity[]>([])
  const fetchUnits = useCallback(async () => {
    setLoading(true)
    const url = '/platform/account-management/rest/unitsOfMeasure'
    const allUnits = (await APIProvider.fetch(url)) as UnitOfMeasureEntity[]
    setUnits(allUnits.filter(unit => unit.enumValue !== null))
    setLoading(false)
  }, [setLoading])
  useEffect(() => {
    fetchUnits()
  }, [fetchUnits])
  return { loading, refetch: fetchUnits, units }
}

export interface CreateOrUpdateLabConfigurationData {
  configurations: Configuration[]
  id?: number
  labId: number
}

export const createOrUpdateLabConfiguration = async (
  data: CreateOrUpdateLabConfigurationData
) => {
  const url = '/edd-processor/lab/configuration'
  await APIProvider.post(url, data)
}
