import {
  Button,
  colors,
  fontSize,
  lineHeight,
  Modal,
  spacing
} from '@120wateraudit/envirio-components';

import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { sampleStatusListOptions } from '../../utils/SelectList';
import { SampleStatus } from 'src/types/Sample';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  onConfirm?: (status?: SampleStatus) => void;
  sampleIds: number[];
  targetStatus: SampleStatus | null;
  selectedStatus: SampleStatus | null;
  onSelectedStatusChange: (data: any) => void;
}

const filteredStatusListOptions = sampleStatusListOptions.filter(
  option => option.label !== 'Shipped'
);

const getStatus = (targetStatus, selectedStatus) => {
  if (targetStatus) {
    return targetStatus;
  } else if (selectedStatus) {
    return selectedStatus;
  } else {
    return SampleStatus.New; // should never be hit (for TS reasons)
  }
};

export const UpdateSampleStatusModal = ({
  isOpen,
  toggle,
  onConfirm,
  sampleIds,
  targetStatus,
  selectedStatus,
  onSelectedStatusChange
}: Props) => {
  const onCancel = () => {
    if (selectedStatus) {
      onSelectedStatusChange(null);
    } else {
      toggle();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      content={
        <div
          style={{
            height: '200px'
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '1.33rem'
            }}>
            <h4>Update Sample Status</h4>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '1.33rem',
              textAlign: 'center',
              fontSize: fontSize.body,
              lineHeight: lineHeight.body,
              color: colors.black50
            }}>
            {!targetStatus && !selectedStatus && (
              <>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  <div style={{ paddingRight: '10px' }}>Select Status:</div>
                  <span style={{ fontWeight: 700, width: '8rem' }}>
                    <Dropdown
                      multiple={false}
                      label="Select Status"
                      options={filteredStatusListOptions}
                      value={targetStatus || 0}
                      onChange={(e, data) => onSelectedStatusChange(data)}
                    />
                  </span>
                </div>
              </>
            )}
            {(targetStatus || selectedStatus) && (
              <>
                Are you sure you want to change the status of the{' '}
                {sampleIds.length === 1 ? 'sample' : 'samples'} below to{' '}
                {getStatus(targetStatus, selectedStatus)}?<br />
                <br />
                {sampleIds.join(', ')}
              </>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '1.33rem'
            }}>
            <div style={{ marginRight: spacing.mini }}>
              <Button onClick={onCancel}>Cancel</Button>
            </div>
            <Button
              onClick={() => {
                if (onConfirm && typeof onConfirm === 'function') {
                  onConfirm(getStatus(targetStatus, selectedStatus));
                }
              }}
              variant="primary"
              disabled={!selectedStatus && !targetStatus}>
              Yes, update status.
            </Button>
          </div>
        </div>
      }
    />
  );
};
