import { LabPartner } from '@120wateraudit/envirio-components/dist/models'
import gql from 'graphql-tag'

export const UPDATE_LAB_MUTATION = gql`
  mutation updateLab($labId: Int!, $lab: UpdateLabInputType!) {
    updateLab(labId: $labId, lab: $lab) {
      id
      name
      addressLine1
      addressLine2
      city
      state
      zip
      county
      country
      labPartnerId
      contactName
      contactPhone
      contactPhoneExt
      contactEmail
    }
  }
`
export const CREATE_LAB_MUTATION = gql`
  mutation createLab($lab: CreateLabInputType!) {
    createLab(lab: $lab) {
      id
      name
      addressLine1
      addressLine2
      city
      state
      zip
      county
      country
      labPartnerId
      contactName
      contactPhone
      contactPhoneExt
      contactEmail
    }
  }
`

export interface UpdateLabMutationArgs {
  labId: number
  lab: {
    addressLine1: string | null
    addressLine2?: string | null
    city: string | null
    state: string | null
    zip: string | null
    county?: string | null
    country: string | null
    name: string | null
    labPartnerId: number | null
    contactName?: string | null
    contactPhone?: string | null
    contactPhoneExt?: string | null
    contactEmail?: string | null
  }
}

export interface CreateLabMutationArgs {
  lab: {
    addressLine1: string
    addressLine2?: string
    city?: string
    state?: string
    zip?: string
    county?: string
    country?: string
    name: string
    labPartnerId: number | null
    contactName?: string
    contactPhone?: string
    contactPhoneExt?: string
    contactEmail?: string
  }
}

export interface CreateOrUpdateLabMutationResponse {
  id: number
  name?: string
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  zip: string
  county?: string
  country: string
  labPartnerId: number
  contactName?: string
  contactPhone?: string
  contactPhoneExt?: string
  contactEmail?: string
}

export interface LabPartnerData {
  count: number
  items: LabPartner[]
  pageNumber: number
  pageSize: number
  pages: number
}
