import * as React from 'react';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { AccountType } from '@120wateraudit/envirio-components/dist/models';

// Components
import { Icon, Search } from 'semantic-ui-react';
import { Button, DARK_BLUE } from '@120wateraudit/waterworks';
import { Modal } from '@120wateraudit/envirio-components';
import styled from 'styled-components';
import { SearchResult, toastError, toastSuccess } from 'src/components/shared';

// Custom Hooks
import {
  useGetAccountsQuery,
  useAssociateManyChildAccountsMutation
} from 'src/services';
import { useToggle } from 'src/hooks';

interface Props {
  parentAccountId: any;
  toggleAddingToAccount: () => void;
  addingToAccount: boolean;
}

const AddAccountModal = ({
  parentAccountId,
  toggleAddingToAccount,
  addingToAccount
}: Props) => {
  const [associateAccounts] = useAssociateManyChildAccountsMutation();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const { data: responseAccounts } = useGetAccountsQuery({
    paramsData: {
      pageNumber: 1,
      pageSize: 10,
      searchTerm,
      accountType: AccountType.PWS
    }
  });
  const [accountsToAdd, setAccountsToAdd] = useState<
    Array<{
      id: number;
      title: string;
    }>
  >([]);
  const { enabled: searchOpen, toggle: toggleSearchOpen } = useToggle(false);

  const onSubmitAddAccount = async () => {
    if (accountsToAdd && parentAccountId) {
      await associateAccounts({
        parentAccountId,
        childAccounts: accountsToAdd.map(a => a.id)
      })
        .unwrap()
        .then(() =>
          toastSuccess(
            `Successfully added ${accountsToAdd.length} child account(s) to this account.`
          )
        )
        .catch(() =>
          toastError('Adding child accounts failed, please try again.')
        );
      toggleAddingToAccount();
      setSearchTerm(undefined);
      setAccountsToAdd([]);
    }
  };

  const onSelectAccount = (
    _: SyntheticEvent,
    data: { result: { id: number; title: string } }
  ) => {
    setSearchTerm(searchTerm);
    setAccountsToAdd([...accountsToAdd, data.result]);
  };

  const onDeleteAccount = (accountToDelete: number) => {
    setAccountsToAdd([...accountsToAdd.filter(a => a.id !== accountToDelete)]);
  };

  const accounts = responseAccounts?.items || [];
  const accountsRecord = accounts
    .filter(a => !accountsToAdd.some(aa => aa.id === a.id))
    .map(account => ({
      id: account.id,
      title: account.name,
      description: account.id.toString()
    }));

  const childAccountsEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    childAccountsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [accountsToAdd]);

  return (
    <Modal
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <h4>Add Child Accounts</h4>
          <div
            style={{
              width: '100%',
              paddingRight: 10,
              maxHeight: 250,
              overflowY: 'auto'
            }}>
            {accountsToAdd.map(a => {
              return (
                <AccountPill key={`new_account_${a.id}`}>
                  <div>{`${a.id} - ${a.title}`}</div>
                  <Icon
                    name={'close'}
                    color={'red'}
                    size={'large'}
                    onClick={() => onDeleteAccount(a.id)}
                  />
                </AccountPill>
              );
            })}
            <div ref={childAccountsEndRef} />
          </div>
          <SearchWrapper>
            <Search
              input={{ icon: 'search', iconPosition: 'left' }}
              fluid={true}
              noResultsMessage="No Accounts Found"
              placeholder="Search for an account"
              onBlur={() => {
                if (searchOpen) {
                  toggleSearchOpen();
                }
              }}
              onFocus={() => {
                if (!searchOpen && searchTerm) {
                  toggleSearchOpen();
                }
              }}
              open={searchOpen}
              onResultSelect={onSelectAccount}
              onSearchChange={(_, data) => {
                if (
                  (!searchOpen && data.value) ||
                  (searchOpen && !data.value)
                ) {
                  toggleSearchOpen();
                }
                setSearchTerm(data.value);
              }}
              resultRenderer={SearchResult}
              results={accountsRecord}
              value={searchTerm}
            />
          </SearchWrapper>

          <div>
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Button
                onClick={() => {
                  setSearchTerm('');
                  toggleAddingToAccount();
                }}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => onSubmitAddAccount()}>
                Add
              </Button>
            </div>
          </div>
        </div>
      }
      isOpen={addingToAccount}
      overflowStyle="visible"
      toggle={toggleAddingToAccount}
    />
  );
};

const SearchWrapper = styled.div`
  width: 80%;
  &&& {
    .ui.input {
      width: 100%;
    }
  }
`;

const AccountPill = styled.div`
  padding: 10px;
  background-color: white;
  border: 1px solid ${DARK_BLUE};
  margin-bottom: 10px;
  text-align: center;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export default AddAccountModal;
