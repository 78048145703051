import * as React from 'react'
import { EmptyState, CenteringWrapper } from './shared'
import { colors, FileUploadButton } from '@120wateraudit/envirio-components'

interface NoEddTemplateFoundProps {
  errorMessage?: string
  onFileSelected(
    event: React.ChangeEvent<HTMLInputElement>,
    { file }: { file: File }
  ): void | Promise<void>
}

export const NoEddTemplateFound = ({
  onFileSelected,
  errorMessage
}: NoEddTemplateFoundProps) => (
  <EmptyState
    text="No Edd Template found for this account."
    subtext="Upload .xlsx file to create one now.">
    <div>
      {errorMessage && (
        <>
          <span style={{ color: colors.error }}>{errorMessage}</span>
          <br />
          <br />
        </>
      )}
      <CenteringWrapper>
        <FileUploadButton
          accept=".xls, .xlsx"
          onFileSelected={onFileSelected}
        />
      </CenteringWrapper>
    </div>
  </EmptyState>
)
