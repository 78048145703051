import { SettingDefinition } from '@120wateraudit/envirio-components/dist/models'
import { ApplicationState } from '../reducers/index'

export const getSettingDefinitions = (
  state: ApplicationState
): SettingDefinition[] => state.settingDefinitions.items

export const getSettingDefinitionsAsSelectList = (state: ApplicationState) =>
  getSettingDefinitions(state).map(d => ({
    key: d.name,
    text: d.name,
    value: d.id,
    placeholder: d.placeholder
  }))
