import {
  colors,
  NavProfile,
  TextNavigation
} from '@120wateraudit/envirio-components';
import { User } from '@120wateraudit/envirio-components/dist/models';
import * as React from 'react';
import { Container, Menu } from 'semantic-ui-react';
import ResultsUpload from 'src/containers/ResultsUpload';
import { NavigationItem } from 'src/containers/Navigation';
import styled from '../../theme';
import { Flags, useConfig } from 'src/hooks/useConfig';

interface Props {
  avatarUser: User;
  location: any;
  activeItem: string;
  onItemClicked: (e: any, { name }: { name: NavigationItem }) => void;
  onLogoutClicked: () => void;
}

const items = [
  {
    name: 'Accounts'
  },
  {
    name: 'Account Groups'
  },
  {
    name: 'Users'
  },
  {
    name: 'Settings'
  },
  {
    name: 'Labs'
  },
  {
    name: 'Fulfillment'
  },
  {
    name: 'SKUs'
  },
  {
    name: 'Reports'
  },
  {
    name: 'EDD Results'
  }
];

const Navigation = ({
  avatarUser,
  activeItem,
  onItemClicked,
  onLogoutClicked
}: Props) => {
  const hasAccountGroups = useConfig(Flags.BackofficeAccountGroup);
  return (
    <NavigationWrapper>
      <Container>
        <TextNavigation
          activeColor={colors.primary}
          inactiveColor={colors.black}
          onItemClicked={onItemClicked}
          activeItem={activeItem}
          items={
            !hasAccountGroups
              ? items.filter(i => i.name !== 'Account Groups')
              : items
          }
          extraNavItemsLeft={
            <Menu.Item
              onClick={() =>
                onItemClicked(null, { name: NavigationItem.Accounts })
              }>
              <Logo
                src="/logomark-color.png"
                srcSet="/logomark-color@2x.png 2x, /logomark-color@3x.png 3x"
              />
            </Menu.Item>
          }
          extraNavItemsRight={
            <Menu.Menu position="right" style={{ alignItems: 'center' }}>
              <UploadResultsWrapper>
                <ResultsUpload />
              </UploadResultsWrapper>
              <NavProfile
                firstName={avatarUser.firstName || ''}
                lastName={avatarUser.lastName || ''}
                email={avatarUser.email}
                logout={onLogoutClicked}
                sectionsToHide={[
                  'Settings',
                  'Support',
                  'Manage Custom Fields',
                  'Manage PTD Content'
                ]}
              />
            </Menu.Menu>
          }
        />
      </Container>
    </NavigationWrapper>
  );
};

const NavigationWrapper = styled.div`
  background: ${colors.white};
  height: 56px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 1rem 0 1rem 0;

  & .ui.text.menu {
    width: 100%;
  }
`;

const Logo = styled.img`
  margin: 0 2rem 0 0 !important;
`;

const UploadResultsWrapper = styled.div`
  display: inline-block;
  margin-right: 1rem;
`;

export default Navigation;
