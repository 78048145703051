import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { __DEV__ } from 'src/constants';
import reducer from 'src/reducers';
import sagas from 'src/sagas';
import { Middleware } from 'redux';
import { accountManagementService, eddService, pwsService } from 'src/services';

const sagaMiddleware = createSagaMiddleware();
const middlewares: Middleware[] = [sagaMiddleware];

if (__DEV__) {
  middlewares.push(logger);
}

// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
  // Automatically calls `combineReducers`
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // Fix state mutation between dispatches
      // Used when upgrading to redux toolkit
      // TODO: Ideally states should be immutable
      immutableCheck: false,
      serializableCheck: false
    }).concat(
      ...middlewares,
      accountManagementService.middleware,
      eddService.middleware,
      pwsService.middleware
    )
});

// Run our sagas
sagaMiddleware.run(sagas);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
// Because of the way we apply a "cross-entity reducer" this type is incorrect
export type RootState = ReturnType<typeof store.getState>;

export default store;
