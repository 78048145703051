import * as React from 'react';
import { Container } from 'semantic-ui-react';
import AccountUsers from 'src/components/AccountUsers';

interface Props {
  accountId: number;
  accountName: string;
  accountType: number;
}

const AccountUsersContainer = ({
  accountId,
  accountName,
  accountType
}: Props) => {
  return (
    <Container>
      <AccountUsers
        accountId={accountId}
        accountName={accountName}
        accountType={accountType}
      />
    </Container>
  );
};

export default AccountUsersContainer;
