import actionCreatorFactory from 'typescript-fsa'
const action = actionCreatorFactory()

import { School } from '@120wateraudit/envirio-components/dist/models'

export const fetchSchoolsRequest = action<{ accountId: number }>(
  'FETCH_SCHOOLS_REQUEST'
)
export const fetchSchoolsSuccess = action<{
  schools: School[]
}>('FETCH_SCHOOLS_SUCCESS')
export const fetchSchoolsFailure = action<{ error: string }>(
  'FETCH_SCHOOLS_FAILURE'
)
