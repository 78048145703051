import gql from 'graphql-tag'
import { ReportOutputType } from 'src/types/ReportOutputType'

export const PROGRAMS = gql`
  query programs($accountId: Int!, $programType: String) {
    programs(accountId: $accountId, programType: $programType) {
      id
      name
    }
  }
`
export interface ProgramsData {
  programs: Array<{
    id: number
    name: string
  }>
}

export interface ProgramsVariables {
  accountId: number
}

export const CREATE_REPORT_INSTANCE = gql`
  mutation createReportInstance(
    $accountId: Int
    $reportId: Int!
    $outputType: String
    $params: String
  ) {
    createReportInstance(
      accountId: $accountId
      reportId: $reportId
      outputType: $outputType
      params: $params
    )
  }
`
export interface CreateReportInstanceData {
  createReportInstance: boolean
}

export interface CreateReportInstanceVariables {
  accountId: number
  reportId: number
  outputType: string
  params?: string
}

export const REPORTS = gql`
  query reports($accountId: Int) {
    reports(accountId: $accountId) {
      id
      displayName
      parameters {
        parameterName
        isRequired
      }
    }
  }
`

export interface ReportsData {
  reports: {
    id: number
    displayName: string
  }
}

export interface ReportsVariables {
  accountId?: number
}
