import React from 'react'
import { TableCell } from 'semantic-ui-react'
import { Dropdown } from '@120wateraudit/envirio-components'
import { AccountAnalyte, AccountAnalyteWithRelations } from '../index'
import { booleanOptions } from 'src/utils/SelectList'

interface Props {
  accountAnalyte: AccountAnalyteWithRelations
  accountAnalyteToUpdate: AccountAnalyte
  isEditing: boolean
  setAccountAnalyteToUpdate(value: AccountAnalyte): void
  setIsRange(value: boolean): void
}

export const IsRangeCell = ({
  accountAnalyte,
  accountAnalyteToUpdate,
  isEditing,
  setAccountAnalyteToUpdate,
  setIsRange
}: Props) => {
  return (
    <TableCell>
      {!isEditing && (accountAnalyte.isRange ? 'Yes' : 'No')}
      {isEditing && (
        <Dropdown
          fluid
          defaultValue={accountAnalyte.isRange || false}
          options={booleanOptions}
          onChange={(_e, option) => {
            setAccountAnalyteToUpdate({
              ...accountAnalyteToUpdate,
              isRange: option.value,
              lowerLimit:
                option.value === false
                  ? null
                  : accountAnalyteToUpdate.lowerLimit ||
                    accountAnalyte.lowerLimit
            })
            setIsRange(option.value)
          }}
        />
      )}
    </TableCell>
  )
}
