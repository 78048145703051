import CrudReducer from 'src/reducers/helpers/DetailsCrudReducer'

// Accounts
import LabPartnerActions from 'src/actions/labPartners'

export default CrudReducer({
  entityName: 'labPartner',
  fetchAction: LabPartnerActions.detailsActions.fetchRequest,
  fetchSuccessAction: LabPartnerActions.detailsActions.fetchSuccess,
  fetchFailureAction: LabPartnerActions.detailsActions.fetchFailure,
  updateActionRequest: LabPartnerActions.updateActions.updateRequest,
  updateActionSuccess: LabPartnerActions.updateActions.updateSuccess,
  updateActionFailure: LabPartnerActions.updateActions.updateFailure,
  unloadAction: LabPartnerActions.detailsActions.unload,
  deleteAction: LabPartnerActions.deleteActions.deleteRequest,
  deleteSuccessAction: LabPartnerActions.deleteActions.deleteSuccess,
  deleteFailureAction: LabPartnerActions.deleteActions.deleteFailure,
  createActionRequest: LabPartnerActions.createActions.createRequest,
  createActionSuccess: LabPartnerActions.createActions.createSuccess,
  createActionFailure: LabPartnerActions.createActions.createFailure
})
