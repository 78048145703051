import { Button, Card } from '@120wateraudit/envirio-components';
import React from 'react';
import { Container, Divider, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import AppSkeleton from './AppSkeleton';

interface Props {
  logout: () => void;
}

const MissingRole = ({ logout }: Props): JSX.Element => {
  return (
    <AppSkeleton logout={logout}>
      <Container style={{ marginTop: '32px' }}>
        <Card>
          <Header as="h2" style={{ marginBottom: 0 }}>
            Unable to authenticate your account
          </Header>
          <Divider />
          <p style={{ fontSize: '1.5rem' }}>
            We could not identify your user account. Please try logging in
            again. If this issue persists, contact our{' '}
            <SupportLink>Support Team.</SupportLink>
          </p>
          <Row>
            <Button onClick={logout} variant="primary">
              Try Again
            </Button>
          </Row>
        </Card>
      </Container>
    </AppSkeleton>
  );
};

export const SupportLink = ({
  children,
  style
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}): JSX.Element => (
  <a
    href="https://120water.com/support"
    rel="noopener noreferrer"
    style={{ ...style, height: '24px' }}
    target="_blank">
    {children}
  </a>
);

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 4rem;
`;

export default MissingRole;
