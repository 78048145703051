import React, { FC, useState } from 'react'

import {
  Button,
  Modal,
  Wizard,
  fontSize,
  Loader
} from '@120wateraudit/envirio-components'
import ReactDropzone from 'react-dropzone'
import styled from 'src/theme'
import { uploadSingleFile } from 'src/utils/API'
import { AccountType } from '@120wateraudit/envirio-components/dist/models'
import { ApiError } from 'src/types/ApiError'

interface SchoolsUploadResponse {
  accountName: string
  imported: number
  newFixturesCreated: number
  newSamplesCreated: number
  schoolName: string
}

interface PWSUploadResponse {
  accountName: string
  imported: number
}

enum ApplicationType {
  SchoolsCustom = 'SchoolsCustom',
  PWS = 'PWS'
}

const ResultsUpload: FC = ({}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [application, setApplication] = useState<ApplicationType>(
    ApplicationType.SchoolsCustom
  )
  const [file, setFile] = useState<File | undefined>(undefined)
  const [isUploading, setIsUploading] = useState(false)
  const [errors, setErrors] = useState<Array<ApiError | string> | undefined>(
    undefined
  )
  const [schoolsResponse, setSchoolsResponse] = useState<
    SchoolsUploadResponse | undefined
  >(undefined)
  const [pwsResponse, setPwsResponse] = useState<PWSUploadResponse | undefined>(
    undefined
  )

  const Applications = {
    SchoolsCustom: {
      title: 'Schools',
      subTitle: 'Custom EDD',
      icon: '/building.svg',
      uploadUrl: '/edd-processor/edd/upload',
      accountType: AccountType.Schools
    },
    PWS: {
      title: 'PWS',
      subTitle: 'Custom EDD',
      icon: '/Line.svg',
      uploadUrl: '/edd-processor/edd/upload',
      accountType: AccountType.PWS
    }
  }

  const getInitialState = () => {
    setIsModalOpen(false)
    setApplication(ApplicationType.SchoolsCustom)
    setFile(undefined)
    setIsUploading(false)
    setErrors(undefined)
    setSchoolsResponse(undefined)
    setPwsResponse(undefined)
  }

  const toggleModal = () => {
    if (isModalOpen) {
      getInitialState()
    } else {
      setIsModalOpen(true)
    }
  }

  const onApplicationSelected = selectedApplication => {
    setApplication(selectedApplication)
    setFile(undefined)
    setErrors(undefined)
  }

  const onFileDropped = ([droppedFile]: File[]) => {
    setFile(droppedFile)
  }

  const submitResults = async () => {
    if (file && application) {
      try {
        setIsUploading(true)
        setErrors([])

        const { uploadUrl, accountType } = Applications[application]

        const response = await uploadSingleFile(uploadUrl, file, accountType)

        if (application === ApplicationType.SchoolsCustom) {
          const receivedSchoolsResponse: SchoolsUploadResponse = response

          setSchoolsResponse(receivedSchoolsResponse)
        } else {
          const receivedPwsResponse: PWSUploadResponse = response

          setPwsResponse(receivedPwsResponse)
        }
      } catch (e) {
        let errorsReceived: Array<ApiError | string> = []
        if (
          e.response &&
          e.response.data &&
          e.response.data.name === 'BadRequestError'
        ) {
          if (typeof e.response.data.message === 'object') {
            errorsReceived = [e.response.data.message.message]
          } else if (Array.isArray(e.response.data.message)) {
            errorsReceived = [...e.response.data.message]
          } else if (typeof e.response.data.message === 'string') {
            const errorsInString = e.response.data.message.split('<br />')
            errorsReceived = [...errorsInString]
          } else if (Array.isArray(e.response.data.errors)) {
            errorsReceived = [...e.response.data.errors]
          } else {
            errorsReceived.push('An error occurred processing your file.')
          }

          setErrors(errorsReceived)
        }
      } finally {
        setIsUploading(false)
      }
    }
  }

  const resultsWereUploaded = schoolsResponse || pwsResponse

  return (
    <ResultsUploadStyled>
      <Button variant="primary" onClick={() => toggleModal()}>
        Upload Results
      </Button>
      {isModalOpen && (
        <Modal
          style={{ content: { padding: '0px' } }}
          isOpen={isModalOpen}
          toggle={toggleModal}
          content={
            <Wizard
              completeButtonText={resultsWereUploaded ? 'Done' : 'Upload'}
              steps={[
                {
                  title: 'Select Account Type',
                  render: () => (
                    <Content>
                      {Object.entries(Applications).map(([type, app]) => (
                        <AppSelection
                          key={type}
                          className={type === application ? 'active' : ''}
                          onClick={() => onApplicationSelected(type)}>
                          <img src={app.icon} />
                          <h5>{app.title}</h5>
                          {app.subTitle}
                        </AppSelection>
                      ))}
                    </Content>
                  ),
                  validate: () => {
                    const validationErrors: string[] = []

                    if (!application) {
                      validationErrors.push(
                        'Select an account type to upload results for'
                      )
                    }

                    return validationErrors
                  }
                },
                {
                  title: 'Upload EDD',
                  render: () => (
                    <Content>
                      <UploadStep>
                        <h5>Uploading EDD for {application}</h5>
                        {file && <p>{file.name}</p>}

                        {isUploading && <Loader />}

                        {!isUploading && (schoolsResponse || pwsResponse) && (
                          <Success>
                            <h5>Results are queued for processing.</h5>
                            <p>
                              You should receive an update via email shortly.
                            </p>
                          </Success>
                        )}

                        {!isUploading && !resultsWereUploaded && (
                          <ReactDropzone
                            className="edd-upload"
                            onDrop={onFileDropped}>
                            <UploadInstructions>
                              <p>Drag and Drop a file, or click to Browse.</p>
                            </UploadInstructions>
                          </ReactDropzone>
                        )}
                        {!isUploading && errors && (
                          <Errors>
                            {errors.map((e, idx) => (
                              <li key={`${idx}-upload-error`}>{e}</li>
                            ))}
                          </Errors>
                        )}
                      </UploadStep>
                    </Content>
                  )
                }
              ]}
              onCompleted={() => {
                if (resultsWereUploaded) {
                  toggleModal()
                } else {
                  if (!isUploading) {
                    submitResults()
                  }
                }
              }}
              onCancel={toggleModal}
            />
          }
        />
      )}
    </ResultsUploadStyled>
  )
}

const ResultsUploadStyled = styled.div`
  .edd-upload {
    border-color: ${props => props.theme.colors.primary};
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 4rem 0;
`

const AppSelection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgba(0, 36, 45, 0.1);
  border-radius: 4px;
  padding: 2rem;
  width: 13rem;
  height: 13rem;
  text-align: center;
  img {
    height: 50px;
  }

  &:hover,
  &.active {
    background: rgba(0, 181, 225, 0.1);
    border-color: rgba(0, 181, 225, 0.1);
  }
  h5 {
    color: ${props => props.theme.colors.black75};
  }
  h6 {
    font-size: ${fontSize.caption};
  }
`

const UploadStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const UploadInstructions = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  align-items: center;
  width: 75%;
  margin: 0px auto;
  text-align: center;
  height: 116px;
  border-radius: 4px;
  border: 2px dashed ${props => props.theme.colors.primary};
  margin-top: 2rem;

  p {
    color: ${props => props.theme.colors.black50};
  }
`

const Errors = styled.ul`
  margin-top: 2rem;
  color: ${props => props.theme.colors.error};
`

const Success = styled.div`
  color: ${props => props.theme.colors.success};
  max-width: 75%;
  margin-top: 2rem;

  h5,
  p {
    text-align: center;
  }
`

export default ResultsUpload
