import { User } from '@120wateraudit/envirio-components/dist/models';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Navigation from 'src/components/Navigation';
import { ApplicationState } from 'src/reducers/index';
import { pushRoute } from 'src/utils/Navigation';
import { getUser } from '../../selectors/user';
import { useEffect, useState } from 'react';

interface Props {
  user: User;
  match: any;
  location: any;
  history: any;
  logout: () => void;
}

export enum NavigationItem {
  Accounts = 'Accounts',
  AccountGroups = 'Account Groups',
  Users = 'Users',
  Labs = 'Labs',
  Settings = 'Settings',
  Fulfillment = 'Fulfillment',
  SKUs = 'SKUs',
  Reports = 'Reports',
  EDDResults = 'EDD Results'
}

const NavigationContainer = ({ user, location, logout }: Props) => {
  const [activeItem, setActiveItem] = useState('');

  useEffect(() => {
    setActiveItem(NavigationItem.Accounts);
    const pathname = location.pathname;
    if (pathname.indexOf('/accounts') > -1 || pathname === '/') {
      setActiveItem(NavigationItem.Accounts);
    } else if (pathname.indexOf('/settingdefinitions') > -1) {
      setActiveItem(NavigationItem.Settings);
    } else if (pathname.indexOf('/labpartners') > -1) {
      setActiveItem(NavigationItem.Labs);
    } else if (pathname.indexOf('/users') > -1) {
      setActiveItem(NavigationItem.Users);
    } else if (pathname.indexOf('/fulfillment') > -1) {
      setActiveItem(NavigationItem.Fulfillment);
    } else if (pathname.indexOf('/skus') > -1) {
      setActiveItem(NavigationItem.SKUs);
    } else if (pathname.indexOf('/reports') > -1) {
      setActiveItem(NavigationItem.Reports);
    } else if (pathname.indexOf('/eddresults') > -1) {
      setActiveItem(NavigationItem.EDDResults);
    } else if (pathname.indexOf('/accountgroups') > -1) {
      setActiveItem(NavigationItem.AccountGroups);
    }
  }, [location.pathname]);

  const handleItemClick = (_e: any, { name }: { name: string }) => {
    setActiveItem(name);

    switch (name) {
      case NavigationItem.Accounts:
        pushRoute('/accounts');
        break;

      case NavigationItem.Users:
        pushRoute('/users');
        break;

      case NavigationItem.Settings:
        pushRoute('/settingdefinitions');
        break;

      case NavigationItem.Labs:
        pushRoute('/labpartners');
        break;

      case NavigationItem.Fulfillment:
        pushRoute('/fulfillment');
        break;

      case NavigationItem.SKUs:
        pushRoute('/skus');
        break;

      case NavigationItem.Reports:
        pushRoute('/reports');
        break;

      case NavigationItem.EDDResults:
        pushRoute('/eddresults');
        break;

      case NavigationItem.AccountGroups:
        pushRoute('/accountgroups');
        break;

      default:
        pushRoute('/');
    }
  };

  return (
    <Navigation
      activeItem={activeItem}
      avatarUser={user}
      location={location}
      onItemClicked={handleItemClick}
      onLogoutClicked={logout}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: getUser(state)
});

export default connect<any, any, any>(
  mapStateToProps,
  null
)(withRouter<Props>(NavigationContainer));
