import React from 'react';
import { Container } from 'semantic-ui-react';
import Definitions from 'src/components/Settings/index';

const SettingDefinitionsContainer = () => {
  return (
    <Container>
      <Definitions />
    </Container>
  );
};

export default SettingDefinitionsContainer;
