import * as React from 'react';
import { UpdateSampleStatusModal } from '../../components/Fulfillment/updateSampleStatusModal';
import { SampleStatus } from 'src/types/Sample';

interface Props {
  sampleIds: number[];
  showModal: boolean;
  toggleModal: () => void;
  targetStatus: SampleStatus | null;
  onConfirm: (status?: SampleStatus) => void;
}

interface State {
  documents: Document[];
  showCreateModal: boolean;
  showEditModal: boolean;
  showStatusChangeModal: boolean;
  selectedStatus: SampleStatus | null;
}

class UpdateSampleStatusModalContainer extends React.Component<Props, State> {
  state: State = {
    documents: [],
    showCreateModal: false,
    showEditModal: false,
    showStatusChangeModal: false,
    selectedStatus: null
  };

  onSelectedStatusChange = (data: any) => {
    this.setState({
      selectedStatus: data?.value || null
    });
  };
  render() {
    const {
      showModal,
      toggleModal,
      sampleIds,
      targetStatus,
      onConfirm
    } = this.props;

    const { selectedStatus } = this.state;

    return (
      <UpdateSampleStatusModal
        isOpen={showModal}
        toggle={toggleModal}
        onConfirm={onConfirm}
        sampleIds={sampleIds}
        targetStatus={targetStatus}
        selectedStatus={selectedStatus}
        onSelectedStatusChange={this.onSelectedStatusChange}
      />
    );
  }
}

export default UpdateSampleStatusModalContainer;
