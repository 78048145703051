import { Analyte } from '@120wateraudit/envirio-components/dist/models'
import { useCallback, useEffect, useState } from 'react'
import { APIProvider } from 'src/utils/API'
import { AccountAnalyte } from '.'

export const useAccountAnalytes = (accountId: number) => {
  const [loading, setLoading] = useState(true)
  const [accountAnalytes, setAccountAnalytes] = useState<Analyte[]>([])
  const fetchAccountAnalytes = useCallback(async () => {
    setLoading(true)
    const url = `/platform/account-management/rest/account-analytes/accountId/${accountId}`
    const analytes = (await APIProvider.fetch(url)) as Analyte[]
    setAccountAnalytes(analytes)
    setLoading(false)
  }, [setLoading])
  useEffect(() => {
    fetchAccountAnalytes()
  }, [fetchAccountAnalytes])
  return { accountAnalytes, loading, refetch: fetchAccountAnalytes }
}

export const updateAccountAnalyte = async (
  accountAnalyteId: number,
  updates: AccountAnalyte
) => {
  const url = `/platform/account-management/rest/account-analytes/${accountAnalyteId}`
  await APIProvider.put(url, updates)
}
