import * as React from 'react';

// Models
import {
  ProductCategory,
  ProductLine,
  ProductType
} from '@120wateraudit/envirio-components/dist/models';
import { Sku } from 'src/types/AccountManagementTypes';
import { FilterType } from '@120wateraudit/waterworks';

// Components
import { Card, Table as WWTable } from '@120wateraudit/waterworks';

// Custom Hooks
import { useTableState } from 'src/hooks/useTableState';
import { useGetSKUsQuery } from 'src/services';

const COLUMNS = [
  {
    key: 'code',
    Header: 'Code',
    sortable: false
  },
  {
    key: 'name',
    Header: 'Name',
    sortable: false
  },
  {
    key: 'description',
    Header: 'Description',
    sortable: false
  },
  {
    key: 'productLine',
    Header: 'Product Line',
    sortable: false,
    accessor: ({ productLine }: Sku) => <div>{ProductLine[productLine]}</div>
  },
  {
    key: 'productType',
    Header: 'Product Type',
    sortable: false,
    accessor: ({ productType }: Sku) => <div>{ProductType[productType]}</div>
  },
  {
    key: 'productCategory',
    Header: 'Product Category',
    sortable: false,
    accessor: ({ productCategory }: Sku) => (
      <div>{ProductCategory[productCategory]}</div>
    )
  }
];

const Skus = () => {
  const {
    params,
    setSearchTermWrapper,
    setPageSize,
    setPageNumber,
    setFiltersWrapper
  } = useTableState({});
  const { data: response, isFetching } = useGetSKUsQuery({
    paramsData: params
  });

  return (
    <Card style={{ marginTop: '20px' }}>
      <WWTable
        columns={COLUMNS}
        data={response?.items || []}
        itemName="SKUs"
        totalRecords={response?.count || 0}
        loading={isFetching}
        paginated
        page={params.pageNumber}
        pageSize={params.pageSize}
        onPageChanged={setPageNumber}
        onPageSizeChanged={setPageSize}
        searchable
        onSearchChanged={setSearchTermWrapper}
        filterable
        activeFilters={params.filters}
        onFilterChanged={setFiltersWrapper}
        filters={[
          {
            key: 'productLine',
            type: FilterType.ListSelection,
            options: [
              { id: 'PWS', value: ProductLine.PWS, label: 'PWS' },
              {
                id: 'Schools',
                value: ProductLine.Schools,
                label: 'Schools'
              },
              {
                id: 'Platform',
                value: ProductLine.Platform,
                label: 'Platform'
              },
              {
                id: 'wtk',
                value: ProductLine['Water Testing Kit'],
                label: 'Water Testing Kit'
              },
              {
                id: 'pfk',
                value: ProductLine['Pitcher Filter Kit'],
                label: 'Pitcher Filter Kit'
              },
              {
                id: 'wst',
                value: ProductLine['Water Sample Testing'],
                label: 'Water Sample Testing'
              },
              {
                id: 'rs',
                value: ProductLine['Recurring Services'],
                label: 'Recurring Services'
              },
              {
                id: 'ots',
                value: ProductLine['One Time Services'],
                label: 'One Time Services'
              }
            ]
          },
          {
            key: 'productCategory',
            type: FilterType.ListSelection,
            options: [
              {
                id: 'Software',
                value: ProductCategory.Software,
                label: 'Software'
              },
              {
                id: 'Kits',
                value: ProductCategory.Kits,
                label: 'Kits'
              },
              {
                id: 'Services',
                value: ProductCategory.Services,
                label: 'Services'
              }
            ]
          }
        ]}
      />
    </Card>
  );
};

export default Skus;
