import { fork } from 'redux-saga/effects'

// Sagas
import accounts from './accounts'
import accountUsers from './accountUsers'
import applicationAccessRoles from './applicationAccessRoles'
import applicationUser from './applicationUser'
import definitions from './definitions'
import labPartners from './labPartners'
import roles from './roles'
import schoolDistricts from './schoolDistricts'
import schools from './schools'
import settingDefinitions from './settingDefinitions'
import settings from './settings'
import userInvitations from './userInvitations'
import users from './users'

export default function* rootSaga() {
  yield [
    fork(accounts),
    fork(accountUsers),
    fork(definitions),
    fork(roles),
    fork(schoolDistricts),
    fork(schools),
    fork(settingDefinitions),
    fork(settings),
    fork(applicationUser),
    fork(userInvitations),
    fork(labPartners),
    fork(users),
    fork(applicationAccessRoles)
  ]
}
