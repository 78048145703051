import * as React from 'react';

// Models
import { ActiveFilters, FilterType } from '@120wateraudit/waterworks';
import { Account } from 'src/types/AccountManagementTypes';
import { AccountType } from '@120wateraudit/envirio-components/dist/models';
import { Sort } from 'src/services/types';

// Components
import { Icon, Label } from 'semantic-ui-react';
import { Button, Table as WWTable, Card } from '@120wateraudit/waterworks';
import LinkCell from 'src/components/Cells/LinkCell';

// Custom Hooks
import { useTableState } from 'src/hooks/useTableState';
import { useGetAccountsQuery } from 'src/services';
import styled from 'src/theme';

interface Props {
  onNewClicked: any;
  onImportClicked: any;
  defaultFilters?: ActiveFilters;
}

const COLUMNS = [
  {
    key: 'id',
    Header: 'Account Id',
    sortable: true,
    accessor: ({ id, isAccountDisabled }: Account) => {
      let Disabled;
      if (isAccountDisabled) {
        Disabled = (
          <Label color="red" horizontal>
            Disabled
          </Label>
        );
      }
      return (
        <div>
          {Disabled}
          <LinkCell text={id} to={`/accounts/${id}`} />
        </div>
      );
    }
  },
  {
    key: 'pwsId',
    Header: 'Pws Id',
    accessor: 'pwsid',
    sortable: true
  },
  {
    key: 'name',
    Header: 'Account Name',
    sortable: true,
    accessor: ({ id, name, isDemo }: Account) => {
      let Demo;
      if (isDemo) {
        Demo = (
          <Label color="red" horizontal>
            Demo
          </Label>
        );
      }
      return (
        <AccountNameWrapper>
          {Demo}
          <LinkCell text={name} to={`/accounts/${id}`} />
        </AccountNameWrapper>
      );
    }
  },
  {
    key: 'accountType',
    Header: 'Account Type',
    accessor: ({ accountType }: Account) => {
      if (AccountType[accountType] === 'Hydra') {
        return <div>Insights</div>;
      }
      if (AccountType[accountType] === 'Unknown') {
        return <div>BackOffice</div>;
      }

      return <div>{AccountType[accountType]}</div>;
    }
  },
  {
    key: 'companyName',
    Header: 'Company Name',
    accessor: 'companyName',
    sortable: true
  },
  {
    key: 'contactName',
    Header: 'Contact Name',
    accessor: 'contactName'
  },
  {
    key: 'contactPhone',
    Header: 'Contact Phone',
    accessor: 'contactPhone'
  },
  {
    key: 'contactEmail',
    Header: 'Contact Email',
    accessor: 'contactEmail'
  }
];

const Accounts = ({ onNewClicked, onImportClicked, defaultFilters }: Props) => {
  const {
    params,
    setSearchTermWrapper,
    setPageSize,
    setPageNumber,
    setSort,
    setFiltersWrapper
  } = useTableState({ defaultFilters });
  const { data: response, isFetching } = useGetAccountsQuery({
    paramsData: params
  });

  return (
    <div style={{ marginTop: '20px' }}>
      <Button onClick={onNewClicked} variant="primary">
        <Icon className="plus" style={{ marginRight: '1rem' }} />
        Create Account
      </Button>
      <Button
        onClick={onImportClicked}
        variant="primary"
        style={{ marginLeft: '1rem' }}>
        <Icon className="plus" style={{ marginRight: '1rem' }} />
        Import PWS Accounts
      </Button>
      <Card style={{ marginTop: '20px' }}>
        <WWTable
          columns={COLUMNS}
          data={response?.items || []}
          itemName="Accounts"
          totalRecords={response?.count || 0}
          loading={isFetching}
          paginated
          page={params.pageNumber}
          pageSize={params.pageSize}
          onPageChanged={setPageNumber}
          onPageSizeChanged={setPageSize}
          searchable
          onSearchChanged={setSearchTermWrapper}
          onSortChanged={setSort}
          sort={params.sort as Sort}
          sortable
          filterable
          activeFilters={params.filters}
          onFilterChanged={setFiltersWrapper}
          filters={[
            {
              key: 'accountType',
              type: FilterType.ListSelection,
              options: [
                { id: 'pws', value: AccountType.PWS, label: 'PWS' },
                {
                  id: 'insights',
                  value: AccountType.Hydra,
                  label: 'Insights'
                },
                {
                  id: 'schools',
                  value: AccountType.Schools,
                  label: 'Schools'
                },
                {
                  id: 'state',
                  value: AccountType.StateDashboard,
                  label: 'State'
                },
                {
                  id: 'backoffice',
                  value: AccountType.Unknown,
                  label: 'BackOffice'
                }
              ]
            },
            {
              key: 'isDemo',
              label: 'Demo',
              type: FilterType.ListSelection,
              options: [
                { id: 'True', value: 1, label: 'True' },
                { id: 'False', value: 0, label: 'False' }
              ]
            },
            {
              key: 'isAccountDisabled',
              label: 'Disabled',
              type: FilterType.ListSelection,
              options: [
                { id: 'True', value: 1, label: 'True' },
                { id: 'False', value: 0, label: 'False' }
              ]
            }
          ]}
        />
      </Card>
    </div>
  );
};

const AccountNameWrapper = styled.div`
  max-width: 250px;
`;

export default Accounts;
