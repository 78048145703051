import axios, { AxiosError, AxiosResponse } from 'axios';
import * as FileSaver from 'file-saver';
import {
  Account,
  AccountType,
  LabPartner,
  User
} from '@120wateraudit/envirio-components/dist/models';
import moment from 'moment';

import { getToken } from 'src/utils/Token';
import { pushRoute } from 'src/utils/Navigation';

// Use proxy for local dev, use ENV var from build otherwise
export const AWS_BASE = process.env.REACT_APP_AWS_ENDPOINT || '';

const CLIENT = axios.create({
  baseURL: AWS_BASE,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
});

interface Headers {
  Authorization: string;
}

interface Credentials {
  headers: Headers;
}

const redirectErrorStatuses = [401, 403];

const APIResponseSuccessHandler = (response: AxiosResponse) => response.data;
const APIResponseErrorHandler = (error: AxiosError) => {
  if (
    error.response &&
    error.response.status &&
    redirectErrorStatuses.indexOf(error.response.status) > -1
  ) {
    pushRoute('/login');
  } else {
    throw error;
  }
};

export class APIProvider {
  public static getAPICredentials(): Credentials {
    return {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    };
  }

  public static fetch(url: string) {
    const headers = { ...APIProvider.getAPICredentials().headers };
    return CLIENT.get(url, {
      headers
    })
      .then(APIResponseSuccessHandler)
      .catch(APIResponseErrorHandler);
  }

  public static fetchCSV(url: string) {
    return CLIENT.get(url, {
      headers: {
        ...APIProvider.getAPICredentials().headers,
        ['Accept']: 'text/csv'
      }
    })
      .then(APIResponseSuccessHandler)
      .catch(APIResponseErrorHandler);
  }

  public static fetchExcel(url: string) {
    return CLIENT.get(url, {
      headers: {
        ...APIProvider.getAPICredentials().headers,
        ['Accept']:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'arraybuffer'
    })
      .then((response: AxiosResponse) => ({
        data: response.data,
        headers: response.headers
      }))
      .catch(APIResponseErrorHandler);
  }

  public static post(url: string, data: any, config?: any) {
    return CLIENT.post(url, data, {
      ...APIProvider.getAPICredentials(),
      ...config
    })
      .then(APIResponseSuccessHandler)
      .catch(APIResponseErrorHandler);
  }

  public static put(url: string, data: any) {
    return CLIENT.put(url, data, {
      headers: {
        ...APIProvider.getAPICredentials().headers
      }
    })
      .then(APIResponseSuccessHandler)
      .catch(APIResponseErrorHandler);
  }

  public static delete(url: string) {
    return CLIENT.delete(url, {
      method: 'DELETE',
      ...APIProvider.getAPICredentials()
    })
      .then(APIResponseSuccessHandler)
      .catch(APIResponseErrorHandler);
  }

  public static downloadBlob = async (
    url: string,
    fileName: undefined | string
  ) => {
    return await CLIENT.get(url, {
      headers: {
        ...APIProvider.getAPICredentials().headers,
        Accept: 'application/octet-stream'
      },
      responseType: 'blob'
    })
      .then(response => FileSaver.saveAs(response.data, fileName))
      .catch(APIResponseErrorHandler);
  };

  public static fetchUser({ userId }: { userId: number }) {
    return APIProvider.fetch(
      `/platform/account-management/rest/users/${userId}`
    );
  }

  public static createUser({ user }: { user: User }) {
    return APIProvider.post(`/platform/account-management/rest/users`, user);
  }

  public static updateUser({ user }: { user: User }) {
    return APIProvider.put(
      `/platform/account-management/rest/users/${user.id}`,
      user
    );
  }

  // Account Methods
  public static fetchAccounts() {
    return APIProvider.fetch(`/platform/account-management/rest/accounts`);
  }

  public static fetchAccountsByType(accountType: AccountType) {
    return APIProvider.fetch(
      `/platform/account-management/rest/accounts-all?accountType=${accountType.toString()}`
    );
  }

  public static fetchSamplingEventsByAccount(accountId: number) {
    return APIProvider.fetch(
      `/pws/rest/accounts/${accountId}/samplingevents?pageSize=250`
    );
  }

  public static fetchAccount({
    accountId
  }: {
    accountId: number;
  }): Promise<Account | null> {
    return APIProvider.fetch(
      `/platform/account-management/rest/accounts/${accountId}`
    );
  }

  public static updateAccount({ account }: { account: Account }) {
    return APIProvider.put(
      `/platform/account-management/rest/accounts/${account.id}`,
      account
    );
  }

  public static createAccount({ account }: { account: Account }) {
    return APIProvider.post(
      `/platform/account-management/rest/accounts`,
      account
    );
  }

  public static deleteAccount({ accountId }: { accountId: number }) {
    return APIProvider.delete(
      `/platform/account-management/rest/accounts/${accountId}`
    );
  }

  public static refreshMapData({
    accountId,
    type
  }: {
    accountId: number;
    type: string;
  }) {
    return APIProvider.post(`/platform/map/etl`, { accountId, type });
  }

  // User Methods
  public static getCurrentUser = () => {
    return APIProvider.fetch(
      `/platform/account-management/rest/currentuser/@me`
    );
  };

  public static fetchAccountUser({
    accountId,
    id
  }: {
    accountId: number;
    id: string;
  }) {
    return APIProvider.fetch(
      `/platform/account-management/rest/accounts/${accountId}/users/${id}`
    );
  }

  public static createAccountUser({
    accountId,
    user
  }: {
    accountId: number;
    user: any;
  }) {
    return APIProvider.post(
      `/platform/account-management/rest/accounts/${accountId}/users`,
      user
    );
  }

  public static updateAccountUser({
    accountId,
    user
  }: {
    accountId: number;
    user: User;
  }) {
    return APIProvider.put(
      `/platform/account-management/rest/accounts/${accountId}/users/${user.id}`,
      user
    );
  }

  public static fetchRoles() {
    return APIProvider.fetch(`/platform/account-management/rest/roles`);
  }

  public static fetchApplicationAccessRoles() {
    return APIProvider.fetch(
      `/platform/account-management/rest/applicationroles`
    );
  }

  public static fetchSchoolDistricts(accountId: number) {
    return APIProvider.fetch(
      `/platform/account-management/rest/accounts/${accountId}/schooldistricts`
    );
  }

  public static fetchSchools(accountId: number) {
    return APIProvider.fetch(
      `/platform/account-management/rest/accounts/${accountId}/schools`
    );
  }

  public static inviteUserToAccount(data: {
    accountId: number;
    email: string;
    roles: string;
    schoolDistricts: string;
    schools: string;
    useSSO: boolean;
    userType: number;
  }) {
    return APIProvider.post(`/platform/account-management/rest/invites`, data);
  }

  public static resendUserInvite({ inviteId }: { inviteId: number }) {
    return APIProvider.post(
      `/platform/account-management/rest/invites/resend/${inviteId}`,
      {
        inviteId
      }
    );
  }

  public static revokeUserInvite({ inviteId }: { inviteId: number }) {
    return APIProvider.post(
      `/platform/account-management/rest/invites/revoke/${inviteId}`,
      undefined
    );
  }

  // Settings Definitions Methods
  public static fetchAccountTypeDefinitions() {
    return APIProvider.fetch(
      `/platform/account-management/rest/settingdefinitions`
    );
  }

  public static createAccountTypeDefinition({
    settingDefinition
  }: {
    settingDefinition: any;
  }) {
    return APIProvider.post(
      `/platform/account-management/rest/settingdefinitions`,
      settingDefinition
    );
  }

  public static fetchSettingDefinitions({
    accountId,
    accountType
  }: {
    accountId: number;
    accountType: number;
  }) {
    return APIProvider.fetch(
      `/platform/account-management/rest/accounts/${accountId}/accountsettingdefinitions/${accountType}`
    );
  }

  // Settings Methods
  public static fetchSettings({ accountId }: { accountId: number }) {
    return APIProvider.fetch(
      `/platform/account-management/rest/accounts/${accountId}/accountsettings/`
    );
  }

  public static fetchSendGridTemplates() {
    return APIProvider.fetch(
      `/platform/account-management/rest/sendgrid/templates`
    );
  }

  public static fetchSendGridTemplate(id: string) {
    return APIProvider.fetch(
      `/platform/account-management/rest/sendgrid/template/${id}`
    );
  }

  public static createSettings({
    accountId,
    settingDefinitionId,
    settings
  }: {
    accountId: number;
    settingDefinitionId: number;
    settings: any;
  }) {
    return APIProvider.post(
      `/platform/account-management/rest/accounts/${accountId}/accountsettings/${settingDefinitionId}`,
      settings
    );
  }

  public static updateSettings({
    accountId,
    settingId,
    settings
  }: {
    accountId: number;
    settingId: number;
    settings: any;
  }) {
    return APIProvider.put(
      `/platform/account-management/rest/accounts/${accountId}/accountsettings/${settingId}`,
      settings
    );
  }

  public static deleteSettings({
    accountId,
    settingId
  }: {
    accountId: number;
    settingId: number;
  }) {
    return APIProvider.delete(
      `/platform/account-management/rest/accounts/${accountId}/accountsettings/${settingId}`
    );
  }

  // Lab Methods

  public static createLabPartner({ labPartner }: { labPartner: LabPartner }) {
    return APIProvider.post(
      `/platform/account-management/rest/labpartners`,
      labPartner
    );
  }
}

export function uploadSingleFile(
  url: string,
  file: File,
  accountType?: AccountType
) {
  const data = new FormData();
  if (accountType) {
    data.append('accountType', AccountType[accountType]);
  }
  data.append('file', file);
  return APIProvider.post(url, data);
}

export const downloadReport = (
  accountId: number,
  documentId: number,
  fileName: undefined | string,
  dateRun: string
) => {
  const formattedFileName = `${moment(dateRun).format(
    'YYYY-MM-DD'
  )}_${fileName}`;

  const url = `${AWS_BASE}/platform/documents/accounts/${accountId ||
    0}/download/${documentId}`;
  return APIProvider.downloadBlob(url, formattedFileName);
};
