import React, { useEffect, useState } from 'react';

// Models
import { Role, User } from 'src/types/AccountManagementTypes';

// Components
import { Button, Icon } from 'semantic-ui-react';
import {
  Button as WWButton,
  Table as WWTable
} from '@120wateraudit/waterworks';
import LinkCell from 'src/components/Cells/LinkCell';
import FindUserModal from './FindUserModal';
import RemoveAccountModal from 'src/components/Users/RemoveAccountModal';

// Custom Hooks
import { useTableState } from 'src/hooks/useTableState';
import { useGetAccountUsersQuery } from 'src/services';
import { APIProvider } from 'src/utils/API';

interface Props {
  accountId: number;
  accountName: string;
  accountType: number;
}

const AccountUsers = ({ accountId, accountName, accountType }: Props) => {
  const {
    params,
    setSearchTermWrapper,
    setPageSize,
    setPageNumber
  } = useTableState({
    searchQueryType: 'search'
  });
  const { data: response, isFetching } = useGetAccountUsersQuery({
    accountId,
    paramsData: params
  });
  const [roles, setRoles] = useState<Role[]>([]);
  const [isAddingUser, setAddUserOpen] = useState(false);
  const [isRemovingUserOpen, setRemoveUserOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [userToRemove, setUserToRemove] = useState<User>();

  useEffect(() => {
    async function fetchRoles() {
      const roleResp: Role[] = await APIProvider.fetchRoles();
      setRoles(roleResp);
    }
    fetchRoles();
  }, []);

  const COLUMNS = [
    {
      key: 'name',
      Header: 'User Name',
      sortable: false,
      accessor: ({ id, firstName, lastName }: User) => {
        return (
          <LinkCell text={`${firstName} ${lastName}`} to={`/users/${id}`} />
        );
      }
    },
    {
      key: 'roles',
      Header: 'Roles',
      sortable: false,
      accessor: ({ userRoles }: User) => {
        return (
          <div>
            {userRoles
              ? userRoles
                  .map(ur => roles.find(r => ur.roleId === r.id)?.name)
                  .join(', ')
              : '--'}
          </div>
        );
      }
    },
    {
      key: 'email',
      Header: 'Email',
      sortable: false,
      accessor: 'email'
    },
    {
      key: 'remove',
      Header: '',
      accessor: (usr: User) => {
        return (
          <WWButton
            disabled={deleteLoading}
            variant={'error'}
            onClick={async e => {
              e.stopPropagation();
              setRemoveUserOpen(true);
              setUserToRemove(usr);
            }}>
            Remove
          </WWButton>
        );
      }
    }
  ];

  return (
    <div style={{ margin: '20px', width: '100%' }}>
      <Button
        disabled={deleteLoading}
        icon
        labelPosition="right"
        onClick={() => setAddUserOpen(true)}
        primary
        style={{ marginBottom: '20px' }}>
        Add Existing User
        <Icon name="plus circle" />
      </Button>
      <WWTable
        columns={COLUMNS}
        data={response?.items || []}
        itemName="Users"
        totalRecords={response?.count || 0}
        loading={isFetching}
        paginated
        page={params.pageNumber}
        pageSize={params.pageSize}
        onPageChanged={setPageNumber}
        onPageSizeChanged={setPageSize}
        searchable
        onSearchChanged={setSearchTermWrapper}
      />
      <FindUserModal
        accountId={accountId}
        accountType={accountType}
        addingToAccount={isAddingUser}
        toggleAddingToAccount={() => {
          setAddUserOpen(false);
        }}
      />
      <RemoveAccountModal
        accountId={accountId}
        accountName={accountName}
        userToRemove={userToRemove}
        isLoading={isFetching}
        deleteLoading={deleteLoading}
        setDeleteLoading={setDeleteLoading}
        isRemovingUserOpen={isRemovingUserOpen}
        setRemoveUserOpen={setRemoveUserOpen}
      />
    </div>
  );
};

export default AccountUsers;
