import { UserInvitation } from '@120wateraudit/envirio-components/dist/models'
import { handleActions } from 'redux-actions'
import {
  inviteUserFailure,
  inviteUserRequest,
  inviteUserSuccess
} from 'src/actions/userInvite'
export interface UserInviteState {
  error: any
  isSaving: boolean
  item: UserInvitation | undefined
}

const initialState = {
  error: undefined,
  isSaving: false,
  item: undefined
}

const userInviteReducer = handleActions(
  {
    [inviteUserRequest.toString()]: (state: UserInviteState, action: any) => ({
      ...state,
      error: undefined,
      isSaving: true,
      item: undefined
    }),
    [inviteUserSuccess.toString()]: (state: UserInviteState, action: any) => ({
      ...state,
      error: undefined,
      isSaving: false,
      item: action.payload.invite
    }),
    [inviteUserFailure.toString()]: (state: UserInviteState, action: any) => ({
      ...state,
      error: action.payload.error,
      isSaving: false,
      item: undefined
    })
  },
  initialState
)

export default userInviteReducer
