import { useState } from 'react'
import { APIProvider } from 'src/utils/API'

export const useFileDownload = (url: string) => {
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const download = async () => {
    setLoadingDownload(true)

    try {
      const s3url = await APIProvider.fetch(url)

      if (s3url) {
        const s3urlTag = document.createElement('a')
        s3urlTag.href = s3url
        s3urlTag.click()
      }
    } catch (error) {
      setErrorMessage(error.message)
    }

    setLoadingDownload(false)
  }

  return { errorMessage, loading: loadingDownload, download }
}
