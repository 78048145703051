import React, { useState, ChangeEvent } from 'react'
import { Button, Dropdown } from '@120wateraudit/envirio-components'
import { DropdownProps } from 'semantic-ui-react'

import styled from 'styled-components'
import { APIProvider } from 'src/utils/API'
import { toast, ToastOptions } from 'react-toastify'

interface Props {
  accountId: number
}

export const GeoserverRefresh = ({ accountId }: Props) => {
  const [entityType, changeEntityType] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const onEntityTypeChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: DropdownProps
  ) => {
    if (data.value) {
      changeEntityType(data.value?.toString())
    }
  }

  const onClickRefresh = async () => {
    if (entityType) {
      setLoading(true)
      await APIProvider.refreshMapData({ accountId, type: entityType })
      setLoading(false)
      toast(
        'Successfully triggered data reload, this can take some time to complete.',
        {
          ...defaultToastOpts,
          type: 'success'
        }
      )
    }
  }

  return (
    <RefreshForm>
      <h5>Seed or Refresh Map Data</h5>
      <Dropdown
        options={entityTypes}
        onChange={onEntityTypeChange}
        placeholder={'Select a Data Type'}
      />
      <Button
        variant="primary"
        onClick={onClickRefresh}
        disabled={!entityType || loading}>
        Apply
      </Button>
    </RefreshForm>
  )
}

const defaultToastOpts: ToastOptions = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true
}

const RefreshForm = styled.div`
  padding: 15px;
  > div {
    display: inline-block;
    margin-right: 1rem;
  }
`

const entityTypes = [
  {
    key: 'Locations',
    text: 'Locations',
    value: 'location'
  },
  {
    key: 'Service Lines',
    text: 'Service Lines',
    value: 'serviceline'
  }
]
