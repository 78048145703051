import React, { FC } from 'react'
import { Table, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

interface Props {
  isOpen: boolean
  toggleIsOpen: () => void
}

export const ToggleIcon: FC<Props> = ({ isOpen, toggleIsOpen }: Props) => {
  return (
    <TableIcon
      className="tableIcon"
      name={isOpen ? 'minus square' : 'plus square'}
      onClick={toggleIsOpen}
    />
  )
}

const TableIcon = styled(Icon)`
  color: rgba(0, 181, 225, 1);
`
