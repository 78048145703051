import * as moment from 'moment'
import * as React from 'react'
import { Button, Icon, Popup, Table } from 'semantic-ui-react'

// Components
import Grid from 'src/components/Grid'

// Types
import { Badge, BadgeVariant } from '@120wateraudit/envirio-components'
import { AWS_BASE } from 'src/utils/API'
import { UserInvitationStatus } from '../../types/UserInvitationStatus'

interface Props {
  accountId: number
  gridRef: any
  onInviteClicked: () => void
  onInviteUrlCopy: (inviteCode: string) => void
  onResendClicked: (inviteId: number) => void
  onRevokeClicked: (inviteId: number) => void
}

const UserInvitationStatusLabel = ({
  status
}: {
  status: UserInvitationStatus
}) => {
  let variant: BadgeVariant = 'primary'
  let text = ''

  switch (status) {
    case UserInvitationStatus.Accepted:
      variant = 'success'
      text = 'Accepted'
      break

    case UserInvitationStatus.Revoked:
      variant = 'error'
      text = 'Revoked'
      break

    case UserInvitationStatus.New:

    default:
      text = 'New'
  }

  return <Badge variant={variant}>{text}</Badge>
}

const AccountUsers = ({
  accountId,
  gridRef,
  onInviteClicked,
  onInviteUrlCopy,
  onResendClicked,
  onRevokeClicked
}: Props) => (
  <div style={{ margin: '20px', width: '100%' }}>
    <Button
      icon
      labelPosition="right"
      onClick={onInviteClicked}
      primary
      style={{ marginBottom: '20px' }}>
      Invite User
      <Icon name="plus circle" />
    </Button>
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Grid
        columns={['Email', 'Created', 'Expires', 'Status', 'Actions']}
        fetchUrl={`${AWS_BASE}/platform/account-management/rest/invites/accounts/${accountId}`}
        pageSize={5}
        ref={gridRef}
        rowRenderer={(invite: any) => {
          const createdOn = invite.createdOn
            ? moment(invite.createdOn).format('MM/DD/YYYY')
            : ''
          const expiresOn = invite.expiresOn
            ? moment(invite.expiresOn).format('MM/DD/YYYY')
            : ''
          const expired = moment()
            .utc()
            .isAfter(invite.expiresOn) ? (
            <span style={{ marginRight: '10px' }}>
              <Badge variant="error">Expired</Badge>
            </span>
          ) : null

          const actions: JSX.Element[] = []

          if (!expired && invite.status === UserInvitationStatus.New) {
            actions.push(
              <Button
                onClick={() => {
                  onRevokeClicked(invite.id)
                }}
                key={`revoke-button-${invite.id}`}
                size="small"
                color="red"
                content="Revoke"
              />
            )

            actions.push(
              <Button
                onClick={() => {
                  onInviteUrlCopy(invite.inviteUrl)
                }}
                key={`copy-link-button-${invite.id}`}
                size="small"
                color="blue"
                content="Copy Url"
              />
            )
          }

          if (invite.status === UserInvitationStatus.New) {
            actions.push(
              <Button
                onClick={() => {
                  onResendClicked(invite.id)
                }}
                key={`resend-button-${invite.id}`}
                size="small"
                color="green"
                content="Resend"
              />
            )
          }

          return (
            <Table.Row key={`account-user-invite-${invite.id}`}>
              <Table.Cell>{invite.email}</Table.Cell>
              <Table.Cell>{createdOn}</Table.Cell>
              <Table.Cell>
                {expired}
                {expiresOn}
              </Table.Cell>
              <Table.Cell>
                <UserInvitationStatusLabel status={invite.status} />
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'center' }}>
                {invite.status === UserInvitationStatus.New && (
                  <Popup
                    trigger={
                      <Button icon>
                        <Icon name="edit" />
                      </Button>
                    }
                    content={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '2px'
                        }}>
                        {actions.map((action, i) => {
                          return (
                            <>
                              {i > 0 && <div>&nbsp;</div>}
                              {action}
                            </>
                          )
                        })}
                      </div>
                    }
                    on="click"
                    position="top center"
                  />
                )}
              </Table.Cell>
            </Table.Row>
          )
        }}
      />
    </div>
  </div>
)

export default AccountUsers
