import React from 'react'
import { TableCell } from 'semantic-ui-react'
import { Dropdown } from '@120wateraudit/envirio-components'
import { AccountAnalyte, AccountAnalyteWithRelations } from '../index'
import { booleanOptions } from 'src/utils/SelectList'

interface Props {
  accountAnalyte: AccountAnalyteWithRelations
  accountAnalyteToUpdate: AccountAnalyte
  setAccountAnalyteToUpdate: React.Dispatch<
    React.SetStateAction<AccountAnalyte | undefined>
  >
  isEditing: boolean
}

export const IncludeInExportCell = ({
  accountAnalyte,
  accountAnalyteToUpdate,
  setAccountAnalyteToUpdate,
  isEditing
}: Props) => {
  return (
    <TableCell>
      {!isEditing && (accountAnalyte.includeInExport ? 'Yes' : 'No')}
      {isEditing && (
        <Dropdown
          fluid
          defaultValue={accountAnalyte.includeInExport}
          options={booleanOptions}
          onChange={(_e, option) => {
            setAccountAnalyteToUpdate({
              ...accountAnalyteToUpdate,
              includeInExport: option.value
            })
          }}
        />
      )}
    </TableCell>
  )
}
