import { LabPartner } from '@120wateraudit/envirio-components/dist/models'
import LabPartnersActions from 'src/actions/labPartners'
import CrudSaga from 'src/sagas/helpers/CrudSaga'
import { APIProvider } from 'src/utils/API'

export default CrudSaga<LabPartner>({
  entityName: 'labPartner',
  actions: LabPartnersActions,
  createEntityMethod: APIProvider.createLabPartner,
  createSuccessMessage: (partner: LabPartner) => `New lab partner created!`
})
