import {
  InMemoryCache,
  NormalizedCacheObject,
  defaultDataIdFromObject
} from 'apollo-cache-inmemory'
import ApolloClient, { Resolvers } from 'apollo-client'
import { split } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import { __DEV__ } from './constants'
import { AWS_BASE } from './utils/API'
import { getToken } from './utils/Token'

const isFile = value =>
  (typeof File !== 'undefined' && value instanceof File) ||
  (typeof Blob !== 'undefined' && value instanceof Blob)

const isUpload = ({ variables }) => {
  return Object.values(variables).some(isFile)
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken()

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const graphQLEndpoint = `${AWS_BASE}/platform/account-management/graphql`

const uploadLink = new createUploadLink({ uri: graphQLEndpoint })
const httpLink = new BatchHttpLink({ uri: graphQLEndpoint })

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development',
  link: authLink.concat(split(isUpload, uploadLink, httpLink))
})

export const accountMgmtClient = createClient({
  name: 'accountMgmtClient',
  uri: graphQLEndpoint
})

export const pwsClient = createClient({
  name: 'pwsClient',
  uri: `${AWS_BASE}/pws/graphql`
})

export const featuresClient = createClient({
  name: 'featuresClient',
  noBatch: true,
  uri: `${AWS_BASE}/platform/features/graphql`
})

export const reportsClient = createClient({
  name: 'reportsClients',
  noBatch: true,
  uri: `${AWS_BASE}/platform/reports`
})

function createClient({
  name,
  noBatch = false,
  resolvers = {},
  typeDefs,
  uri
}: {
  name?: string
  noBatch?: boolean
  resolvers?: Resolvers | Resolvers[]
  typeDefs?: any
  uri: string
}): ApolloClient<NormalizedCacheObject> {
  const featuresUploadLink = new createUploadLink({ uri })
  const featuresHttpLink = new BatchHttpLink({
    batchMax: noBatch ? 1 : undefined,
    uri
  })

  return new ApolloClient({
    cache: new InMemoryCache({
      dataIdFromObject: object => {
        switch (object.__typename) {
          case 'ReportInstanceGridQueryResponse':
            return object.__typename
          default:
            return defaultDataIdFromObject(object)
        }
      }
    }),
    connectToDevTools: __DEV__,
    link: authLink.concat(
      split(isUpload, featuresUploadLink, featuresHttpLink)
    ),
    name,
    resolvers,
    typeDefs
  })
}
