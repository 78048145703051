import { useCallback, useEffect, useReducer } from 'react'

import { AWS_BASE } from 'src/utils/API'
import { getToken } from 'src/utils/Token'
import { useAbort } from './useAbort'

/**
 * `useFetch` - used to fetch rest data
 *
 * Note: Accepts type generic
 *
 * @param url what endpoint to hit excluding `apiBase`
 * @param options fetch `RequestInit` options
 * @param lazy set to true if you want to manually call `fetchData` function
 * @param onCompleted run callback with data from the completed request
 */
export function useFetch<T>({
  apiBase = AWS_BASE,
  lazy = false,
  onCompleted,
  options,
  url
}: {
  apiBase?: string
  lazy?: boolean
  options?: RequestInit
  url: string
  onCompleted?: (data: T) => void
}) {
  const { abort, hasAborted } = useAbort()
  const [state, dispatch] = useReducer(fetchReducer<T>(), {
    data: undefined,
    error: undefined,
    loading: undefined
  })

  const fetchData = useCallback(async () => {
    dispatch({ type: DispatchType.LOADING })
    let data: T
    try {
      const response = await fetch(
        `${apiBase}${url}`,
        appendDefaultHeaders(options)
      )
      data = (await response.json()) as T
      if (!hasAborted) {
        dispatch({ data, type: DispatchType.RESPONSE })
      }
    } catch (error) {
      if (!hasAborted) {
        dispatch({ error, type: DispatchType.ERROR })
      }
    } finally {
      if (!hasAborted) {
        dispatch({ type: DispatchType.LOADING })
      }
      if (onCompleted) {
        onCompleted(data!)
      }
    }
  }, [url, options, hasAborted, onCompleted])

  useEffect(() => {
    if (!lazy) {
      fetchData()
    }

    return () => {
      abort()
    }
  }, [])

  return { ...state, abort, fetchData, hasAborted } as UseFetchReturnType<T>
}

const fetchReducer = <T>() => (
  state: State<T>,
  { data, error, type }: { data?: T; error?: Error; type: DispatchType }
): State<T> => {
  if (type === DispatchType.RESPONSE) {
    return {
      ...state,
      data
    }
  }

  if (type === DispatchType.ERROR) {
    return {
      ...state,
      error
    }
  }

  if (type === DispatchType.LOADING) {
    return {
      ...state,
      loading: !state.loading
    }
  }

  return state
}

const appendDefaultHeaders = (options?: RequestInit) => {
  return {
    ...options,
    headers: {
      ...(options && { ...options.headers }),
      Accept: 'application/json',
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json'
    }
  }
}

interface State<T> {
  data?: T
  error?: Error
  loading?: boolean
}

interface UseFetchReturnType<T> {
  data?: T
  error?: Error
  hasAborted: boolean
  loading: boolean
  abort: () => void
  fetchData: () => void
}

enum DispatchType {
  RESPONSE,
  ERROR,
  LOADING
}
