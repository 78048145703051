import * as React from 'react';
import { reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { Field } from 'src/components/Forms';

interface Props {
  isNew: boolean;
}

const LabPartnerForm = () => (
  <Form>
    <Form.Group width="equal">
      <Form.Field>
        <Field label="Name" name="name" />
      </Form.Field>
    </Form.Group>
  </Form>
);

export default reduxForm<object, Props>({
  form: 'labPartner'
})(LabPartnerForm);
