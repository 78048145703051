import * as React from 'react'
import { Redirect, Route } from 'react-router-dom'

export default ({ component: C, props: cProps, ...rest }: any) => (
  <Route
    {...rest}
    render={props =>
      cProps.user ? (
        <C {...props} {...cProps} />
      ) : (
        <Redirect
          to={`/login?redirect=${props.location.pathname}${props.location.search}`}
        />
      )
    }
  />
)
