import * as moment from 'moment'
import * as React from 'react'

// Components
import ReportCard from 'src/components/Common/ReportCard'

interface Props {
  accountId: number
}

class PWSAccountReportingContainer extends React.Component<Props, any> {
  render() {
    const { accountId } = this.props

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', padding: '20px' }}>
        <ReportCard
          description="View pending items ready to be shipped"
          filename={`fulfillment-report-${moment().format('MM-DD-YYYY')}`}
          heading="Fulfillment Report"
          url={`/platform/account-management/rest/reporting/pws/fulfillmentreport/${accountId}`}
        />
      </div>
    )
  }
}

export default PWSAccountReportingContainer
