import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import AuthenticatedRoute from 'src/router/AuthenticatedRoute';

// Actions
import { loginSuccess } from 'src/actions/user';

// Styles
import './styles.css';

// Layout
import App from 'src/containers/App';

// Containers
import Login from 'src/containers/Login';

import AccountDetails from 'src/containers/Accounts/details';
import Accounts from 'src/containers/Accounts/index';
import AccountUserDetails from 'src/containers/AccountUsers/details';
import InviteAccountUser from 'src/containers/AccountUsers/invite';
import Fulfillment from 'src/containers/Fulfillment/index';
import LabPartners from 'src/containers/LabPartners';
import Reports from 'src/containers/Reports';
import Definitions from 'src/containers/SettingDefinitions';
import Settings from 'src/containers/Settings/accountSettings';
import Skus from 'src/containers/Skus/index';
import CreateUser from 'src/containers/Users/create';
import UserDetails from 'src/containers/Users/details';
import Users from 'src/containers/Users/index';
import EDDResults from 'src/containers/EDDResults';

// Utils
import { browserHistory } from 'src/utils/Navigation';

// State
import { ApplicationState } from 'src/reducers';
import { getUser } from '../selectors/user';
import { LabConfigurations } from 'src/components/LabConfigurations';
import AccountGroups from 'src/containers/AccountGroups';
import AccountGroupsDetailContainer from 'src/containers/AccountGroups/details';

const authenticatedRoutes = [
  {
    component: Accounts,
    path: '/'
  },
  {
    component: Accounts,
    path: '/accounts'
  },
  {
    component: Users,
    path: '/users'
  },
  {
    component: CreateUser,
    path: '/users/new'
  },
  {
    component: UserDetails,
    path: '/users/:id'
  },
  {
    component: AccountDetails,
    path: '/accounts/:id'
  },
  {
    component: InviteAccountUser,
    path: '/accounts/:accountId/user/invite'
  },
  {
    component: AccountUserDetails,
    path: '/accounts/:accountId/users/:id'
  },
  {
    component: Settings,
    path: '/accounts/:accountId/accountsettings'
  },
  {
    component: Definitions,
    path: '/settingdefinitions'
  },
  {
    component: LabPartners,
    path: '/labpartners'
  },
  {
    component: Fulfillment,
    path: '/fulfillment'
  },
  {
    component: Skus,
    path: '/skus'
  },
  {
    component: Reports,
    path: '/reports'
  },
  {
    component: LabConfigurations,
    path: '/eddconfigurations/:id'
  },
  {
    component: EDDResults,
    path: '/eddresults'
  },
  {
    component: AccountGroups,
    path: '/accountgroups'
  },
  {
    component: AccountGroupsDetailContainer,
    path: '/accountgroups/:id'
  }
];

class Routes extends React.Component<any, any> {
  render() {
    const { user } = this.props;

    return (
      <Router history={browserHistory}>
        <Switch>
          <Route path="/login" component={Login} />
          <App user={user}>
            {authenticatedRoutes.map((r, i) => (
              <AuthenticatedRoute
                key={`authenticatedroute-${i}`}
                exact
                path={r.path}
                component={r.component}
                props={{ user }}
              />
            ))}
          </App>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: getUser(state)
});

const mapDispatchToProps = {
  loginSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
