import React, { FC } from 'react'
import { Field, FormRenderProps } from 'react-final-form'
import { StateList, colors, spacing } from '@120wateraudit/envirio-components'
import Dropdown from '../Dropdown/Dropdown'
import TextField from '../TextField/TextField'
import styled from 'styled-components'
import { ReactSelectOption } from 'src/utils/SelectList'

interface Props {
  labPartnerDropdownOptions: ReactSelectOption[]
  onSubmit: FormRenderProps['handleSubmit']
}

const LabForm: FC<Props> = ({ labPartnerDropdownOptions, onSubmit }) => {
  const style = { width: '100%' }

  const validatePhone = phoneNumber =>
    !phoneNumber ||
    phoneNumber.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/)
      ? undefined
      : 'Invalid Phone Number'

  return (
    <form onSubmit={onSubmit}>
      <Grid>
        <LabPartner>
          <Field<string>
            component={TextField}
            label="Lab Partner*"
            name="labPartnerName"
            style={style}
            disabled
          />
        </LabPartner>
        <NameAndAddress>
          <LabName>
            <Field<string>
              component={TextField}
              label="Lab Name*"
              name="name"
              style={style}
            />
          </LabName>
          <AddressLine1>
            <Field<string>
              component={TextField}
              label="Street Address*"
              name="addressLine1"
              style={style}
            />
          </AddressLine1>
        </NameAndAddress>
        <AddressLine2AndCity>
          <AddressLine2>
            <Field<string>
              component={TextField}
              label="Address Line 2"
              name="addressLine2"
              style={style}
            />
          </AddressLine2>
          <City>
            <Field<string>
              component={TextField}
              label="City*"
              name="city"
              style={style}
            />
          </City>
        </AddressLine2AndCity>
        <StateZip>
          <State>
            <Field<string>
              component={Dropdown}
              fluid
              label="State*"
              name="state"
              options={StateList}
              placeholder="Select..."
              search
            />
          </State>
          <Zip>
            <Field<string>
              component={TextField}
              label="Postal Code*"
              name="zip"
              style={style}
            />
          </Zip>
        </StateZip>
        <CountyCountry>
          <County>
            <Field<string>
              component={TextField}
              label="County"
              name="county"
              style={style}
            />
          </County>
          <Country>
            <Field<string>
              component={TextField}
              label="Country"
              name="country"
              style={style}
            />
          </Country>
        </CountyCountry>
        <BottomFormDivider />
        <ContactName>
          <Field<string>
            component={TextField}
            label="Contact Name"
            name="contactName"
            style={style}
          />
        </ContactName>
        <Email>
          <Field<string>
            component={TextField}
            label="Contact Email"
            name="contactEmail"
            style={style}
          />
        </Email>
        <ContactPhone>
          <Phone>
            <Field<string>
              component={TextField}
              inputMaskType="Phone"
              label="Contact Phone"
              name="contactPhone"
              style={style}
              validate={validatePhone}
            />
          </Phone>
          <Extension>
            <Field<string>
              component={TextField}
              label="Extension"
              name="contactPhoneExt"
              style={style}
            />
          </Extension>
        </ContactPhone>
      </Grid>
    </form>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: repeat(9, 1fr);
  grid-row-gap: 0.25rem;
  grid-template-areas:
    'LabPartner'
    'NameAndAddress'
    'AddressLine2AndCity'
    'StateZip'
    'CountyCountry'
    'BottomFormDivider'
    'ContactName'
    'Email'
    'ContactPhone';
`
const GridRow = styled.div`
  display: grid;
  grid-column-gap: 2%;
`
const LabPartner = styled(GridRow)`
  grid-area: LabPartner;
  grid-template-columns: 99%;
`
const NameAndAddress = styled(GridRow)`
  grid-area: NameAndAddress;
  grid-template-columns: 48.5% 48.5%;
  grid-template-areas: 'LabName AddressLine1';
`
const LabName = styled.div`
  grid-area: LabName;
`
const AddressLine1 = styled.div`
  grid-area: AddressLine1;
`
const AddressLine2AndCity = styled(GridRow)`
  grid-area: AddressLine2AndCity;
  grid-template-columns: 30% 67%;
  grid-template-areas: 'AddressLine2 City';
`
const AddressLine2 = styled.div`
  grid-area: AddressLine2;
`
const City = styled.div`
  grid-area: City;
`
const StateZip = styled(GridRow)`
  grid-area: StateZip;
  grid-template-columns: 57% 40%;
  grid-template-areas: 'State Zip';
`
const State = styled.div`
  grid-area: State;
`
const Zip = styled.div`
  grid-area: Zip;
`
const CountyCountry = styled(GridRow)`
  grid-area: CountyCountry;
  grid-template-columns: 67% 30%;
  grid-template-areas: 'County Country';
`
const County = styled.div`
  grid-area: County;
`
const Country = styled.div`
  grid-area: Country;
`
const ContactName = styled.div`
  grid-area: ContactName;
  grid-template-columns: 99%;
`
const Email = styled.div`
  grid-area: Email;
  grid-template-columns: 99%;
`
const ContactPhone = styled(GridRow)`
  grid-area: ContactPhone;
  padding-bottom: 1rem;
  grid-template-columns: 48.5% 48.5%;
  grid-template-areas: 'Phone Extension';
`
const Phone = styled.div`
  grid-area: Phone;
`
const Extension = styled.div`
  grid-area: Extension;
`
const FormDivider = styled.div`
  width: 100%;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.black25};
  margin-top: ${spacing.small};
`
const BottomFormDivider = styled(FormDivider)`
  grid-area: BottomFormDivider;
  margin-bottom: ${spacing.small};
`

export default LabForm
