import * as React from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';

// Component
import UserDetails from 'src/components/Users/details';

// Models
import {
  Account,
  AccountType,
  Role
} from '@120wateraudit/envirio-components/dist/models';
import { User } from 'src/types/AccountManagementTypes';
import { Auth0Role } from 'src/types/Auth0Role';

// Actions
import { fetchRolesRequest as fetchApplicationRolesRequest } from 'src/actions/applicationAccessRoles';
import { fetchRolesRequest } from 'src/actions/roles';
import UserActions from 'src/actions/users';

// Utils
import { omit } from 'lodash';
import { getFormValues } from 'redux-form';

// API
import { APIProvider } from 'src/utils/API';
import { ApplicationState } from 'src/reducers';

// Selectors
import { getApplicationRoles } from 'src/selectors/applicationAccessRoles';
import { getRoles, getRolesAsSelectList } from 'src/selectors/roles';

interface Props {
  applicationRoleOptions: Auth0Role[];
  formUser: User;
  isFetching: boolean;
  match: any;
  roleOptions: any;
  roles: Role[];
  user: User;

  // Actions
  fetchRolesRequest: () => void;
  fetchApplicationRolesRequest: () => void;
  fetchUser: ({ userId }: { userId: number }) => void;
  unloadUser: () => void;
  updateUser: ({ user }: { user: User }) => void;
}

interface State {
  addingToAccount: boolean;
  applicationRoles: { [key in Auth0Role['_id']]: boolean };
  defaultAccounts: { [key in string]: Account | null };
  defaultAccountsChanged: boolean;
  applicationRolesChanged: boolean;
  currentRoles: any[];
  editingRoles: boolean;
  isSaving: boolean;
  rolesAccount: undefined | Account;
}

class UserDetailsContainer extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      addingToAccount: false,
      applicationRoles: {},
      defaultAccounts: {},
      defaultAccountsChanged: false,
      applicationRolesChanged: false,
      currentRoles: [],
      editingRoles: false,
      isSaving: false,
      rolesAccount: undefined
    };
  }

  componentWillMount() {
    this.getAllRoles();
    this.fetchUser();
    this.fetchUserApplications();
    this.fetchDefaultAccounts();
  }

  componentWillUnmount() {
    this.props.unloadUser();
  }

  getAllRoles = () => {
    this.props.fetchApplicationRolesRequest();
    this.props.fetchRolesRequest();
  };

  fetchUser = () => {
    this.props.fetchUser({
      userId: this.props.match.params.id
    });
  };

  fetchUserApplications = async () => {
    try {
      const userApplications: Auth0Role[] = await APIProvider.fetch(
        `/platform/account-management/rest/applicationroles/${this.props.match.params.id}`
      );
      const applicationRoles = userApplications.reduce((prev, current) => {
        return {
          ...prev,
          [current._id]: true
        };
      }, {});

      this.setState({
        applicationRoles
      });
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.log(e);
    }
  };

  fetchDefaultAccounts = async () => {
    try {
      const defaultAccounts: {
        [key in string]: Account;
      } = await APIProvider.fetch(
        `/platform/account-management/rest/users/${this.props.match.params.id}/defaultaccounts`
      );

      this.setState({
        defaultAccounts,
        defaultAccountsChanged: false
      });
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.log(e);
    }
  };

  setDefaultAccount = (type: number, account: Account | null): void => {
    const defaultAccounts = this.state.defaultAccounts;
    defaultAccounts[AccountType[type]] = account;
    this.setState({ defaultAccounts, defaultAccountsChanged: true });
  };

  updateDefaultAccounts = async (
    defaultAccounts: { [key in string]: Account | null }
  ) => {
    try {
      const payload: { [key: string]: number | null | undefined } = {};
      Object.keys(defaultAccounts).forEach(k => {
        payload[k] = k in defaultAccounts ? defaultAccounts[k]?.id : null;
      });
      await APIProvider.put(
        `/platform/account-management/rest/users/${this.props.match.params.id}/defaultaccounts`,
        payload
      );
      await this.fetchDefaultAccounts();
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.log(e);
    }
  };

  updateUserApplications = async (roles: string[]) => {
    try {
      const userApplications: Auth0Role[] = await APIProvider.put(
        `/platform/account-management/rest/applicationroles/${this.props.match.params.id}`,
        { roles }
      );
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.log(e);
    }
  };

  onUpdateClicked = async () => {
    const user = this.props.formUser;
    const { applicationRoles, applicationRolesChanged } = this.state;
    const { defaultAccounts, defaultAccountsChanged } = this.state;

    if (applicationRolesChanged) {
      try {
        this.setState({
          isSaving: true
        });

        const roles: string[] = [];

        // Get the roleIDs for application roles from our applicationRoles object
        // e.g:
        // {
        //   "0e0e151f-4a07-4dee-b347-cf6839b355e2": true,
        // 	 "1e03151f-4a07-4dae-b347-sd6839b355e2": false
        // }
        Object.keys(applicationRoles).forEach(key => {
          // Filter out application roles that are set to false
          if (applicationRoles[key]) {
            roles.push(key);
          }
        });

        await this.updateUserApplications(roles);
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.log(e);
      } finally {
        this.setState({
          isSaving: false
        });
      }
    }

    if (defaultAccountsChanged) {
      await this.updateDefaultAccounts(defaultAccounts);
      this.setState({ defaultAccountsChanged: false });
    }

    this.props.updateUser({
      user: omit(user, ['roles', 'accounts', 'defaultAccountId']) as User
    });
  };

  toggleEditingRoles = () => {
    this.setState({
      currentRoles: [],
      editingRoles: !this.state.editingRoles,
      rolesAccount: undefined
    });
  };

  toggleAddingToAccount = () => {
    this.setState({
      addingToAccount: !this.state.addingToAccount
    });
  };

  onCheckboxChanged = (auth0RoleId: any, { checked }: { checked: boolean }) => {
    this.setState({
      applicationRoles: {
        ...this.state.applicationRoles,
        [auth0RoleId]: checked
      },
      applicationRolesChanged: true
    });
  };

  render() {
    const {
      applicationRoleOptions,
      isFetching,
      roleOptions,
      user
    } = this.props;

    const {
      addingToAccount,
      applicationRoles,
      defaultAccounts,
      editingRoles,
      isSaving,
      rolesAccount
    } = this.state;

    return (
      <Container>
        <UserDetails
          addingToAccount={addingToAccount}
          applicationRoleOptions={applicationRoleOptions || []}
          applicationRoles={applicationRoles}
          defaultAccounts={defaultAccounts}
          defaultAccountSetter={this.setDefaultAccount}
          editingRoles={editingRoles}
          isFetching={isFetching}
          isSaving={isSaving}
          onCheckboxChanged={this.onCheckboxChanged}
          onUpdateClicked={this.onUpdateClicked}
          roleOptions={roleOptions}
          rolesAccount={rolesAccount}
          roles={this.props.roles}
          toggleAddingToAccount={this.toggleAddingToAccount}
          toggleEditingRoles={this.toggleEditingRoles}
          user={user}
          fetchUser={this.fetchUser}
        />
      </Container>
    );
  }
}

const mapState = (state: ApplicationState) => ({
  applicationRoleOptions: getApplicationRoles(state),
  formUser: getFormValues('user')(state),
  isFetching: state.user.isFetching,
  roleOptions: getRolesAsSelectList(state),
  roles: getRoles(state),
  user: state.user.item
});

const mapDispatchToProps = {
  fetchRolesRequest,
  fetchApplicationRolesRequest,
  fetchUser: UserActions.detailsActions.fetchRequest,
  unloadUser: UserActions.detailsActions.unload,
  updateUser: UserActions.updateActions.updateRequest
};

export default connect(mapState, mapDispatchToProps)(UserDetailsContainer);
