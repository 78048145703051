import { Button } from '@120wateraudit/envirio-components';
import * as React from 'react';
import { Card, Icon } from 'semantic-ui-react';
// Utils
import { APIProvider } from 'src/utils/API';
import FileSaver from 'file-saver';

interface Props {
  heading: string;
  description: string;
  url: string;
  filename: string;
  csv?: boolean;
}

interface State {
  isLoading: boolean;
}

export default class ReportCard extends React.Component<Props, State> {
  state = { isLoading: false };

  fetchReport = async () => {
    try {
      this.setState({
        isLoading: true
      });

      const { filename, url, csv } = this.props;

      const results: any = csv
        ? await APIProvider.fetchCSV(url)
        : await APIProvider.fetchExcel(url);
      const blob = new Blob([csv ? results : results.data], {
        type: csv
          ? 'text/csv;charset=utf-8;'
          : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      FileSaver.saveAs(blob, `${filename}`, true);
    } catch (error) {
      if ('TextDecoder' in window) {
        const enc = new (window as any).TextDecoder();

        if (error && error.response && error.response.data) {
          // tslint:disable-next-line:no-console
          console.log(enc.decode(error.response.data));
        } else if (error && error.message) {
          // tslint:disable-next-line:no-console
          console.log(error.message);
        }
      }
    } finally {
      this.setState({
        isLoading: false
      });
    }
  };

  render() {
    const { heading, description } = this.props;
    const { isLoading } = this.state;

    return (
      <Card>
        <Card.Content header={heading} />
        <Card.Content description={description} />
        <Card.Content
          extra
          style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={this.fetchReport}>
            {isLoading ? 'Loading...' : 'View'}
            <Icon style={{ marginLeft: '10px' }} name="arrow right" />
          </Button>
        </Card.Content>
      </Card>
    );
  }
}
