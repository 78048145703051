import * as React from 'react'
import { Query } from 'react-apollo'
import { GET_EDD_WIZARD_DATA } from './data-access'
import { Loader } from '@120wateraudit/envirio-components'
import { EddTemplateWizard } from '../../components/EddTemplate/EddTemplateWizard'
import { EddUploadViewContainer } from './EddUploadViewContainer'
import { AccountType } from '@120wateraudit/envirio-components/dist/models'
import { EddTemplateWizardProps } from '../../components/EddTemplate/shared'

interface Props {
  accountId: number
  accountType: AccountType
  hasDefinitionsRow: boolean
  onDefinitionsRowChanged(): void
}

export const EddTemplateContainer = ({
  accountId,
  accountType,
  hasDefinitionsRow,
  onDefinitionsRowChanged
}: Props) => (
  <Query
    query={GET_EDD_WIZARD_DATA}
    variables={{
      accountId,
      accountType
    }}>
    {({ data, loading }) => {
      if (loading) {
        return <Loader />
      }

      if (responseIsNotValid(data)) {
        return null
      }

      return (
        <EddUploadViewContainer
          accountId={accountId}
          accountType={accountType}
          mostRecentEddTemplate={data.mostRecentEddTemplate}
          requiredFields={data.eddRequiredFields.fields.map(({ name }) => ({
            name,
            meta: { isRequired: true }
          }))}
          optionalFields={data.eddOptionalFields.fields}
          wizardRenderer={wizardRenderer}
          hasDefinitionsRow={hasDefinitionsRow}
          onDefinitionsRowChanged={onDefinitionsRowChanged}
        />
      )
    }}
  </Query>
)

const responseIsNotValid = data =>
  !data ||
  data.mostRecentEddTemplate === undefined ||
  !data.eddRequiredFields.fields ||
  !data.eddOptionalFields.fields

const wizardRenderer = (props: EddTemplateWizardProps) => (
  <EddTemplateWizard {...props} />
)
