import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Router from './router';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from 'src/constants';

// application store
import store from './store';

// apollo client
import { apolloClient } from './apolloClient';

// global styles
import { Theme } from '@120wateraudit/envirio-components';

import 'semantic-ui-css/semantic.min.css';
import 'src/styles/app.css';

import '@120wateraudit/envirio-components/src/styles.css';

import { unregister } from './registerServiceWorker';

ReactDOM.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    useRefreshTokens={true}
    cacheLocation={'memory'}>
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <Theme>
          <Router />
        </Theme>
      </ApolloProvider>
    </Provider>
  </Auth0Provider>,
  document.getElementById('root') as HTMLElement
);

unregister();
