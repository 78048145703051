import { SettingDefinition } from '@120wateraudit/envirio-components/dist/models'
import DefinitionsActions from 'src/actions/definitions'
import CrudSaga from 'src/sagas/helpers/CrudSaga'

import { APIProvider } from 'src/utils/API'

export default CrudSaga<SettingDefinition>({
  entityName: 'definition',
  actions: DefinitionsActions,
  fetchCollectionMethod: APIProvider.fetchAccountTypeDefinitions,
  createEntityMethod: APIProvider.createAccountTypeDefinition,
  createSuccessMessage: (settingDefinition: SettingDefinition) =>
    `New setting definition created!`
})
