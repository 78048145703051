import { Lab, LabPartner } from '@120wateraudit/envirio-components/dist/models'
import { ReactSelectOption } from 'src/utils/SelectList'

export const getLabPartnerDropdownOptions = (
  labPartners: LabPartner[]
): ReactSelectOption[] => {
  return labPartners.map(partner => {
    return {
      key: partner.id.toString(),
      value: partner.id,
      text: partner.name
    }
  })
}

export const generateInitialLabFormValues = ({
  labPartnerName,
  lab
}: GenerateInitialLabFormValuesArgs): LabValues => {
  return {
    addressLine1: lab && lab.addressLine1 ? lab.addressLine1 : undefined,
    addressLine2: lab && lab.addressLine2 ? lab.addressLine2 : undefined,
    city: lab && lab.city ? lab.city : undefined,
    state: lab && lab.state ? lab.state : undefined,
    zip: lab && lab.zip ? lab.zip : undefined,
    county: lab && lab.county ? lab.county : undefined,
    country: lab && lab.country ? lab.country : undefined,
    name: lab && lab.name ? lab.name : undefined,
    labPartnerName: labPartnerName ? labPartnerName : undefined,
    contactName: lab && lab.contactName ? lab.contactName : undefined,
    contactPhone: lab && lab.contactPhone ? lab.contactPhone : undefined,
    contactPhoneExt:
      lab && lab.contactPhoneExt ? lab.contactPhoneExt : undefined,
    contactEmail: lab && lab.contactEmail ? lab.contactEmail : undefined
  }
}

export interface GenerateInitialLabFormValuesArgs {
  labPartnerName?: string
  lab?: Lab
}

export interface LabValues {
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  zip?: string
  county?: string
  country?: string
  name?: string
  labPartnerName?: string
  contactName?: string
  contactPhone?: string
  contactPhoneExt?: string
  contactEmail?: string
}
