import * as React from 'react';

// Models
import { SettingDefinition } from 'src/types/AccountManagementTypes';
import { AccountType } from '@120wateraudit/envirio-components/dist/models';

// Components
import { Card, Table as WWTable } from '@120wateraudit/waterworks';

// Custom Hooks
import { useTableState } from 'src/hooks/useTableState';
import { useGetSettingDefinitionsQuery } from 'src/services';

const COLUMNS = [
  {
    key: 'accountType',
    Header: 'Account Type',
    sortable: false,
    accessor: ({ accountType }: SettingDefinition) => {
      if (AccountType[accountType] === 'Hydra') {
        return <div>Insights</div>;
      }
      if (AccountType[accountType] === 'Unknown') {
        return <div>BackOffice</div>;
      }

      return <div>{accountType === 0 ? 'All' : AccountType[accountType]}</div>;
    }
  },
  {
    key: 'name',
    Header: 'Setting Name',
    sortable: false,
    accessor: 'name'
  },
  {
    key: 'description',
    Header: 'Description',
    sortable: false,
    accessor: 'description'
  }
];

const Definitions = () => {
  const {
    params,
    setSearchTermWrapper,
    setPageSize,
    setPageNumber
  } = useTableState({});
  const { data: response, isFetching } = useGetSettingDefinitionsQuery({
    paramsData: params
  });

  return (
    <Card style={{ marginTop: '20px' }}>
      <WWTable
        columns={COLUMNS}
        data={response?.items || []}
        itemName="Setting Definitions"
        totalRecords={response?.count || 0}
        loading={isFetching}
        paginated
        page={params.pageNumber}
        pageSize={params.pageSize}
        onPageChanged={setPageNumber}
        onPageSizeChanged={setPageSize}
        searchable
        onSearchChanged={setSearchTermWrapper}
      />
    </Card>
  );
};

export default Definitions;
