import {
  Button,
  Dropdown,
  Grid,
  Loader
} from '@120wateraudit/envirio-components'
import { AccountType, Sku } from '@120wateraudit/envirio-components/dist/models'
import gql from 'graphql-tag'
import { sortBy } from 'lodash'
import * as React from 'react'
import { Mutation, Query } from 'react-apollo'
import { DropdownProps, TableCell, TableRow } from 'semantic-ui-react'
import styled from 'src/theme'

const ACCOUNT_SKUS_QUERY = gql`
  query account($id: Int!) {
    account(id: $id) {
      id
      skus {
        id
        name
        code
        description
        productLine
        productType
        productCategory
        sku
      }
      availableSkus {
        id
        name
        code
        description
        productLine
        productType
        productCategory
        sku
      }
    }
  }
`

const ADD_SKU_TO_ACCOUNT_MUTATION = gql`
  mutation addSkuToAccount($accountId: Int!, $skuId: Int!) {
    addSkuToAccount(accountId: $accountId, skuId: $skuId) {
      accountId
      skuId
    }
  }
`

const REMOVE_SKU_FROM_ACCOUNT_MUTATION = gql`
  mutation removeSkuFromAccount($accountId: Int!, $skuId: Int!) {
    removeSkuFromAccount(accountId: $accountId, skuId: $skuId) {
      accountId
      skuId
    }
  }
`

interface AccountSkusProps {
  id: number
  accountType: AccountType
}

interface AccountSkusState {
  selectedSkuId: number | null
}

class AccountSkus extends React.Component<AccountSkusProps, AccountSkusState> {
  constructor(props: AccountSkusProps) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState(): AccountSkusState {
    return {
      selectedSkuId: null
    }
  }

  onSelectedSkuChanged = (
    event: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    if (typeof value === 'number') {
      this.setState({ selectedSkuId: value })
    }
  }

  onAddSkuClicked = async addSkuToAccount => {
    const { id } = this.props
    const { selectedSkuId } = this.state

    if (selectedSkuId !== null) {
      await addSkuToAccount({
        variables: {
          accountId: id,
          skuId: selectedSkuId
        }
      })

      this.setState(this.getInitialState())
    }
  }

  render() {
    const { id } = this.props
    const queriesToFetchOnChanged = [
      { query: ACCOUNT_SKUS_QUERY, variables: { id } }
    ]

    return (
      <Mutation
        mutation={REMOVE_SKU_FROM_ACCOUNT_MUTATION}
        refetchQueries={queriesToFetchOnChanged}>
        {removeSkuFromAccount => (
          <>
            <Mutation
              mutation={ADD_SKU_TO_ACCOUNT_MUTATION}
              refetchQueries={queriesToFetchOnChanged}>
              {addSkuToAccount => (
                <Query query={ACCOUNT_SKUS_QUERY} variables={{ id }}>
                  {({ loading, data }) => {
                    if (!loading && data && data.account) {
                      return (
                        <AddSkuSection>
                          <Dropdown
                            label="Add SKU"
                            options={this.transformSkusToDropdownOptions(
                              data.account.availableSkus
                            )}
                            onChange={this.onSelectedSkuChanged}
                            search
                            placeholder={'Select a SKU'}
                          />
                          <Button
                            variant="primary"
                            onClick={() =>
                              this.onAddSkuClicked(addSkuToAccount)
                            }>
                            Add SKU
                          </Button>
                        </AddSkuSection>
                      )
                    }
                    return null
                  }}
                </Query>
              )}
            </Mutation>
            <Query query={ACCOUNT_SKUS_QUERY} variables={{ id }}>
              {({ loading, data }) => {
                if (loading) {
                  return <Loader />
                }

                const skus: Sku[] = data.account.skus

                return (
                  <>
                    <h4>SKUs</h4>
                    <Grid
                      style={{}}
                      columns={[
                        {
                          key: 'code',
                          sortable: false,
                          name: 'Code'
                        },
                        {
                          key: 'name',
                          sortable: false,
                          name: 'Name'
                        },
                        {
                          key: 'description',
                          sortable: false,
                          name: 'Description'
                        },
                        {
                          key: 'actions',
                          sortable: false,
                          name: 'Actions'
                        }
                      ]}
                      data={skus}
                      renderRow={(sku: Sku) => (
                        <TableRow key={sku.id}>
                          <TableCell>{sku.code}</TableCell>
                          <TableCell>{sku.name}</TableCell>
                          <TableCell>{sku.description}</TableCell>
                          <TableCell>
                            <Button
                              variant="error"
                              onClick={() => {
                                removeSkuFromAccount({
                                  variables: {
                                    accountId: id,
                                    skuId: sku.id
                                  }
                                })
                              }}>
                              Remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    />
                  </>
                )
              }}
            </Query>
          </>
        )}
      </Mutation>
    )
  }

  private transformSkusToDropdownOptions = (
    skus: Sku[]
  ): DropdownProps['options'] =>
    sortBy(
      skus.map(s => ({
        text: `${s.sku} : ${s.name}`,
        value: s.id
      })),
      'text'
    )
}

const AddSkuSection = styled.div`
  > div {
    display: inline-block;
    margin-right: 1rem;
  }
`

export default AccountSkus
