import { handleActions } from 'redux-actions'

// Actions
import {
  fetchSchoolsFailure,
  fetchSchoolsRequest,
  fetchSchoolsSuccess
} from 'src/actions/schools'

// Types
import { School } from '@120wateraudit/envirio-components/dist/models'

export interface SchoolsState {
  error: any
  isFetching: boolean
  items: School[]
}

const initialState = {
  error: undefined,
  isFetching: false,
  items: []
}

const schoolsReducer = handleActions(
  {
    [fetchSchoolsRequest.toString()]: (state: any, action: any) => ({
      ...state,
      error: undefined,
      isFetching: true
    }),
    [fetchSchoolsSuccess.toString()]: (state: any, action: any) => ({
      ...state,
      error: undefined,
      isFetching: false,
      items: action.payload.schools
    }),
    [fetchSchoolsFailure.toString()]: (state: any, action: any) => ({
      ...state,
      error: action.payload.error,
      isFetching: false
    })
  },
  initialState
)

export default schoolsReducer
