import React, { useState } from 'react';

// Models
import { ReportInstance } from 'src/types/ReportInstance';
import { Sort } from 'src/services/types';
import { ReportInstanceStatus } from '@120wateraudit/envirio-components/dist/models';

// Components
import { Query } from 'react-apollo';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { Icon } from 'semantic-ui-react';
import { Card, Table as WWTable } from '@120wateraudit/waterworks';
import { GET_ALL_REPORT_INSTANCES } from 'src/containers/Reports/data-access';
import { reportsClient } from 'src/apolloClient';
import { REPORTS } from 'src/components/Reports/CreateReportModal/dataAccess';
import { ReportInstanceStatusBadge } from 'src/components/Reports/ReportsTable/ReportInstanceStatusBadge';
import { downloadReport } from 'src/utils/API';

const COLUMNS = [
  {
    Header: 'Report Name',
    key: 'reportName',
    sortable: false
  },
  {
    Header: 'Params',
    key: 'params',
    sortable: false,
    accessor: ({ params }: ReportInstance) => {
      return (
        (params && (
          <pre>
            {Object.entries(JSON.parse(params))
              .map(([key, value]) => {
                if (value && (key === 'startDate' || key === 'endDate')) {
                  value = moment(value).format('MM/DD/YYYY hh:mm A');
                }
                return `${key}: ${value}`;
              })
              .join('\n')}
          </pre>
        )) ||
        '--'
      );
    }
  },
  {
    Header: 'Date Run',
    key: 'dateRun',
    sortable: true,
    accessor: ({ dateRun }: ReportInstance) => {
      return moment(dateRun).format('MM/DD/YYYY hh:mm A');
    }
  },
  {
    Header: 'Status',
    key: 'status',
    sortable: false,
    accessor: ({ status, error }: ReportInstance) => {
      return <ReportInstanceStatusBadge status={status} error={error} />;
    }
  },
  {
    Header: 'Download',
    key: 'download',
    sortable: false,
    accessor: ({
      status,
      accountId,
      documentId,
      fileName,
      dateRun
    }: ReportInstance) => {
      return (
        status === ReportInstanceStatus.Processed && (
          <Icon
            link
            name="download"
            size="big"
            onClick={async () => {
              await downloadReport(accountId, documentId, fileName, dateRun);
            }}
          />
        )
      );
    }
  }
];

const ReportsTable = () => {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = React.useState<Sort | undefined>();
  // Pass in createdOn and returns a dateRun in reports-api
  const [sorts, setSorts] = React.useState<{ createdOn: string }>();

  const setSortWrapper = (sortData: Sort) => {
    setSort(sortData);
    setSorts(sortData ? { createdOn: sortData.direction } : undefined);
  };

  const getVariables = () => {
    return {
      page: page - 1,
      pageSize,
      sorts,
      filters: {}
    };
  };

  return (
    <Query client={reportsClient} query={REPORTS}>
      {() => {
        return (
          <Query
            fetchPolicy="cache-and-network"
            client={reportsClient}
            query={GET_ALL_REPORT_INSTANCES}
            variables={getVariables()}>
            {({ data, loading }) => {
              let items: ReportInstance[] = [];
              let total = 0;

              if (!loading && !isEmpty(data)) {
                const values = Object.values(data);

                items = (values[0] as any).items;
                total = data.reportInstances.total;
              }

              return (
                <Card style={{ marginBottom: '50px' }}>
                  <WWTable
                    columns={COLUMNS}
                    data={items || []}
                    itemName="Reports"
                    totalRecords={total}
                    loading={loading}
                    paginated
                    page={page}
                    pageSize={pageSize}
                    onPageChanged={setPage}
                    onPageSizeChanged={setPageSize}
                    sortable
                    sort={sort}
                    onSortChanged={setSortWrapper}
                  />
                </Card>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default ReportsTable;
