// Libs
import { call, put, takeLatest } from 'redux-saga/effects'

import {
  inviteUserFailure,
  inviteUserRequest,
  inviteUserSuccess,
  resendInviteFailure,
  resendInviteRequest,
  resendInviteSuccess,
  revokeInviteFailure,
  revokeInviteRequest,
  revokeInviteSuccess
} from 'src/actions/userInvite'

// Utils
import { APIProvider } from 'src/utils/API'
import { pushRoute } from 'src/utils/Navigation'

// Types
import { UserInvite } from '../types/UserInvite'

function* inviteUserSaga(action: any) {
  try {
    // Grab payload from action
    const { payload } = action
    // Create invite and assign to var
    const invite: UserInvite = yield call(
      APIProvider.inviteUserToAccount,
      payload
    )

    // Dispatch success action
    yield put(inviteUserSuccess({ invite }))
    // Redirect user to list of outstanding invites for the account
    if (invite.account) {
      yield call(pushRoute, `/accounts/${invite.account.id}/?tab=4`)
    }
  } catch (error) {
    const targetError =
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
        ? error.response.data.message
        : error
    yield put(inviteUserFailure({ error: targetError }))
  }
}

function* resendInviteSaga(action: any) {
  try {
    // Grab invite id
    const { inviteId, callback } = action.payload
    // POST to API
    yield call(APIProvider.resendUserInvite, { inviteId })
    // Dispatch success
    yield put(resendInviteSuccess())
    // Call supplied callback
    if (typeof callback === 'function') {
      yield call(callback)
    }
  } catch (error) {
    yield put(resendInviteFailure({ error }))
  }
}

function* revokeInviteSaga(action: any) {
  try {
    // Grab invite id
    const { inviteId, callback } = action.payload
    // POST to API
    yield call(APIProvider.revokeUserInvite, { inviteId })
    // Dispatch success
    yield put(revokeInviteSuccess())
    // Call supplied callback
    if (typeof callback === 'function') {
      yield call(callback)
    }
  } catch (error) {
    yield put(revokeInviteFailure({ error }))
  }
}

export default function* schoolsSaga() {
  yield takeLatest(inviteUserRequest.toString(), inviteUserSaga)
  yield takeLatest(resendInviteRequest.toString(), resendInviteSaga)
  yield takeLatest(revokeInviteRequest.toString(), revokeInviteSaga)
}
