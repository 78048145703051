import { User } from '@120wateraudit/envirio-components/dist/models';
import React, { useEffect, useState } from 'react';
import { APIProvider } from 'src/utils/API';
import { Checkbox, Grid, Label } from 'semantic-ui-react';
import { Button } from '@120wateraudit/envirio-components';
import './styles.css';

interface Props {
  user: User;
}

const MFA = ({ user }: Props) => {
  const [mfa, setMfa] = useState({
    enabled: false,
    required: false
  });
  const [enabled, setEnabled] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (user && user.id) {
      (async () => {
        const mfaData = await APIProvider.fetch(
          `/platform/account-management/rest/users/${user.id}/mfa`
        );
        setMfa(mfaData);
        setEnabled(mfaData.enabled);
      })();
    }
  }, []);

  const handleCheckboxChanged = (e: never, { checked }: never) => {
    setEnabled(checked);
  };

  const handleUpdate = () => {
    setSaving(true);
    APIProvider.put(`/platform/account-management/rest/users/${user.id}/mfa`, {
      enabled
    }).then(() => {
      mfa.enabled = enabled;
      setMfa(JSON.parse(JSON.stringify(mfa)));
      setSaving(false);
    });
  };

  const resetMFA = () => {
    setSaving(true);
    APIProvider.post(
      `/platform/account-management/rest/users/${user.id}/mfa/reset`,
      {}
    ).then(() => {
      setSaving(false);
    });
  };

  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <h6 style={{ marginTop: 0, marginBottom: 10 }}>MFA Settings</h6>
          </Grid.Column>
          <Grid.Column width={4}>
            {mfa.required && <Label>MFA Required</Label>}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div style={{ marginTop: '10px' }}>
        <Checkbox
          label={'MFA Enabled'}
          disabled={mfa.required && mfa.enabled}
          checked={enabled}
          onChange={handleCheckboxChanged}
        />
        <Button
          variant="primary"
          type={'button'}
          onClick={handleUpdate}
          style={{ marginLeft: '10px' }}
          disabled={mfa.required && mfa.enabled}>
          {saving ? 'Updating...' : 'Update MFA'}
        </Button>
      </div>
      <div style={{ marginTop: '20px' }}>
        <a className="reset-mfa" onClick={resetMFA}>
          Reset MFA Device
        </a>
      </div>
    </div>
  );
};

export default MFA;
