import { User } from '@120wateraudit/envirio-components/dist/models'
import { loginFailure, loginRequest, loginSuccess } from 'src/actions/user'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

export interface UserState {
  error: string | null
  user: User | null
  isFetching: boolean
  isLoggedIn: boolean
}

const initialState = {
  error: null,
  user: null,
  isFetching: false,
  isLoggedIn: false
}

const userReducer = reducerWithInitialState<UserState>(initialState)
  .case(loginRequest, (state, { token }) => ({
    ...state,
    isFetching: true
  }))
  .case(loginSuccess, (state, action) => ({
    ...state,
    user: action.user,
    isFetching: false,
    isLoggedIn: true
  }))
  .case(loginFailure, (state, { error }) => ({
    ...state,
    error,
    isFetching: false
  }))

export default userReducer
