import actionCreatorFactory from 'typescript-fsa'
const action = actionCreatorFactory()

import { Auth0Role } from '../types/Auth0Role'

export const fetchRolesRequest = action(
  'FETCH_APPLICATION_ACCESS_ROLES_REQUEST'
)
export const fetchRolesSuccess = action<{ roles: Auth0Role[] }>(
  'FETCH_APPLICATION_ACCESS_ROLES_SUCCESS'
)
export const fetchRolesFailure = action<{ error: string }>(
  'FETCH_APPLICATION_ACCESS_ROLES_FAILURE'
)
