import {
  Label,
  ListContent,
  ListIcon,
  ListItem,
  ListList
} from 'semantic-ui-react';
import React from 'react';
import { AccountGroup } from 'src/types/AccountManagementTypes';
import { useGetAccountGroupChildrenQuery } from 'src/services';
import LinkCell from 'src/components/Cells/LinkCell';

interface Props {
  id: number;
  name: string;
  type: string;
  children?: AccountGroup[];
}

const AccountGroupTreeItem = (props: Props) => {
  const { data: children } = useGetAccountGroupChildrenQuery({
    accountGroupId: props.id
  });

  return (
    <ListItem>
      <ListIcon
        name={children && children.length > 0 ? 'folder open' : 'file'}
        size={'large'}
      />
      <ListContent style={{ fontSize: '18px' }}>
        <div>
          <Label>Type: {props.type}</Label>{' '}
          <LinkCell text={`${props.name}`} to={`/accountgroups/${props.id}`} />
        </div>
        {children && children.length > 0 && (
          <ListList>
            {children.map(child => {
              return (
                <AccountGroupTreeItem
                  key={child.id}
                  id={child.id}
                  name={child.name}
                  type={child.type}
                />
              );
            })}
          </ListList>
        )}
      </ListContent>
    </ListItem>
  );
};

export default AccountGroupTreeItem;
