import { handleActions } from 'redux-actions'

export interface DetailsReducerState {
  item: any
  isFetching: boolean
  isSaving: boolean
  errors: [any]
}

interface DetailsReducerOptions {
  fetchAction: any
  fetchSuccessAction: any
  fetchFailureAction: any
  // Delete
  deleteAction: any
  deleteSuccessAction: any
  deleteFailureAction: any
  // Update
  updateActionRequest: any
  updateActionSuccess: any
  updateActionFailure: any
  // Create
  createActionRequest?: any
  createActionSuccess?: any
  createActionFailure?: any
  // Unload
  unloadAction: any
  entityName: string
  additionalActions?: object
}

const initialState = {
  item: [],
  isFetching: false,
  isSaving: false,
  errors: []
}

const getErrorsFromPayload = payload => {
  return []
    .concat(payload.error ? payload.error : [])
    .concat(payload.errors ? payload.errors : [])
}

const createDetailsReducer = ({
  fetchAction,
  fetchSuccessAction,
  fetchFailureAction,
  deleteAction,
  deleteSuccessAction,
  deleteFailureAction,
  updateActionRequest,
  updateActionSuccess,
  updateActionFailure,
  createActionRequest,
  createActionSuccess,
  createActionFailure,
  unloadAction,
  entityName,
  additionalActions
}: DetailsReducerOptions) => {
  let deleteActions = {}
  let updateActions = {}
  let createActions = {}

  if (!additionalActions) {
    additionalActions = {}
  }

  if (typeof createActionRequest === 'function') {
    createActions = {
      [createActionRequest.toString()]: (
        state: DetailsReducerState,
        action: any
      ) => {
        return {
          ...state,
          isSaving: true
        }
      },
      [createActionSuccess.toString()]: (
        state: DetailsReducerState,
        action: any
      ) => {
        return {
          ...state,
          isSaving: false
        }
      },
      [createActionFailure.toString()]: (
        state: DetailsReducerState,
        action: any
      ) => {
        return {
          ...state,
          errors: getErrorsFromPayload(action.payload),
          isSaving: false
        }
      }
    }
  }

  if (typeof deleteAction === 'function') {
    deleteActions = {
      [deleteAction.toString()]: (state: DetailsReducerState, action: any) => {
        return {
          ...state,
          isFetching: true
        }
      },
      [deleteSuccessAction.toString()]: (
        state: DetailsReducerState,
        action: any
      ) => {
        return {
          ...state,
          item: null,
          isFetching: false
        }
      },
      [deleteFailureAction.toString()]: (
        state: DetailsReducerState,
        action: any
      ) => {
        return {
          ...state,
          errors: action.errors,
          isFetching: false
        }
      }
    }
  }

  if (typeof updateActionSuccess === 'function') {
    updateActions = {
      [updateActionRequest.toString()]: (
        state: DetailsReducerState,
        action: any
      ) => {
        return {
          ...state,
          isSaving: true
        }
      },
      [updateActionSuccess.toString()]: (
        state: DetailsReducerState,
        action: any
      ) => {
        return {
          ...state,
          item: action.payload[entityName],
          isFetching: false,
          isSaving: false
        }
      },
      [updateActionFailure.toString()]: (
        state: DetailsReducerState,
        action: any
      ) => {
        return {
          ...state,
          isSaving: false
        }
      }
    }
  }

  return handleActions(
    {
      [fetchAction.toString()]: (state: DetailsReducerState, action: any) => {
        return {
          ...state,
          isFetching: true
        }
      },
      [fetchSuccessAction.toString()]: (
        state: DetailsReducerState,
        action: any
      ) => {
        return {
          ...state,
          item: action.payload[entityName],
          isFetching: false
        }
      },
      [fetchFailureAction.toString()]: (
        state: DetailsReducerState,
        action: any
      ) => {
        return {
          ...state,
          isFetching: false
        }
      },
      [unloadAction.toString()]: (state: DetailsReducerState, action: any) => {
        return initialState
      },
      ...createActions,
      ...deleteActions,
      ...updateActions,
      ...additionalActions
    } as any,
    initialState
  )
}

export default createDetailsReducer
