import * as React from 'react';
import { useEffect, useState } from 'react';

// Models
import {
  AccountType,
  PWSAccount
} from '@120wateraudit/envirio-components/dist/models';

// Components
import Fulfillment from 'src/components/Fulfillment';
import UpdateSampleStatusModal from 'src/containers/Fulfillment/updateSampleStatusModal';

// API & Service
import { APIProvider } from 'src/utils/API';
import { useUpdatePWSSampleStatusMutation } from 'src/services';

// Types
import { PWSSampleRecord } from 'src/types/Fulfillment';
import { SampleStatus } from 'src/types/Sample';

const FulfillmentContainer = () => {
  const [
    showUpdateSampleStatusModal,
    setShowUpdateSampleStatusModal
  ] = useState(false);
  const [pwsAccounts, setPwsAccounts] = useState<PWSAccount[]>([]);
  const [updatingSampleIds, setUpdatingSampleIds] = useState<number[]>([]);
  const [
    targetUpdateStatus,
    setTargetUpdateStatus
  ] = useState<SampleStatus | null>(null);
  const [updatePWSSampleStatus] = useUpdatePWSSampleStatusMutation();

  const toggleUpdateSampleStatusModal = () => {
    setShowUpdateSampleStatusModal(!showUpdateSampleStatusModal);
  };

  const onUpdateSampleStatus = (sample: PWSSampleRecord) => {
    setUpdatingSampleIds([sample.id]);
    toggleUpdateSampleStatusModal();
  };

  const onUpdateSamplesStatus = (samples: PWSSampleRecord[]) => {
    setUpdatingSampleIds(samples.map(sample => sample.id));
    toggleUpdateSampleStatusModal();
  };

  const onMarkSampleAsShipped = (sample: PWSSampleRecord) => {
    setUpdatingSampleIds([sample.id]);
    setTargetUpdateStatus(SampleStatus.New);
    toggleUpdateSampleStatusModal();
  };

  const onMarkSamplesAsShipped = (samples: PWSSampleRecord[]) => {
    setUpdatingSampleIds(samples.map(sample => sample.id));
    setTargetUpdateStatus(SampleStatus.New);
    toggleUpdateSampleStatusModal();
  };

  const fetchPWSAccount = async () => {
    const fetchedPwsAccounts = await APIProvider.fetchAccountsByType(
      AccountType.PWS
    );
    setPwsAccounts(fetchedPwsAccounts);
  };

  const onConfirmStatusUpdate = async (status?: SampleStatus) => {
    if (status) {
      await updatePWSSampleStatus({
        status,
        sampleIds: updatingSampleIds
      }).unwrap();
      setUpdatingSampleIds([]);
      toggleUpdateSampleStatusModal();
      setTargetUpdateStatus(null);
    }
  };

  useEffect(() => {
    fetchPWSAccount();
  }, []);

  return (
    <>
      <Fulfillment
        accounts={pwsAccounts}
        onMarkSampleAsShipped={onMarkSampleAsShipped}
        onMarkSamplesAsShipped={onMarkSamplesAsShipped}
        onUpdateSampleStatus={onUpdateSampleStatus}
        onUpdateSamplesStatus={onUpdateSamplesStatus}
      />
      {showUpdateSampleStatusModal && (
        <UpdateSampleStatusModal
          sampleIds={updatingSampleIds}
          showModal={showUpdateSampleStatusModal}
          toggleModal={toggleUpdateSampleStatusModal}
          targetStatus={targetUpdateStatus}
          onConfirm={onConfirmStatusUpdate}
        />
      )}
    </>
  );
};

export default FulfillmentContainer;
