import * as React from 'react'
import { Form, Icon, Label, Menu, Select } from 'semantic-ui-react'

interface Props {
  page: number
  lastPage: number
  pageSize: number
  onNext: any
  onPrev: any
  onPageSizeChanged: any
}

const pageSizeOptions = [
  {
    key: 5,
    text: '5 rows',
    value: 5
  },
  {
    key: 10,
    text: '10 rows',
    value: 10
  },
  {
    key: 25,
    text: '25 rows',
    value: 25
  },
  {
    key: 50,
    text: '50 rows',
    value: 50
  },
  {
    key: 100,
    text: '100 rows',
    value: 100
  }
]

const Pagination = ({
  page,
  lastPage,
  pageSize,
  onNext,
  onPrev,
  onPageSizeChanged
}: Props) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
        <Menu floated="right" pagination>
          <Menu.Item as="a" icon disabled={page === 0} onClick={onPrev}>
            <Icon name="chevron left" />
          </Menu.Item>
          <Menu.Item>
            Page {page + 1} of {lastPage}
          </Menu.Item>
          <Menu.Item
            as="a"
            icon
            disabled={page + 1 === lastPage}
            onClick={onNext}>
            <Icon name="chevron right" />
          </Menu.Item>
        </Menu>
      </div>
      <div style={{ marginLeft: '20px' }}>
        <Form.Field
          compact={false}
          control={Select}
          width="4"
          value={pageSize}
          options={pageSizeOptions}
          onChange={(e, data) => onPageSizeChanged(data)}
        />
      </div>
    </div>
  )
}

export default Pagination
