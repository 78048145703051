import { isEmpty } from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'semantic-ui-react'

// Components
import UserDetails from 'src/components/AccountUsers/details'

// Actions
import AccountUserActions from 'src/actions/accountUsers'

import { Role, User } from '@120wateraudit/envirio-components/dist/models'
import { ApplicationState } from 'src/reducers'
import { getRoles, getRolesAsSelectList } from '../../selectors/roles'

interface Props {
  match: any
  user: User
  isFetching: boolean
  isSaving: boolean
  // Actions
  fetchAccountUser: ({
    accountId,
    id
  }: {
    accountId: number
    id: number
  }) => any
  updateAccountUser: (params: { accountId: number; user: User }) => any
  unloadUser: () => void
  roleOptions: any
  roles: Role[]
}

interface State {
  model: any
  isEditing: boolean
}

class AccountUserDetailsContainer extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      model: isEmpty(this.props.user) ? undefined : this.props.user,
      isEditing: false
    }
  }

  componentWillMount() {
    this.fetchUser()
  }

  componentWillReceiveProps(nextProps: any) {
    if (isEmpty(this.props.user) && nextProps.user) {
      this.setState({ model: nextProps.user })
    }
  }

  componentWillUnmount() {
    this.props.unloadUser()
  }

  fetchUser = () => {
    const { accountId, id } = this.props.match.params

    this.props.fetchAccountUser({ accountId, id })
  }

  toggleEditing = () => {
    this.setState({
      isEditing: !this.state.isEditing
    })
  }

  onInputChanged = (e: any) => {
    if (this.state.model.hasOwnProperty(e.target.name) && e.target.name) {
      this.setState({
        model: {
          ...this.state.model,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  onSelectListChanged = (data: any, modelKey: string) => {
    if (this.state.model.user.hasOwnProperty(modelKey)) {
      this.setState({
        model: {
          ...this.state.model,
          user: {
            ...this.state.model.user,
            [modelKey]: data.value
          }
        }
      })
    }
  }

  onUpdateClicked = () => {
    const { accountId } = this.props.match.params
    const { updateAccountUser } = this.props
    const user = this.state.model

    // Dispatch update action
    updateAccountUser({ accountId, user })

    // Switch back to viewing user details
    this.toggleEditing()
  }

  onRoleTypeChanged = ({ value }: { value: number[] }) => {
    const roles = this.props.roles.filter(r => value.indexOf(r.id) > -1)

    this.setState({
      model: {
        ...this.state.model,
        roles
      }
    })
  }

  render() {
    const { model } = this.state
    const { isFetching, isSaving, roleOptions } = this.props

    if (isEmpty(model) || isFetching || isSaving) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            marginTop: '50px'
          }}>
          <Loader active inline="centered" size="huge" />
        </div>
      )
    }

    return (
      <UserDetails
        user={model}
        isEditing={this.state.isEditing}
        isSaving={isSaving}
        onInputChanged={this.onInputChanged}
        onSelectListChanged={this.onSelectListChanged}
        onClickUpdate={this.onUpdateClicked}
        toggleEditing={this.toggleEditing}
        roleOptions={roleOptions}
        onRoleTypeChanged={this.onRoleTypeChanged}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.accountUser.item,
  isSaving: state.accountUser.isSaving,
  isFetching: state.accountUser.isFetching,
  roles: getRoles(state),
  roleOptions: getRolesAsSelectList(state)
})

const mapDispatchToProps = {
  fetchAccountUser: AccountUserActions.detailsActions.fetchRequest,
  updateAccountUser: AccountUserActions.updateActions.updateRequest,
  unloadUser: AccountUserActions.detailsActions.unload
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountUserDetailsContainer)
