import * as React from 'react';
import { SyntheticEvent, useState } from 'react';

// Components
import {
  Search,
  Dropdown,
  Grid,
  GridRow,
  GridColumn,
  DropdownItemProps
} from 'semantic-ui-react';
import { Button } from '@120wateraudit/waterworks';
import { Modal } from '@120wateraudit/envirio-components';
import styled from 'styled-components';
import { SearchResult, toastError, toastSuccess } from 'src/components/shared';

// Custom Hooks
import {
  useGetUsersQuery,
  useAddAccountToUserMutation,
  useGetRolesQuery
} from 'src/services';

interface Props {
  accountId: number;
  accountType: number;
  toggleAddingToAccount: () => void;
  addingToAccount: boolean;
}

const FindUserModal = ({
  accountId,
  accountType,
  toggleAddingToAccount,
  addingToAccount
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const { data: responseUsers } = useGetUsersQuery({
    paramsData: {
      pageNumber: 1,
      pageSize: 10,
      searchTerm,
      searchQueryType: 'search',
      accountId,
      excludeFromAccount: true
    }
  });
  const { data: roles } = useGetRolesQuery();
  const [addAccount] = useAddAccountToUserMutation();
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [roleIds, setRoleIds] = useState<number[] | undefined>([]);
  const onSubmitAddAccount = async () => {
    if (userId && accountId && roleIds && roleIds.length) {
      await addAccount({
        userId,
        accountId,
        roleIds
      })
        .unwrap()
        .then(() => toastSuccess('Successfully added user to this account.'))
        .catch(() => toastError('Add user failed, please try again.'));
      toggleAddingToAccount();
      setSearchTerm(undefined);
      setUserId(undefined);
    } else {
      toastError('Please fill both User and Role required fields');
    }
  };

  const onSelectAccount = async (
    event: SyntheticEvent,
    data: { result: { id: number; title: string; description: string } }
  ) => {
    setSearchTerm(data.result.description);
    setUserId(data.result.id);
  };

  const users = responseUsers?.items || [];
  const usersRecord = users.map(user => ({
    id: user.id,
    title: `${user.firstName} ${user.lastName}`,
    description: `${user.email}`
  }));

  const convertRolesToItemProps = (): DropdownItemProps[] => {
    return roles
      ? roles
          .filter(r => r.accountType === accountType || r.accountType === 0)
          .map(r => {
            return {
              text: r.name,
              value: r.id,
              key: r.id
            };
          })
      : [];
  };

  return (
    <Modal
      content={
        <Grid>
          <GridRow columns={1}>
            <GridColumn>
              <h4>Add Existing User To Account</h4>
            </GridColumn>
          </GridRow>
          <GridRow columns={1}>
            <GridColumn>
              <SearchWrapper>
                <Search
                  input={{ icon: 'search', iconPosition: 'left' }}
                  fluid={true}
                  noResultsMessage="No Users Found"
                  placeholder="Search for a user"
                  onBlur={() => {
                    if (!searchTerm) {
                      // If we end up without a search term we assume the user is clearing the search
                      setSearchTerm(undefined);
                      setUserId(undefined);
                    }
                  }}
                  onFocus={(e: SyntheticEvent) =>
                    (e.target as HTMLInputElement).select()
                  }
                  onResultSelect={onSelectAccount}
                  onSearchChange={(_, data) => setSearchTerm(data.value)}
                  resultRenderer={SearchResult}
                  results={usersRecord}
                  value={searchTerm}
                />
              </SearchWrapper>
            </GridColumn>
          </GridRow>
          <GridRow columns={1}>
            <GridColumn>
              <Dropdown
                placeholder="Select Role"
                fluid
                multiple
                selection
                onChange={(_, data) => {
                  setRoleIds(data.value as number[]);
                }}
                value={roleIds}
                options={convertRolesToItemProps()}
              />
            </GridColumn>
          </GridRow>
          <GridRow columns={1}>
            <GridColumn>
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <Button
                  onClick={() => {
                    setSearchTerm('');
                    toggleAddingToAccount();
                  }}>
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: '20px' }}
                  variant="primary"
                  onClick={() => onSubmitAddAccount()}>
                  Add
                </Button>
              </div>
            </GridColumn>
          </GridRow>
        </Grid>
      }
      isOpen={addingToAccount}
      overflowStyle="visible"
      toggle={toggleAddingToAccount}
    />
  );
};

const SearchWrapper = styled.div`
  width: 100%;
  &&& {
    .ui.input {
      width: 100%;
    }
  }
`;

export default FindUserModal;
