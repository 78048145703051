import actionCreatorFactory from 'typescript-fsa'
const action = actionCreatorFactory()

import { Role } from '@120wateraudit/envirio-components/dist/models'

export const fetchRolesRequest = action('FETCH_ROLES_REQUEST')
export const fetchRolesSuccess = action<{ roles: Role[] }>(
  'FETCH_ROLES_SUCCESS'
)
export const fetchRolesFailure = action<{ error: string }>(
  'FETCH_ROLES_FAILURE'
)
