import { TextField } from '@120wateraudit/envirio-components'
import React from 'react'
import { TableCell } from 'semantic-ui-react'
import { AccountAnalyte, AccountAnalyteWithRelations } from '../index'

interface Props {
  accountAnalyte: AccountAnalyteWithRelations
  accountAnalyteToUpdate: AccountAnalyte
  isEditing: boolean
  isRange: boolean
  setAccountAnalyteToUpdate(value: AccountAnalyte): void
}

export const LowerLimitCell = ({
  accountAnalyte,
  accountAnalyteToUpdate,
  isEditing,
  isRange,
  setAccountAnalyteToUpdate
}: Props) => {
  return (
    <TableCell>
      {!isEditing && (accountAnalyte.lowerLimit || '--')}
      {isEditing && (
        <TextField
          disabled={!isRange}
          onChange={e => {
            setAccountAnalyteToUpdate({
              ...accountAnalyteToUpdate,
              lowerLimit: Number(e.target.value)
            })
          }}
          type={'number'}
          value={
            !isRange
              ? (null as any)
              : `${accountAnalyteToUpdate.lowerLimit}` ||
                `${accountAnalyte.lowerLimit}`
          }
        />
      )}
    </TableCell>
  )
}
