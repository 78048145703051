import * as React from 'react';
import { FC } from 'react';
import { Container } from 'semantic-ui-react';

// Models
import Accounts from 'src/components/Accounts';
import { useToggle } from 'src/hooks';

// Components
import { CreateAccountModal } from 'src/modules/Account/CreateAccountModal';
import { ImportAccountsModal } from 'src/modules/Account/ImportAccountsModal';

const AccountsContainer: FC = () => {
  const { enabled: createModalOpen, toggle: toggleCreateModal } = useToggle();
  const { enabled: importModalOpen, toggle: toggleImportModal } = useToggle();

  return (
    <Container>
      <Accounts
        onNewClicked={toggleCreateModal}
        onImportClicked={toggleImportModal}
      />
      <CreateAccountModal onClose={toggleCreateModal} open={createModalOpen} />
      <ImportAccountsModal onClose={toggleImportModal} open={importModalOpen} />
    </Container>
  );
};

export default AccountsContainer;
