import { createApi } from '@reduxjs/toolkit/query/react';

// Models
import { Tag, TagLabel, SearchParams } from 'src/services/types';
import { EDDResult } from 'src/types/EDDResult';
import PagedResponse from 'src/types/PagedResponse';

// Utils
import baseQuery from 'src/services/baseQuery';
import { buildParameters } from 'src/services/utils';

interface EDDRequest {
  paramsData: SearchParams;
}

export const eddService = createApi({
  baseQuery: baseQuery({
    baseUrl: '/edd-processor'
  }),
  reducerPath: 'edd',
  tagTypes: [Tag.EDDs],
  endpoints: builder => ({
    getEDDs: builder.query<PagedResponse<EDDResult>, EDDRequest>({
      providesTags: [{ id: TagLabel.List, type: Tag.EDDs }],
      query: ({ paramsData }) => {
        const params = buildParameters(paramsData).toString();
        return {
          method: 'GET',
          url: `/edd?${params}`
        };
      }
    })
  })
});

export const { useGetEDDsQuery } = eddService;
