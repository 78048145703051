import AccountUsersActions from 'src/actions/accountUsers'
import CrudSaga from 'src/sagas/helpers/CrudSaga'

import { APIProvider } from 'src/utils/API'

export default CrudSaga({
  entityName: 'accountUser',
  actions: AccountUsersActions,
  fetchEntityMethod: APIProvider.fetchAccountUser,
  updateEntityMethod: APIProvider.updateAccountUser,
  updateSuccessfulMessage: (mappedUser: any) => `User updated successfully!`,
  createEntityMethod: APIProvider.createAccountUser,
  createSuccessMessage: (mappedUser: any) => `User created!`
})
