import React from 'react';

// Models
import { EDDResult } from 'src/types/EDDResult';

// Components
import moment from 'moment';
import { Card, Table as WWTable } from '@120wateraudit/waterworks';
import styled from 'src/theme';

// Custom Hooks
import { useGetEDDsQuery } from 'src/services';
import { useTableState } from 'src/hooks/useTableState';
import { useFileDownload } from 'src/hooks';

const COLUMNS = [
  {
    key: 'fileName',
    Header: 'File Name',
    accessor: 'fileName'
  },
  {
    key: 'accountName',
    Header: 'Account',
    accessor: 'accountName'
  },
  {
    key: 'accountType',
    Header: 'Platform',
    accessor: 'accountType'
  },
  {
    key: 'createdByUsername',
    Header: 'Submitter',
    accessor: 'createdByUsername'
  },
  {
    key: 'createdOn',
    Header: 'Started At',
    accessor: ({ createdOn }: EDDResult) => {
      return <div>{moment(createdOn).format('MM/DD/YYYY hh:mm A')}</div>;
    }
  },
  {
    key: 'id',
    Header: 'Batch ID',
    accessor: 'id'
  },
  {
    key: 'status',
    Header: 'Status',
    accessor: ({ id, status, reportFileName }: EDDResult) => {
      return (
        <StatusHandler
          id={id}
          status={status}
          reportFileName={reportFileName}
        />
      );
    }
  }
];

const EDDResultsTable = () => {
  const {
    params,
    setSearchTermWrapper,
    setPageSize,
    setPageNumber
  } = useTableState({
    initialPageSize: 20,
    searchQueryType: 'search'
  });
  const { data: response, isFetching } = useGetEDDsQuery({
    paramsData: params
  });

  return (
    <Card style={{ marginTop: '20px', marginBottom: '50px' }}>
      <WWTable
        columns={COLUMNS}
        data={response?.data || []}
        itemName="EDD Results"
        totalRecords={response?.count || 0}
        loading={isFetching}
        paginated
        page={params.pageNumber}
        pageSize={params.pageSize}
        onPageChanged={setPageNumber}
        onPageSizeChanged={setPageSize}
        searchable
        onSearchChanged={setSearchTermWrapper}
      />
    </Card>
  );
};

interface StatusProps {
  id: number;
  status: number;
  reportFileName: string;
}

const StatusHandler = ({ id, status, reportFileName }: StatusProps) => {
  const url = `/edd-processor/edd/${id}/file`;
  const { download } = useFileDownload(url);
  // exists if failed
  const Component = reportFileName ? StatusWrapper : 'div';
  return <Component onClick={download}>{status}</Component>;
};

const StatusWrapper = styled.div`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
`;

export default EDDResultsTable;
