import { Button } from '@120wateraudit/envirio-components'
import React from 'react'
import { TableCell } from 'semantic-ui-react'
import styled from 'src/theme'
import { AccountAnalyteWithRelations } from '../index'

interface Props {
  accountAnalyte: AccountAnalyteWithRelations
  isEditing: boolean
  onUpdateClicked: () => void
  setDisableEditButtons(value: boolean): void
  setIsEditing(value: boolean): void
  disableEditButton: boolean
}

export const ButtonCell = ({
  isEditing,
  onUpdateClicked,
  setDisableEditButtons,
  setIsEditing,
  disableEditButton
}: Props) => {
  return (
    <TableCell>
      {!isEditing && (
        <Button
          disabled={disableEditButton}
          onClick={() => {
            setDisableEditButtons(true)
            setIsEditing(true)
          }}
          variant="primary">
          Edit
        </Button>
      )}
      {isEditing && (
        <>
          <ActionButton variant="primary" onClick={onUpdateClicked}>
            Update
          </ActionButton>
          <ActionButton
            onClick={() => {
              setIsEditing(false)
              setDisableEditButtons(false)
            }}>
            Cancel
          </ActionButton>
        </>
      )}
    </TableCell>
  )
}

const ActionButton = styled(Button)`
  margin-right: 1rem;
`
