import { Loader } from '@120wateraudit/envirio-components';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Menu } from 'semantic-ui-react';
import styled from 'styled-components';

interface SkeletonProps {
  logout: () => void;
  children: React.ReactNode;
}

const AppSkeleton: FC<SkeletonProps> = ({ children, logout }) => {
  return (
    <Wrapper>
      <SkeletonNavigation logout={logout} />
      <ChildrenContainer>{children || <Loader />}</ChildrenContainer>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'header' 'main' 'footer';
  width: 100%;
`;

interface NavigationProps {
  logout: () => void;
}

const SkeletonNavigation = ({ logout }: NavigationProps) => {
  return (
    <Nav>
      <Container>
        <Menu fluid text>
          <Menu.Item>
            <NavLink to="/login" onClick={logout}>
              <Logo
                src="/logomark-color.png"
                srcSet="/logomark-color@2x.png 2x, /logomark-color@3x.png 3x"
              />
            </NavLink>
          </Menu.Item>
        </Menu>
      </Container>
    </Nav>
  );
};

const Nav = styled.nav`
  grid-area: header;
  max-height: 56px;
  background: white;
  display: flex;
  align-items: center;
`;

const ChildrenContainer = styled.section`
  grid-area: main;
  background-color: #f6f7f7;
`;

const Logo = styled.img`
  margin: 0 2rem 0 0 !important;
`;
export default AppSkeleton;
