import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { getToken } from 'src/utils/Token';
import { AWS_BASE } from '../utils/API';

const baseQuery = ({ baseUrl }: { baseUrl: string }): BaseQueryFn =>
  fetchBaseQuery({
    baseUrl: `${AWS_BASE}${baseUrl}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  });

export default baseQuery;
