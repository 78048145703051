import gql from 'graphql-tag'
import { uploadSingleFile } from 'src/utils/API'

export const GET_EDD_WIZARD_DATA = gql`
  query getEddWizardData($accountId: Int!, $accountType: Int!) {
    mostRecentEddTemplate(accountId: $accountId) {
      id
      EDDJSONData
      version
    }
    eddRequiredFields(accountId: $accountId, accountType: $accountType) {
      fields {
        name
      }
    }
    eddOptionalFields(accountId: $accountId, accountType: $accountType) {
      fields {
        name
        meta {
          customFieldDefinitionId
          customFieldDefinitionEntityName
        }
      }
    }
  }
`

export const CREATE_EDD_TEMPLATE = gql`
  mutation createEddTemplate(
    $accountId: Int!
    $mappings: [EddMappingInputType!]!
    $resultRow: Int!
  ) {
    createEddTemplate(
      accountId: $accountId
      mappings: $mappings
      resultRow: $resultRow
    ) {
      id
    }
  }
`

interface UploadEddTemplateProps {
  accountId: number
  file: File
}

export const uploadEddTemplate = ({
  accountId,
  file
}: UploadEddTemplateProps) =>
  uploadSingleFile(
    `/platform/account-management/rest/accounts/${accountId}/edd-uploads`,
    file
  )
