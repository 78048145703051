import {
  Setting,
  SettingDefinition
} from '@120wateraudit/envirio-components/dist/models'
import SettingDefinitionsActions from 'src/actions/settingDefinitions'
import CrudSaga from 'src/sagas/helpers/CrudSaga'

import { APIProvider } from 'src/utils/API'

export default CrudSaga<SettingDefinition>({
  entityName: 'settingDefinition',
  actions: SettingDefinitionsActions,
  fetchCollectionMethod: APIProvider.fetchSettingDefinitions
})
