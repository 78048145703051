import * as React from 'react'
import { AccountType } from '@120wateraudit/envirio-components/dist/models'
import styled from '../../theme'
import { FeaturesGrid } from '../../components/AccountSoftwarePackages/FeaturesGrid'
import { AddFeature } from 'src/components/AccountSoftwarePackages/AddFeature'
import { UpdatePackage } from 'src/components/AccountSoftwarePackages/UpdatePackage'

export interface Props {
  accountId: number
  accountType: AccountType
}

export const AccountSoftwarePackages = ({ accountType, accountId }: Props) => {
  return (
    <>
      <h4 style={{ marginTop: 10 }}>ACCOUNT FEATURES</h4>
      <InputFieldsWrapper>
        {accountType === AccountType.PWS && (
          <UpdatePackage accountId={accountId} accountType={accountType} />
        )}
        <AddFeature accountId={accountId} accountType={accountType} />
      </InputFieldsWrapper>
      <FeaturesGrid accountId={accountId} />
    </>
  )
}

const InputFieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
