import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import { Button, Modal } from '@120wateraudit/envirio-components';
import moment from 'moment';
import styled from 'src/theme';
import { User } from '@120wateraudit/envirio-components/dist/models';

interface LogResult {
  details: Details;
  lastModifiedByUser?: User;
  lastModifiedOn?: string;
  status?: string;
}

interface Details {
  locationIds: string[];
  errors: string;
  warnings: string;
}

export const LocationDeleteGridRow: React.FC<{
  result: LogResult;
}> = ({ result }) => {
  const { details, lastModifiedByUser, lastModifiedOn, status } = result;

  const [isLocationsModalOpen, setIsLocationsModalOpen] = useState(false);

  const getInitialState = () => {
    setIsLocationsModalOpen(false);
  };

  const toggleModal = () => {
    if (isLocationsModalOpen) {
      getInitialState();
    } else {
      setIsLocationsModalOpen(true);
    }
  };

  const formattedLocationsForModal = () => {
    if (details?.locationIds) {
      return details?.locationIds.toString().replaceAll(',', ', ');
    } else if (
      details?.warnings ||
      (details?.errors &&
        details?.errors !==
          `Couldn't refresh map data, a manual refresh may be needed.`)
    ) {
      // this indicates a file was submitted, but none were actually deleted
      return 'No locations deleted';
    }
    return 'All Locations';
  };

  return (
    <>
      {isLocationsModalOpen && (
        <Modal
          content={
            <LocationIdsModal>
              <h4>Location Delete Details</h4>
              <p>Status: {status}</p>
              {details.errors && <p>{details.errors}</p>}
              {details.warnings && <p>{details.warnings}</p>}
              <p>Location IDs deleted: {formattedLocationsForModal()}</p>
              <Button type="button" variant="primary" onClick={toggleModal}>
                Okay
              </Button>
            </LocationIdsModal>
          }
          isOpen={isLocationsModalOpen}
          toggle={toggleModal}
        />
      )}
      <Table.Row>
        <Table.Cell>
          <LocationLink onClick={toggleModal}>Details</LocationLink>
        </Table.Cell>
        <Table.Cell>{lastModifiedByUser?.email || '-'}</Table.Cell>
        <Table.Cell>
          {moment(lastModifiedOn).format('MM/DD/YYYY hh:mm A')}
        </Table.Cell>
        <Table.Cell>{status}</Table.Cell>
      </Table.Row>
    </>
  );
};

const LocationIdsModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LocationLink = styled.a`
  cursor: pointer;
`;
