import gql from 'graphql-tag'

interface ReportInstanceGridQueryParams {
  sorts: string
  pageSize: number
  pageNumber: number
}

export const GET_ALL_REPORT_INSTANCES = gql`
  query reportInstances(
    $accountId: Int
    $page: Int!
    $pageSize: Int!
    $sorts: ReportInstanceGridSorts
    $filters: ReportInstanceGridFilters
  ) {
    reportInstances(
      accountId: $accountId
      page: $page
      pageSize: $pageSize
      sorts: $sorts
      filters: $filters
    ) {
      total
      totalPages
      pageSize
      page
      items {
        id
        reportName
        params
        dateRun
        status
        accountId
        documentId
        fileName
      }
    }
  }
`
