import React, { FC } from 'react'
import { LabsList } from './LabsList'
import { Lab, LabPartner } from '@120wateraudit/envirio-components/dist/models'

interface Props {
  labPartner: LabPartner
  toggleCreateEditLabModal: (labPartner?: LabPartner, lab?: Lab) => void
}

export const Labs: FC<Props> = ({
  labPartner,
  toggleCreateEditLabModal
}: Props) => {
  return (
    <>
      {!labPartner.labs.length ? (
        <p>There are no Labs associated with this Lab Partner</p>
      ) : (
        <LabsList
          labPartner={labPartner}
          toggleCreateEditLabModal={toggleCreateEditLabModal}
        />
      )}
    </>
  )
}
