import React from 'react';
import { Container } from 'semantic-ui-react';
import Users from 'src/components/Users';

const UsersContainer = () => {
  return (
    <Container>
      <Users />
    </Container>
  );
};

export default UsersContainer;
