// Lib
import { debounce, throttle } from 'lodash';
import * as React from 'react';
import { Label, Loader, Search, Segment, Table } from 'semantic-ui-react';

// API
import { APIProvider } from 'src/utils/API';

// Components
import Pagination from './pagination';

interface Props {
  columns: string[];
  fetchUrl: string;
  pageSize?: number;
  rowRenderer: (data: any) => any;
  parentRef?: any;
}

interface State {
  items: any[];
  isFetching: boolean;
  page: number;
  pageSize: number;
  totalResults: number;
  totalPages: number;
  search: string;
}

export default class Grid extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const pageSize = this.props.pageSize ? this.props.pageSize : 25;

    this.state = {
      items: [],
      totalResults: 0,
      isFetching: false,
      page: 0,
      pageSize,
      totalPages: 0,
      search: ''
    };
  }

  componentWillMount() {
    this.fetchData();

    this.onSearchChanged = debounce(this.onSearchChanged, 300);
  }

  componentWillUnmount() {
    const func: any = this.onSearchChanged;
    func.cancel();
  }

  fetchData = async () => {
    const url = this.buildUrl();
    this.setState({ isFetching: true });

    try {
      const { data, items, pages, pageNumber, count } = await APIProvider.fetch(
        url
      );

      this.setState({
        items: items || data,
        isFetching: false,
        totalPages: pages,
        totalResults: count
      });
    } catch (e) {
      this.setState({
        isFetching: false
      });
    }
  };

  buildUrl = () => {
    const { fetchUrl } = this.props;
    const { page, pageSize, search } = this.state;

    let query = `?pageNumber=${page}&pageSize=${pageSize}`;

    if (search.length > 0) {
      query += `&search=${search}`;
    }

    return `${fetchUrl}${query}`;
  };

  onNextClicked = () => {
    const { page, totalPages } = this.state;

    if (page !== totalPages) {
      this.setState({ page: page + 1 }, () => this.fetchData());
    }
  };

  onPrevClicked = () => {
    const { page } = this.state;

    if (page > 0) {
      this.setState({ page: page - 1 }, () => this.fetchData());
    }
  };

  onPageSizeChanged = ({ value: pageSize }: { value: any }) => {
    this.setState({ pageSize, page: 0 }, () => this.fetchData());
  };

  updateSearchValue = evt => {
    this.setState(
      {
        search: evt.target.value
      },
      () => this.onSearchChanged()
    );
  };

  onSearchChanged = () => {
    this.fetchData();
  };

  render() {
    const {
      items,
      isFetching,
      page,
      totalPages,
      pageSize,
      totalResults,
      search
    } = this.state;
    const { columns, rowRenderer, parentRef } = this.props;

    return (
      <Segment.Group>
        <Segment>
          <Label attached="top" size="large">
            Search {items.length > 0 ? `(${totalResults} records)` : ''}
          </Label>
          <div>
            <Search
              showNoResults={false}
              loading={false}
              onResultSelect={() => {
                /* */
              }}
              onSearchChange={this.updateSearchValue}
              results={[]}
              value={search}
            />
          </div>
        </Segment>
        <Segment>
          <Table celled selectable striped>
            {isFetching ? null : (
              <Table.Header>
                <Table.Row>
                  {columns.map((c, i) => (
                    <Table.HeaderCell key={`table-headercell-${c}-${i}`}>
                      {c}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
            )}
            {isFetching ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell width="16">
                    <Loader active inline="centered" size="huge" />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body>
                {items.length > 0 && items.map(rowRenderer)}
              </Table.Body>
            )}
          </Table>
        </Segment>
        <Segment>
          <Pagination
            page={page}
            lastPage={totalPages}
            pageSize={pageSize}
            onNext={this.onNextClicked}
            onPrev={this.onPrevClicked}
            onPageSizeChanged={this.onPageSizeChanged}
          />
        </Segment>
      </Segment.Group>
    );
  }
}
