import CrudReducer from 'src/reducers/helpers/IndexCrudReducer'

// Actions
import SettingDefinitionsActions from 'src/actions/settingDefinitions'

export default CrudReducer({
  fetchAction: SettingDefinitionsActions.collectionActions.fetchRequest,
  fetchSuccessAction: SettingDefinitionsActions.collectionActions.fetchSuccess,
  fetchFailureAction: SettingDefinitionsActions.collectionActions.fetchFailure,
  unloadAction: SettingDefinitionsActions.collectionActions.unload,
  collectionName: 'settingDefinitions'
})
