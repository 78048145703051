import React, { useCallback, useState } from 'react'
import { TableCell, TableRow } from 'semantic-ui-react'
import { AccountAnalyte, AccountAnalyteWithRelations } from '../index'
import { updateAccountAnalyte } from '../dataAccess'
import { ExceedanceLimitCell } from './ExceedanceLimitCell'
import { IncludeInExportCell } from './IncludeInExportCell'
import { ButtonCell } from './ButtonCell'
import { LowerLimitCell } from './LowerLimitCell'
import { IsRangeCell } from './IsRangeCell'

interface Props {
  accountAnalyte: AccountAnalyteWithRelations
  disableEditButtons: boolean
  refetch: () => void
  setDisableEditButtons(value: boolean): void
}

export const AccountAnalyteRow = ({
  accountAnalyte,
  disableEditButtons,
  refetch,
  setDisableEditButtons
}: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isRange, setIsRange] = useState(accountAnalyte.isRange || false)
  const [accountAnalyteToUpdate, setAccountAnalyteToUpdate] = useState<
    AccountAnalyte
  >({
    id: accountAnalyte.id,
    accountId: accountAnalyte.accountId,
    analyteId: accountAnalyte.analyteId,
    exceedanceLimit: accountAnalyte.exceedanceLimit,
    includeInExport: accountAnalyte.includeInExport
  })

  const onUpdateClicked = useCallback(async () => {
    await updateAccountAnalyte(accountAnalyte.id, accountAnalyteToUpdate)
    setDisableEditButtons(false)
    setIsEditing(false)
    refetch()
  }, [accountAnalyte, accountAnalyteToUpdate])

  return (
    <TableRow key={accountAnalyte.id}>
      <TableCell>{accountAnalyte.analyte.name}</TableCell>
      <ExceedanceLimitCell
        accountAnalyte={accountAnalyte}
        accountAnalyteToUpdate={accountAnalyteToUpdate}
        isEditing={isEditing}
        setAccountAnalyteToUpdate={setAccountAnalyteToUpdate}
      />
      <LowerLimitCell
        accountAnalyte={accountAnalyte}
        accountAnalyteToUpdate={accountAnalyteToUpdate}
        isEditing={isEditing}
        isRange={isRange}
        setAccountAnalyteToUpdate={setAccountAnalyteToUpdate}
      />
      <IsRangeCell
        accountAnalyte={accountAnalyte}
        accountAnalyteToUpdate={accountAnalyteToUpdate}
        isEditing={isEditing}
        setAccountAnalyteToUpdate={setAccountAnalyteToUpdate}
        setIsRange={setIsRange}
      />
      <IncludeInExportCell
        accountAnalyte={accountAnalyte}
        accountAnalyteToUpdate={accountAnalyteToUpdate}
        isEditing={isEditing}
        setAccountAnalyteToUpdate={setAccountAnalyteToUpdate}
      />
      <ButtonCell
        accountAnalyte={accountAnalyte}
        disableEditButton={disableEditButtons}
        isEditing={isEditing}
        onUpdateClicked={onUpdateClicked}
        setDisableEditButtons={setDisableEditButtons}
        setIsEditing={setIsEditing}
      />
    </TableRow>
  )
}
