import AccountActions from 'src/actions/accounts'
import CrudSaga from 'src/sagas/helpers/CrudSaga'

import { APIProvider } from 'src/utils/API'

import { Account } from '@120wateraudit/envirio-components/dist/models'

export default CrudSaga<Account>({
  entityName: 'account',
  actions: AccountActions,
  fetchCollectionMethod: APIProvider.fetchAccounts,
  fetchEntityMethod: APIProvider.fetchAccount,
  updateEntityMethod: APIProvider.updateAccount,
  updateSuccessfulMessage: (account: Account) =>
    'Account updated successfully.',
  createEntityMethod: APIProvider.createAccount,
  deleteEntityMethod: APIProvider.deleteAccount,
  deleteSuccessMessage: () => `Account deleted successfully.`
})
