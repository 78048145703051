import {
  Dropdown as EnvirioDropdown,
  EnvirioDropdownProps,
  TextField,
  TextFieldProps
} from '@120wateraudit/envirio-components'
import * as React from 'react'
import { Field as ReduxFormField } from 'redux-form'

interface FieldProps extends Pick<TextFieldProps, 'label' | 'placeholder'> {
  name: string
}

interface DropdownProps
  extends Pick<
    EnvirioDropdownProps,
    'label' | 'options' | 'placeholder' | 'fluid' | 'multiple'
  > {
  name: string
}

export const Field = ({ name, ...rest }: FieldProps) => (
  <ReduxFormField
    name={name}
    component={({ input: { value, onChange } }) => (
      <TextField {...rest} value={value} onChange={onChange} />
    )}
  />
)

export const Dropdown = ({ name, ...rest }: DropdownProps) => (
  <ReduxFormField
    name={name}
    component={({ input: { value, onChange } }) => (
      <EnvirioDropdown
        {...rest}
        value={value}
        onChange={(e, data) => {
          onChange(data.value)
        }}
      />
    )}
  />
)
