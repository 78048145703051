import { Role } from '@120wateraudit/envirio-components/dist/models';
import { ApplicationState } from 'src/reducers';

type RoleWithAccountType = Role & { accountType: number };

export const getRoles = (state: ApplicationState): Role[] => state.roles.items;

export const getRolesAsSelectList = (state: ApplicationState) =>
  getRoles(state).map(r => ({
    key: r.name,
    text: r.name,
    value: r.id,
    accountType: (r as RoleWithAccountType).accountType
  }));
