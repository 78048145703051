export const getFriendlyNameForDataProperty = (property: string) => {
  const map = {
    accountType: 'Account Type',
    streetAddressLine1: 'Address',
    streetAddressLine2: 'Address 2',
    city: 'City',
    companyName: 'Company Name',
    contactEmail: 'Email',
    contactName: 'Contact Name',
    contactPhone: 'Phone',
    email: 'Email',
    name: 'Name',
    state: 'State',
    zip: 'Zip'
  }

  return map[property] || property
}

export const getErrorMessageFromResponse = error => {
  if (!error || !error.response || !error.response.status) {
    throw new Error('No error response/status returned from server')
  }

  let errorMessage = ''

  switch (error.response.status) {
    case 400:
      if (
        error.response.data &&
        error.response.data.name === 'BadRequestError' &&
        error.response.data.errors &&
        error.response.data.errors.length
      ) {
        errorMessage = `The following fields are invalid: ${error.response.data.errors
          .map(dataError => {
            return getFriendlyNameForDataProperty(dataError.property)
          })
          .join(', ')}`
      } else {
        errorMessage = 'Invalid data.'
      }
      break

    default:
      throw new Error('Unknown error')
  }

  return errorMessage
}
