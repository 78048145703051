import CrudReducer from 'src/reducers/helpers/IndexCrudReducer'

// Accounts
import SettingsActions from 'src/actions/settings'

export default CrudReducer({
  collectionName: 'settings',
  fetchAction: SettingsActions.collectionActions.fetchRequest,
  fetchSuccessAction: SettingsActions.collectionActions.fetchSuccess,
  fetchFailureAction: SettingsActions.collectionActions.fetchFailure,
  updateActionRequest: SettingsActions.updateActions.updateRequest,
  updateActionSuccess: SettingsActions.updateActions.updateSuccess,
  updateActionFailure: SettingsActions.updateActions.updateFailure,
  unloadAction: SettingsActions.collectionActions.unload,
  deleteAction: SettingsActions.deleteActions.deleteRequest,
  deleteSuccessAction: SettingsActions.deleteActions.deleteSuccess,
  deleteFailureAction: SettingsActions.deleteActions.deleteFailure,
  createActionRequest: SettingsActions.createActions.createRequest,
  createActionSuccess: SettingsActions.createActions.createSuccess,
  createActionFailure: SettingsActions.createActions.createFailure
})
