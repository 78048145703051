import * as React from 'react'
import styled from 'styled-components'
import { colors, Icons } from '@120wateraudit/envirio-components'
import { AccountType } from '@120wateraudit/envirio-components/dist/models'

export const EmptyState = ({ children, text, subtext }: EmptyStateProps) => (
  <WhiteFullWidthBox>
    <CenteringWrapper>
      <Icons.Upload />
    </CenteringWrapper>
    <CenteredCardTitle>{text}</CenteredCardTitle>
    {subtext && <CenteredCardSubtitle>{subtext}</CenteredCardSubtitle>}
    <CenteringWrapper>{children}</CenteringWrapper>
  </WhiteFullWidthBox>
)

export interface SavedStepStates {
  required: MappingStepState
  optional: MappingStepState
}

export interface EddTemplateWizardProps {
  requiredFields: EddField[]
  optionalFields: EddField[]
  uploadData: object
  onCompleted(mappings: DefinedMapping[]): void
  closeWizard(): void
  hasDefinitionsRow: boolean
  onDefinitionsRowChanged(): void
  accountType: AccountType
}

export interface EddField {
  name: string
  meta?: object
}

export interface MappingStepProps {
  systemFields: EddField[]
  userFields: string[]
  getInitialMappings: () => void
  mappings: Mapping[]
  updateUserFieldData(s: MappingStepState): void
}

export interface Mapping {
  systemField: EddField
  userFieldName?: string
}

export interface MappingStepState {
  mappings: Mapping[]
}

interface EmptyStateProps {
  children: JSX.Element
  text: string
  subtext?: string
}

const FullWidthBox = styled.div`
  border-radius: 0.33rem;
  padding: 2rem;
`

export const WhiteFullWidthBox = styled(FullWidthBox)`
  background-color: ${colors.white};
`

const CardSubtitle = styled.p`
  font-size: 14px;
`

const CardTitle = styled.h6`
  margin-top: 20px;
  margin-bottom: 10px;
`

export const CenteredCardTitle = styled(CardTitle)`
  text-align: center;
`

export const CenteredCardSubtitle = styled(CardSubtitle)`
  text-align: center;
`

export const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export interface DefinedMapping extends Mapping {
  userField: EddField
}
