import * as moment from 'moment'
import * as React from 'react'

// Components
import ReportCard from 'src/components/Common/ReportCard'

interface Props {
  accountId: number
}

class SchoolsAccountReportingContainer extends React.Component<Props, any> {
  render() {
    const { accountId } = this.props

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', padding: '20px' }}>
        <div>
          <ReportCard
            description="View pending items ready to be shipped"
            filename={`fulfillment-report-${moment().format('MM-DD-YYYY')}`}
            heading="Fulfillment Report"
            url={`/platform/account-management/rest/reporting/schools/fulfillmentreport/${accountId}`}
          />
        </div>
        <div style={{ marginLeft: '20px' }}>
          <ReportCard
            description="Account, school, and exceedance counts"
            filename={`account-summary-report-${moment().format('MM-DD-YYYY')}`}
            heading="Account Summary"
            url={`/platform/account-management/rest/reporting/schools/accountsummaryreport/${accountId}`}
          />
        </div>
      </div>
    )
  }
}

export default SchoolsAccountReportingContainer
