import React from 'react';
import { Container } from 'semantic-ui-react';
import { skipToken } from '@reduxjs/toolkit/query';
import { List, Card, Loader } from 'semantic-ui-react';
import AccountGroupTreeItem from 'src/modules/AccountGroup/AccountGroupTreeItem';

import {
  useGetAccountGroupChildrenQuery,
  useGetTopAccountGroupQuery
} from 'src/services';

const AccountGroupsContainer = () => {
  const {
    data: root,
    isLoading: loading,
    isSuccess: success
  } = useGetTopAccountGroupQuery(null);
  const {
    data: children,
    isSuccess: childrenSuccess
  } = useGetAccountGroupChildrenQuery(
    success ? { accountGroupId: root.id } : skipToken
  );

  return (
    <Container>
      <Card style={{ marginTop: '20px', minHeight: 600 }} fluid={true}>
        <div
          style={{
            marginTop: '20px',
            paddingLeft: '10px',
            paddingRight: '10px',
            minHeight: 600
          }}>
          <List divided verticalAlign="middle">
            {loading && <Loader />}
            {success && (
              <AccountGroupTreeItem
                id={root.id}
                name={root.name}
                type={root.type}
                children={childrenSuccess ? children : []}
              />
            )}
          </List>
        </div>
      </Card>
    </Container>
  );
};

export default AccountGroupsContainer;
