// Libs
import { call, put, takeLatest } from 'redux-saga/effects'

// Actions
import {
  fetchRolesFailure,
  fetchRolesRequest,
  fetchRolesSuccess
} from 'src/actions/roles'

// Utils
import { APIProvider } from '../utils/API'

// Types
import { Role } from '@120wateraudit/envirio-components/dist/models'

function* fetchRolesSaga(action: any) {
  try {
    const roles: Role[] = yield call(APIProvider.fetchRoles)
    yield put(fetchRolesSuccess({ roles }))
  } catch (error) {
    yield put(fetchRolesFailure({ error }))
  }
}

export default function* rolesSaga() {
  yield takeLatest(fetchRolesRequest.toString(), fetchRolesSaga)
}
