import React, { FC } from 'react'
import { Field } from 'react-final-form'
import { Button, Icon } from 'semantic-ui-react'
import { orderBy } from 'lodash'
import styled from 'styled-components'
import { Analyte } from '@120wateraudit/envirio-components/dist/models'
import { UnitOfMeasureEntity } from 'src/types/UnitOfMeasureEntity'
import { EddConfigurationValues } from '.'
import Dropdown from '../Dropdown/Dropdown'
import TextField from '../TextField/TextField'
interface Props {
  analytes: Analyte[]
  fieldName: string
  index: number
  onDelete(): void
  units: UnitOfMeasureEntity[]
  values: EddConfigurationValues
}

export const AnalyteRow: FC<Props> = ({
  analytes,
  fieldName,
  index,
  onDelete,
  units,
  values
}) => {
  const analyteDropdownOptions = orderBy(analytes, 'displayName', 'asc')
    .filter(
      analyte =>
        !values.analytes.map(val => val.analyteId).includes(analyte.id) ||
        values.analytes[index].analyteId === analyte.id
    )
    .map(analyte => {
      return {
        key: analyte.id.toString(),
        value: analyte.id,
        text: analyte.displayName
      }
    })
  const unitDropdownOptions = units.map(unit => {
    return {
      key: unit.id.toString(),
      value: unit.id,
      text: unit.unitOfMeasure
    }
  })

  return (
    <AnalyteWrapper>
      <Field<string>
        component={Dropdown}
        fluid
        label="120Water Analyte Name"
        name={`${fieldName}analyteId`}
        options={analyteDropdownOptions}
      />
      <Field<string>
        component={TextField}
        label="Lab Analyte Name"
        name={`${fieldName}labAnalyteId`}
      />
      <Field<string>
        component={Dropdown}
        fluid
        label="120Water Unit Name"
        name={`${fieldName}unitId`}
        options={unitDropdownOptions}
      />
      <Field<string>
        component={TextField}
        label="Lab Unit Name"
        name={`${fieldName}labUnitId`}
      />
      <DeleteButton basic circular icon onClick={onDelete} type="button">
        <Icon color="red" name="delete" />
      </DeleteButton>
    </AnalyteWrapper>
  )
}

const AnalyteWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
  & > * {
    flex: 1;
    margin: 0 12px;
  }
`
const DeleteButton = styled(Button)`
  &&& {
    box-shadow: none;
    align-self: normal;
    flex: 0;
  }
`
